import { inject, observer } from 'mobx-react';
import {
  ProgressLoader,
  images,
} from '../../../../components/Utils/componentUtils';
import { Sticky, StickyContainer } from 'react-sticky';
import ProtectedComponent from '../../../../components/Base/protected';
import { MenuDailySlider } from './menuDailySlider';
import { MenuFavoriteSlider } from './menuFavoriteSlider';
import { MenuSearch } from './menuSearch';
import { MenuTabs } from './menuTabs';
import { MenuCategoryList } from './menuCategoryList';
import { Toggle } from '../../../../components/Toggle/Toggle';
import { UserStore, UiStore } from 'stores';

@inject('recipeStore', 'basketStore', 'uiStore', 'userStore')
@observer
class Menu extends ProtectedComponent {
  render() {
    const userStore: UserStore = this.props.userStore;
    const uiStore: UiStore = this.props.uiStore;

    if (!userStore?.profile || !userStore.token || uiStore.loading) {
      return <ProgressLoader open={true} />;
    }

    return (
      <div className="container">
        <Toggle />
        <div className="title">Рецепты на день</div>
        {this.props.recipeStore.recipes ? <MenuDailySlider /> : <div />}
        <br />

        <div className="title">Любимые рецепты</div>
        {this.props.recipeStore.recipes ? <MenuFavoriteSlider /> : <div />}

        <div className="note">
          <span>
            Все блюда в рамках одного приема пищи взаимозаменяемы между собой.
          </span>
          <span>Также между собой можно менять завтраки и обеды.</span>
          <img
            className="note__img"
            src={images['sticker1.png'].default}
            alt="sticker"
          />
        </div>

        <StickyContainer>
          <Sticky topOffset={-56}>
            {({ style, isSticky }) => (
              <div
                style={{
                  ...style,
                  marginTop: isSticky ? '56px' : '0px',
                  zIndex: '1',
                  background: '#fff',
                }}
              >
                <MenuSearch />

                <MenuTabs />
              </div>
            )}
          </Sticky>

          <div className="tab tab--search">
            <MenuCategoryList />
          </div>
        </StickyContainer>
      </div>
    );
  }
}

export default Menu;
