import { connector } from '../index';

export default class PaymentsActions {
  static async postPayment(paymentId: Number, onError, onSuccess) {
    return connector.post(
      '/billings/initiate_payment/',
      { tariff_id: paymentId },
      onError,
      onSuccess
    );
  }
}
