import { Component } from 'react';
import { inject, observer } from 'mobx-react';
import ProtectedRoute from './protectedRouter';
import { toJS } from 'mobx';

@inject('userStore', 'uiStore')
@observer
class App extends Component<any, any> {
  render() {
    const { roles, profile } = this.props.userStore;

    const isSubscription = Boolean(profile?.subscriptions.length);
    const isMeasurement = Boolean(profile?.measurements.length);

    return (
      <div
        style={{
          padding: isSubscription && isMeasurement ? '65px 0 100px' : 0,
        }}
      >
        <ProtectedRoute
          roles={roles}
          isSubscription={isSubscription}
          isMeasurement={isMeasurement}
        />
      </div>
    );
  }
}

export default App;
