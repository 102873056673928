import { useEffect, useState } from 'react';
import styles from './verifyEmail.module.scss';
import { useHistory } from 'react-router-dom';
import { observer } from 'mobx-react';
import { ProgressLoader } from 'components/Utils/componentUtils';
import { UiStore, UserStore } from 'stores';

const userStore = new UserStore(this);
const uiStore = new UiStore(this);

const VerifyEmail = observer(() => {
  const [success, setSuccess] = useState(false);
  const history = useHistory();

  useEffect(() => {
    const fetchVerifyEmail = async () => {
      uiStore.loadingOn();

      const params = new URLSearchParams(history.location.search);
      const token = params.get('token');

      if (token) {
        await userStore.verifyEmail(
          token,
          async () => {
            setSuccess(true);
            uiStore.loadingOff();
            setTimeout(() => {
              window.location.href = '/';
            }, 2000);
          },
          () => {}
        );
      }
    };

    fetchVerifyEmail();
  }, [history]);

  if (uiStore.loading) {
    return <ProgressLoader open={true} />;
  }

  return (
    success && (
      <div className={styles.wrapper}>
        <div className={styles.dialog}>
          <h1 className={styles.title}>Поздравляем!</h1>
          <div>
            <div className={styles.description}>
              Учетная запись успешно активирована.
            </div>
            {/* <div
              className="userform__link"
              style={{
                display: 'flex',
                justifyContent: 'space-around',
              }}
            >
              <div>
                <a onClick={() => history.push('/login')}>Войти</a>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    )
  );
});

export default VerifyEmail;
