import { Component } from 'react';
import { Link } from 'react-router-dom';
import { FixedSizeList } from 'react-window';
import { MealTime } from '../../../../data';
import { inject, observer } from 'mobx-react';
import { AutoSizer } from 'react-virtualized';
import { images } from '../../../../components/Utils/componentUtils';
import { BasketLabel } from './basketLabel';
import { WishLabel } from './wishLabel';
import { RecipeStore } from 'stores';

@inject('recipeStore')
@observer
export class MenuFavoriteSlider extends Component<any, any> {
  slider: any;

  protected sliderDataType: string = 'favorite';

  render() {
    const recipeStore: RecipeStore = this.props.recipeStore;

    if (!this.props.recipeStore.favoriteRecipes?.length) {
      return (
        <>
          <div className="notfound">
            <div className="notfound__title">Нет любимых рецептов</div>
            <p>
              Нажмите на сердечко понравившегося рецепта, чтобы добавить в
              список любимых:
            </p>
            <img
              src={images['norecipe.svg'].default}
              alt="Нет любимых рецептов"
            />
          </div>
        </>
      );
    }

    return (
      <div>
        <div style={{ height: '300px' }}>
          <AutoSizer>
            {({ width, height }) => (
              <FixedSizeList
                className="menuSlider"
                layout="horizontal"
                height={height}
                width={width}
                itemSize={310}
                itemCount={recipeStore.favoriteRecipes.length}
                itemData={recipeStore.favoriteRecipes}
              >
                {(props) => {
                  const { data, index, style } = props;
                  const recipe = data[index];

                  return (
                    <div
                      className="item"
                      key={'dayRecipe' + index}
                      style={style}
                    >
                      <div className="recipe__item">
                        <BasketLabel recipe={recipe} />
                        <WishLabel recipe={recipe} />
                        <Link
                          to={'/recipe/' + recipe.id}
                          className="recipe__image"
                          style={{
                            backgroundImage: `url(${recipe.image})`,
                          }}
                        >
                          {' '}
                        </Link>
                        <Link
                          to={'/recipe/' + recipe.id}
                          className="recipe__text"
                        >
                          <span className="recipe__desc">
                            {MealTime[recipe.mealTime]}
                            {recipe.timeToCook ? '|' + recipe.timeToCook : null}
                          </span>
                          <span className="recipe__title">{recipe.title}</span>
                          <span className="recipe__links">
                            {recipe.components.map((component, foodIndex) => {
                              return foodIndex < 3 ? (
                                <span
                                  className={
                                    recipeStore.foodClassNames[foodIndex % 3]
                                  }
                                  key={
                                    this.sliderDataType +
                                    index +
                                    component.title
                                  }
                                >
                                  {component.title}
                                </span>
                              ) : null;
                            })}
                            {/* {!recipe.expanded && recipe.foods.length > 3 ? (
                              <span
                                style={{ cursor: 'pointer' }}
                                className={recipeStore.foodClassNames[0]}
                                key={this.sliderDataType + index + 'more'}
                              ></span>
                            ) : null} */}
                            {/* {recipe.foods.map((food, foodIndex) => {
                              return recipe.expanded && foodIndex >= 3 ? (
                                <span
                                  className={
                                    recipeStore.foodClassNames[foodIndex % 3]
                                  }
                                  key={this.sliderDataType + index + food.title}
                                >
                                  {food.title}
                                </span>
                              ) : null;
                            })} */}
                          </span>
                        </Link>
                      </div>
                    </div>
                  );
                }}
              </FixedSizeList>
            )}
          </AutoSizer>
        </div>
      </div>
    );
  }
}
