import {IPlanVO} from "../types/IPlanVO";
import {IPlanCategoryData} from "../types/IPlanCategory";

export namespace PlanConfigSlimming {

    export const categories: IPlanCategoryData[] = [
        {
            id: 0,
            name: "Категория 1",
            type: "Белки",
            hrefId: "cat1",
            image: "turkey.png",
            description: " Категория \"Белки\"",
        },
        {
            id: 1,
            name: "Категория 2",
            hrefId: "cat2",
            type: "Жиры",
            image: "avocado.png",
            description: " Категория \"Жиры\"",
        },
        {
            id: 2,
            name: "Категория 3",
            hrefId: "cat3",
            type: "Углеводы",
            image: "paprika.png",
            description: " Категория \"Углеводы\"",
        }
    ];

    export const breakfast: IPlanVO = {
        id: 0,
        name: "Завтрак",
        calories: 1600,
        image: "cat1.png",
        categories: [
            {
                id: 0,
                data: [
                    [1, "Мясо", "Зеленый", 195, "product2.png", "Курица филе, индейка филе"],
                    [2, "Мясо", "Желтый", 115, "product1.png", "Курица голень, курица бедро, утка филе, индейка бедро, перепел, говядина постная, свинина вырезка, кролик, мясо косули"],
                    [3, "Рыба", "Зеленый", 210, "product4.png", "Хек, дорадо, сибас, карась, камбала, аргентина, минтай, путассу, палтус, окунь морской, треска, лещ, карп, сазан, навага, судак, конгрио, щука, тунец свежий, тунец в собственном соку, кальмар, креветки, морские гребешки, осьминог, мидии"],
                    [4, "Рыба", "Желтый", 125, "product3.png", "Лосось, форель, семга, горбуша, скумбрия, сельдь, кижуч, сом, ставрида, севрюга, морской заяц, голец"],
                    [5, "Субпродукты", "Зеленый", 205, "product5.png", "Говяжьи легкие, говяжьи почки, свиные легкие, свиные почки"],
                    [6, "Субпродукты", "Желтый", 150, "product6.png", "Куриное сердце, куриная печень, куриные желудки, говяжий язык, говяжья печень, свиная печень, говяжье сердце, свиное сердце"],
                    [7, "Соя", "Желтый", 65, "product7.png", "Соевое мясо"],
                    [8, "Тофу", "Желтый", 260, "product8.png", "Тофу"],
                    [9, "Яйца", "Зеленый", 115, "product9.png", "Куриное, перепелиное (5 шт. - 1 куриное)", 55],
                    [10, "Творог", "Зеленый", 185, "product10.png", "1-5%"],
                    [11, "Йогурт", "Зеленый", 300, "product11.png", "Йогурты с высоким содержанием белка"],
                    [12, "Экспонента", "Зеленый", 315, "product12.png", "High-pro (250 мл.)", 250]
                ]
            },
            {
                id: 1,
                data: [
                    [1,"Сыр","Желтый",50, "cheese.png", "Твердые сорта 30-45%"],
                    [2,"Масло растительное","Желтый",15, "product13.png", "масло оливковое, масло растительное, масло кокосовое, масло кунжутное"],
                    [3,"Масло сливочное","Желтый",20, "product14.png", "Масло жирностью 82%"],
                    [4,"Орехи","Желтый",25, "product15.png", "Миндаль, фундук, грецкий, бразильский, кешью, кедровый, урбеч из миндаля, арахис и арахисовая паста"],
                    [5,"Семена","Желтый",25, "product16.png", "Мак, чиа, семена тыквенные, семена подсолнечника, семена льна, семена кунжута, урбеч из кунжута"],
                    [6,"Сливки","Желтый",125, "product17.png", "Жирность 10%"],
                    [7,"Сметана","Желтый",95, "product18.png", "Жирность 15%"],
                    [8,"Авокадо","Желтый",70, "product19.png", "Авокадо"],
                    [9,"Плоды","Зеленый",105, "product20.png", "Оливки, маслины"]
                ]
            },
            {
                id: 2,
                data: [
                    [1,"Крупа","Желтый",45, "product21.png", "Гречневая, пшеничная, перловая, ячневая, пшенная, овсяные хлопья для варки, кускус, кукурузная, полба, булгур, киноа, рис нешлифованный, хлебцы цельнозерновые"],
                    [2,"Макароны","Желтый",45, "product22.png", "Макароны из твердых сортов пшеницы, лапша рисовая, лапша гречневая"],
                    [3,"Мука","Желтый",45, "product23.png", "Высшего сорта, цельнозерновая, рисовая, овсяная, гречневая, лаваш тонкий"],
                    [4,"Бобовые","Зеленый",245, "product24.png", "Фасоль спаржевая, горошек зеленый свежий (замороженный)"],
                    [5,"Бобовые","Желтый",45, "product25.png", "Фасоль белая, фасоль красная, чечевица зеленая, чечевица красная, чечевица желтая, нут, маш"],
                    [6,"Овощи","Зеленый",615, "product26.png", "Помидор, огурец, кабачок, цукини, баклажан, капуста цветная, капуста белокочанная, брокколи, капуста брюссельская, перец сладкий, лук репчатый, салат, зеленый лук, укроп, петрушка, кинза, базилик"],
                    [7,"Овощи","Желтый",365, "product27.png", "Морковь, свекла, тыква"],
                    [8,"Консервы","Желтый",135, "product28.png", "Кукуруза консервированная"],
                    [9,"Сухофрукты","Желтый",65, "product29.png", "Курага, чернослив"],
                    [10,"Фрукты","Желтый",355, "product30.png", "Яблоко, персик, абрикос, апельсин, нектарин, мандарин, груша, грейпфрут, дыня, ананас, киви, инжир"],
                    [11,"Ягоды","Желтый",330, "product31.png", "Малина, черника, голубика, земляника, клубника, вишня, черешня, слива, смородина, клюква"],
                    [12,"Грибы","Зеленый",545, "product32.png", "Шампиньоны, белый гриб, лисички"],
                ]
            }
        ]
    };

    export const dinner: IPlanVO = {
        id: 1,
        name: "Обед",
        calories: 1600,
		image: "cat2.png",
        categories: breakfast.categories
    };

    export const lunch: IPlanVO = {
        id: 2,
        name: "Перекус",
        image: "cat3.png",
        calories: 1600,
        categories: [
            {
                id: 0,
                data: [
                    [1, "Мясо", "Зеленый", 150, "product2.png", "Курица филе, индейка филе"],
                    [2, "Мясо", "Желтый", 85, "product1.png", "Курица голень, курица бедро, утка филе, индейка бедро, перепел, говядина постная, свинина вырезка, кролик, мясо косули"],
                    [3, "Рыба", "Зеленый", 160, "product4.png", "Хек, дорадо, сибас, карась, камбала, аргентина, минтай, путассу, палтус, окунь морской, треска, лещ, карп, сазан, навага, судак, конгрио, щука, тунец свежий, тунец в собственном соку, кальмар, креветки, морские гребешки, осьминог, мидии"],
                    [4, "Рыба", "Желтый", 95, "product3.png", "Лосось, форель, семга, горбуша, скумбрия, сельдь, кижуч, сом, ставрида, севрюга, морской заяц, голец"],
                    [5, "Субпродукты", "Зеленый", 160, "product5.png", "Говяжьи легкие, говяжьи почки, свиные легкие, свиные почки"],
                    [6, "Субпродукты", "Желтый", 115, "product6.png", "Куриное сердце, куриная печень, куриные желудки, говяжий язык, говяжья печень, свиная печень, говяжье сердце, свиное сердце"],
                    [7, "Соя", "Желтый", 50, "product7.png", "Соевое мясо"],
                    [8, "Тофу", "Желтый", 200, "product8.png", "Тофу"],
                    [9, "Яйца", "Зеленый", 90, "product9.png", "Куриное, перепелиное (5 шт. - 1 куриное)", 55],
                    [10, "Творог", "Зеленый", 140, "product10.png", "1-5%"],
                    [11, "Йогурт", "Зеленый", 230, "product11.png", "Йогурты с высоким содержанием белка"],
                    [12, "Экспонента", "Зеленый", 245, "product12.png", "High-pro (250 мл.)", 250]
                ]
            },
            {
                id: 1,
                data: [
                    [1, "Сыр", "Желтый", 40, "cheese.png", "Твердые сорта 30-45%"],
                    [2, "Масло растительное", "Желтый", 15, "product13.png", "масло оливковое, масло растительное, масло кокосовое, масло кунжутное"],
                    [3, "Масло сливочное", "Желтый", 15, "product14.png", "Масло жирностью 82%"],
                    [4, "Орехи", "Желтый", 20, "product15.png", "Миндаль, фундук, грецкий, бразильский, кешью, кедровый, урбеч из миндаля, арахис и арахисовая паста"],
                    [5, "Семена", "Желтый", 20, "product16.png", "Мак, чиа, семена тыквенные, семена подсолнечника, семена льна, семена кунжута, урбеч из кунжута"],
                    [6, "Сливки", "Желтый", 100, "product17.png", "Жирность 10%"],
                    [7, "Сметана", "Желтый", 75, "product18.png", "Жирность 15%"],
                    [8, "Авокадо", "Желтый", 55, "product19.png", "Авокадо"],
                    [9, "Плоды", "Зеленый", 85, "product20.png", "Оливки, маслины"],
                ]
            },
            {
                id: 2,
                data: [
                    [1, "Крупа", "Желтый", 35, "product21.png", "Гречневая, пшеничная, перловая, ячневая, пшенная, овсяные хлопья для варки, кускус, кукурузная, полба, булгур, киноа, рис нешлифованный, хлебцы цельнозерновые"],
                    [2, "Макароны", "Желтый", 35, "product22.png", "Макароны из твердых сортов пшеницы, лапша рисовая, лапша гречневая"],
                    [3, "Мука", "Желтый", 35, "product23.png", "Высшего сорта, цельнозерновая, рисовая, овсяная, гречневая, лаваш тонкий"],
                    [4, "Бобовые", "Зеленый", 190, "product24.png", "Фасоль спаржевая, горошек зеленый свежий (замороженный)"],
                    [5, "Бобовые", "Желтый", 35, "product25.png", "Фасоль белая, фасоль красная, чечевица зеленая, чечевица красная, чечевица желтая, нут, маш"],
                    [6, "Овощи", "Зеленый", 480, "product26.png", "Помидор, огурец, кабачок, цукини, баклажан, капуста цветная, капуста белокочанная, брокколи, капуста брюссельская, перец сладкий, лук репчатый, салат, зеленый лук, укроп, петрушка, кинза, базилик"],
                    [7, "Овощи", "Желтый", 285, "product27.png", "Морковь, свекла, тыква"],
                    [8, "Консервы", "Желтый", 105, "product28.png", "Кукуруза консервированная"],
                    [9, "Сухофрукты", "Желтый", 50, "product29.png", "Курага, чернослив"],
                    [10, "Фрукты", "Желтый", 280, "product30.png", "Яблоко, персик, абрикос, апельсин, нектарин, мандарин, груша, грейпфрут, дыня, ананас, киви, инжир"],
                    [11, "Ягоды", "Желтый", 255, "product31.png", "Малина, черника, голубика, земляника, клубника, вишня, черешня, слива, смородина, клюква"],
                    [12, "Грибы", "Зеленый", 425, "product32.png", "Шампиньоны, белый гриб, лисички"]
                ]
            }
        ]
    };

    export const supper: IPlanVO = {
        id: 3,
        name: "Ужин",
		image: "cat4.png",
        calories: 1600,
        categories: [
            {
                id: 0,
                data: [
                    [1, "Мясо", "Зеленый", 215, "product2.png", "Курица филе, индейка филе"],
                    [2, "Рыба", "Зеленый", 230, "product4.png", "Хек, дорадо, сибас, карась, камбала, аргентина, минтай, путассу, палтус, окунь морской, треска, лещ, карп, сазан, навага, судак, конгрио, щука, тунец свежий, тунец в собственном соку, кальмар, креветки, морские гребешки, осьминог, мидии"],
                    [3, "Субпродукты", "Зеленый", 230, "product5.png", "Говяжьи легкие, говяжьи почки, свиные легкие, свиные почки"],
                    [4, "Яйца", "Зеленый", 130, "product9.png", "Куриное, перепелиное (5 шт. - 1 куриное)", 55],
                    [5, "Творог", "Зеленый", 200, "product10.png", "1-5%"],
                    [6, "Йогурт", "Зеленый", 330, "product11.png", "Йогурты с высоким содержанием белка"],
                    [7, "Экспонента", "Зеленый", 350, "product12.png", "High-pro (250 мл.)", 250]
                ]
            },
            {
                id: 2,
                data: [
                    [1, "Овощи", "Зеленый", 215, "product26.png", "Помидор, огурец, кабачок, цукини, баклажан, капуста цветн, капуста белокочанная, брокколи, капуста брюссельская, перец сладкий, лук репчатый, салат, зеленый лук, укроп, петрушка, кинза, базилик"],
                    [2, "Бобовые", "Зеленый", 85, "product24.png", "Фасоль спаржевая, горошек зеленый свежий (замороженный)"],
                    [3, "Грибы", "Зеленый", 190, "product32.png", "Шампиньоны, белый гриб, лисички"],
                ]
            }
        ]
    };

    export const slimmingCathegories: IPlanVO[] = [
        PlanConfigSlimming.breakfast,
        PlanConfigSlimming.dinner,
        PlanConfigSlimming.lunch,
        PlanConfigSlimming.supper
    ];
}