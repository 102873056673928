import { observer } from 'mobx-react';
import { sectionData } from '../../data';
import { ButtonType, SupportButton } from '../SupportButton';
import { images } from '../../../../components/Utils/componentUtils';
import s from './UnderContentButtons.module.scss';
import { useStore } from 'utils/hooks';

export const UnderContentButtonsBlock = observer(() => {
  const { supportStore } = useStore();
  const { currentContent, currentSection } = supportStore;
  return (
    <div className={s.underContentButtons}>
      {currentContent?.currentIndex === 0 ? (
        sectionData[currentSection.id]?.items.map((item, index) => {
          if (index > currentContent?.currentIndex) {
            return (
              <SupportButton
                key={index}
                type={ButtonType.WithIcon}
                width={'100%'}
                height={59}
                leftIcon={item?.buttonProps?.icon}
                text={item?.buttonProps?.text}
                contentAlign={'center'}
                style={{
                  marginBottom: '16px',
                }}
                onclick={() =>
                  supportStore.setCurrentContent(item.currentIndex)
                }
              />
            );
          }

          return null;
        })
      ) : (
        <>
          <SupportButton
            type={ButtonType.WithIcon}
            width={'100%'}
            height={59}
            leftIcon={images['burgerMenu.svg'].default}
            text={'Главное меню'}
            contentAlign={'center'}
            style={{
              marginBottom: '16px',
            }}
            onclick={() => supportStore.goMenu()}
          />
          <SupportButton
            type={ButtonType.WithIcon}
            width={'100%'}
            height={59}
            leftIcon={currentSection.icon}
            text={currentSection.text}
            leftIconProps={currentSection.iconProps}
            contentAlign={'center'}
            style={{
              marginBottom: '16px',
            }}
            onclick={() => supportStore.goFirstSlideContent()}
          />
        </>
      )}
    </div>
  );
});
