import React, { Component } from 'react';
import { images } from '../Utils/componentUtils';
import { NavLink } from 'react-router-dom';
import s from './bottomMenu.module.scss';

const menuItems = [
  {
    linkTo: '/plan',
    src: images['menu2.png'].default,
    content: 'питание',
  },
  {
    linkTo: '/exercises',
    src: images['menu3.png'].default,
    content: 'тренировки',
  },
  {
    linkTo: '/support',
    src: images['chat.png'].default,
    content: 'обучение',
  },
  {
    linkTo: '/profile',
    src: images['user.png'].default,
    content: 'профиль',
  },
];

class BottomMenu extends Component<any, any> {
  render() {
    if (window.location.pathname === '/payments') return null;

    return (
      <div className={s.container}>
        {menuItems.map((item, index) => {
          return (
            <NavLink
              key={index}
              activeClassName={s.itemActive}
              to={item.linkTo}
            >
              <span className={s.itemImg}>
                <img width="32" src={item.src} alt={item.content} />
                {item.content}
              </span>
            </NavLink>
          );
        })}
      </div>
    );
  }
}

export default BottomMenu;
