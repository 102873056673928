import { SupportButton, ButtonType } from './SupportButton';
import { sections } from '../data';
import { observer } from 'mobx-react';
import { useStore } from 'utils/hooks';
import s from '../Support.module.scss';

export const SectionButtonBlock = observer(() => {
  const { supportStore } = useStore();
  return (
    <div className={s.buttonsBlock}>
      <div style={{ width: '48.8%' }} className={s.subBlock}>
        {sections.map((section, index) => {
          if (index < 2) {
            return (
              <SupportButton
                key={index}
                type={ButtonType.WithIcon}
                leftIcon={section.icon}
                text={section.text}
                width={section.width}
                contentAlign={'center'}
                height={60}
                onclick={() => {
                  supportStore.onSectionClick(index);
                }}
              />
            );
          }

          return null;
        })}
      </div>
      <div style={{ width: '48.8%' }} className={s.subBlock}>
        {sections.map((section, index) => {
          if (index > 1) {
            return (
              <SupportButton
                key={index}
                type={ButtonType.WithIcon}
                leftIcon={section.icon}
                leftIconProps={section.iconProps}
                text={section.text}
                width={section.width}
                contentAlign={'center'}
                height={60}
                onclick={() => {
                  supportStore.onSectionClick(index);
                }}
              />
            );
          }

          return null;
        })}
      </div>
    </div>
  );
});
