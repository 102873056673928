import {BaseStore} from "./baseStore";
import {IProgressPhoto, IProgressPhotoSet, IProgressPhotosSets} from "../types/IProgressPhoto";
import {action, computed, makeObservable, observable} from "mobx";
import UserActions from "../connector/userActions";
import {IStores} from "../types/IStores";
import {images} from "../components/Utils/componentUtils";
import moment from "moment";

export class ProgressStore extends BaseStore {

    constructor(stores: IStores) {
        super(stores);
        makeObservable(this);
    }

    @observable
    photoSets: IProgressPhotosSets = null;

    @observable
    lastPhotoSet: IProgressPhotoSet = null;

    @action
    async loadPhotos() {
        const photoSets = {
            arr: [],
            map: {}
        };
        const result = await UserActions.loadProgressPhotos();
        if (result.data && result.data.status) {
            const nowDate = moment(result.data.date);
            result.data.photos.forEach(photo => {
                let set: IProgressPhotoSet = photoSets.map[photo.date];
                if (!set) {
                    const dateDiff = moment.duration(nowDate.diff(moment(photo.date)));
                    const daysDiff = dateDiff.days();
                    set = {date: photo.date, arr: [], map: {}, canEdit: (daysDiff < 7)};
                    photoSets.arr.push(set);
                    photoSets.map[set.date] = set;

                }
                set.arr.push(photo);
                set.map[photo.type] = photo;
            })
        }

        if (photoSets.arr.length) {
            const lastPhotoSet = photoSets.arr[0];
            if (lastPhotoSet.canEdit) {
                this.lastPhotoSet = lastPhotoSet;
            }
        }
        this.photoSets = photoSets;
    }

    @action
    async addPhoto(formData) {
        const result = await UserActions.addProgressPhoto(formData);
        if (result.data.status) {
            const photo = result.data.photo;
            if (!this.lastPhotoSet) {
                this.lastPhotoSet = {
                    date: photo.date,
                    arr: [],
                    map: {},
                    canEdit: true
                };
            }
            this.lastPhotoSet.arr.push(photo);
            this.lastPhotoSet.map[photo.type] = photo;
        }
    }

    @action
    async updatePhoto(photoId: number, formData) {
        const result = await UserActions.updateProgressPhoto(photoId, formData);
        if (result.data.status) {
            const photo = result.data.photo;
            const arr = this.lastPhotoSet.arr.map(p =>  p.id === photoId ? photo : p);
            const map = this.lastPhotoSet.map;
            map[photo.type] = photo;
            const date = this.lastPhotoSet.date;
            const canEdit = this.lastPhotoSet.canEdit;

            this.lastPhotoSet = {
                arr,
                map,
                date: date,
                canEdit
            };
        }
    }

    @action
    async deletePhoto(photo: IProgressPhoto) {
        const result = await UserActions.deleteProgressPhoto(photo.id);
        if (result.data.status) {
            this.lastPhotoSet.arr.splice(this.lastPhotoSet.arr.indexOf(photo), 1);
            delete this.lastPhotoSet.map[photo.type];
            const date = this.lastPhotoSet.date;
            const canEdit = this.lastPhotoSet.canEdit;

            this.lastPhotoSet = {
                arr: this.lastPhotoSet.arr,
                map: this.lastPhotoSet.map,
                date: date,
                canEdit
            };
        }
    }

    getPhotoURL(photo) {
        if (photo.path) {
            return process.env.REACT_APP_S3_STORE_URL + "/" + photo.path;
        }
        return images["avatar.png"].default;
    }

}