import {IPlanVO} from "../types/IPlanVO";

export namespace PlanConfigSupport {

    export const breakfast: IPlanVO = {
        id: 0,
        name: "Завтрак",
        calories: 1600,
        image: "cat1.png",
        categories: [
            {
                id: 0,
                data: [
                    [1, "Мясо", "Зеленый", 80, "product2.png", "Курица филе, индейка филе"],
                    [2, "Мясо", "Желтый", 45, "product1.png", "Курица голень, курица бедро, утка филе, индейка бедро, перепел, говядина постная, свинина вырезка, кролик, мясо косули"],
                    [3, "Рыба", "Зеленый", 85, "product4.png", "Хек, дорадо, сибас, карась, камбала, аргентина, минтай, путассу, палтус, окунь морской, треска, лещ, карп, сазан, навага, судак, конгрио, щука, тунец свежий, тунец в собственном соку, кальмар, креветки, морские гребешки, осьминог, мидии"],
                    [4, "Рыба", "Желтый", 50, "product3.png", "Лосось, форель, семга, горбуша, скумбрия, сельдь, кижуч, сом, ставрида, севрюга, морской заяц, голец"],
                    [5, "Субпродукты", "Зеленый", 85, "product5.png", "Говяжьи легкие, говяжьи почки, свиные легкие, свиные почки"],
                    [6, "Субпродукты", "Желтый", 60, "product6.png", "Куриное сердце, куриная печень, куриные желудки, говяжий язык, говяжья печень, свиная печень, говяжье сердце, свиное сердце"],
                    [7, "Соя", "Желтый", 25, "product7.png", "Соевое мясо"],
                    [8, "Тофу", "Желтый", 105, "product8.png", "Тофу"],
                    [9, "Яйца", "Зеленый", 50, "product9.png", "Куриное, перепелиное (5 шт. - 1 куриное)", 55],
                    [10, "Творог", "Зеленый", 75, "product10.png", "1-5%"],
                    [11, "Йогурт", "Зеленый", 125, "product11.png", "Йогурты с высоким содержанием белка"],
                ]
            },
            {
                id: 1,
                data: [
                    [1,"Сыр","Желтый",55, "cheese.png", "Твердые сорта 30-45%"],
                    [2,"Масло растительное","Желтый",20, "product13.png", "масло оливковое, масло растительное, масло кокосовое, масло кунжутное"],
                    [3,"Масло сливочное","Желтый",20, "product14.png", "Масло жирностью 82%"],
                    [4,"Орехи","Желтый",25, "product15.png", "Миндаль, фундук, грецкий, бразильский, кешью, кедровый, урбеч из миндаля, арахис и арахисовая паста"],
                    [5,"Семена","Желтый",30, "product16.png", "Мак, чиа, семена тыквенные, семена подсолнечника, семена льна, семена кунжута, урбеч из кунжута"],
                    [6,"Сливки","Желтый",135, "product17.png", "Жирность 10%"],
                    [7,"Сметана","Желтый",100, "product18.png", "Жирность 15%"],
                    [8,"Авокадо","Желтый",75, "product19.png", "Авокадо"],
                    [9,"Плоды","Зеленый",115, "product20.png", "Оливки, маслины"]
                ]
            },
            {
                id: 2,
                data: [
                    [1,"Крупа","Желтый",75, "product21.png", "Гречневая, пшеничная, перловая, ячневая, пшенная, овсяные хлопья для варки, кускус, кукурузная, полба, булгур, киноа, рис нешлифованный, хлебцы цельнозерновые"],
                    [2,"Макароны","Желтый",75, "product22.png", "Макароны из твердых сортов пшеницы, лапша рисовая, лапша гречневая"],
                    [3,"Мука","Желтый",75, "product23.png", "Высшего сорта, цельнозерновая, рисовая, овсяная, гречневая, лаваш тонкий"],
                    [4,"Бобовые","Зеленый",410, "product24.png", "Фасоль спаржевая, горошек зеленый свежий (замороженный)"],
                    [5,"Бобовые","Желтый",75, "product25.png", "Фасоль белая, фасоль красная, чечевица зеленая, чечевица красная, чечевица желтая, нут, маш"],
                    [6,"Овощи","Зеленый",1025, "product26.png", "Помидор, огурец, кабачок, цукини, баклажан, капуста цветная, капуста белокочанная, брокколи, капуста брюссельская, перец сладкий, лук репчатый, салат, зеленый лук, укроп, петрушка, кинза, базилик"],
                    [7,"Овощи","Желтый",610, "product27.png", "Морковь, свекла, тыква"],
                    [8,"Консервы","Желтый",220, "product28.png", "Кукуруза консервированная"],
                    [9,"Сухофрукты","Желтый",110, "product29.png", "Курага, чернослив"],
                    [10,"Фрукты","Желтый",595, "product30.png", "Яблоко, персик, абрикос, апельсин, нектарин, мандарин, груша, грейпфрут, дыня, ананас, киви, инжир"],
                    [11,"Ягоды","Желтый",545, "product31.png", "Малина, черника, голубика, земляника, клубника, вишня, черешня, слива, смородина, клюква"],
                    [12,"Грибы","Зеленый",910, "product32.png", "Шампиньоны, белый гриб, лисички"],
                ]
            }
        ]
    };

    export const dinner: IPlanVO = {
        id: 1,
        name: "Обед",
        calories: 1600,
        image: "cat2.png",
        categories: breakfast.categories
    };

    export const lunch: IPlanVO = {
        id: 2,
        name: "Перекус",
        image: "cat3.png",
        calories: 1600,
        categories: [
            {
                id: 0,
                data: [
                    [1, "Мясо", "Зеленый", 60, "product2.png", "Курица филе, индейка филе"],
                    [2, "Мясо", "Желтый", 35, "product1.png", "Курица голень, курица бедро, утка филе, индейка бедро, перепел, говядина постная, свинина вырезка, кролик, мясо косули"],
                    [3, "Рыба", "Зеленый", 65, "product4.png", "Хек, дорадо, сибас, карась, камбала, аргентина, минтай, путассу, палтус, окунь морской, треска, лещ, карп, сазан, навага, судак, конгрио, щука, тунец свежий, тунец в собственном соку, кальмар, креветки, морские гребешки, осьминог, мидии"],
                    [4, "Рыба", "Желтый", 40, "product3.png", "Лосось, форель, семга, горбуша, скумбрия, сельдь, кижуч, сом, ставрида, севрюга, морской заяц, голец"],
                    [5, "Субпродукты", "Зеленый", 65, "product5.png", "Говяжьи легкие, говяжьи почки, свиные легкие, свиные почки"],
                    [6, "Субпродукты", "Желтый", 45, "product6.png", "Куриное сердце, куриная печень, куриные желудки, говяжий язык, говяжья печень, свиная печень, говяжье сердце, свиное сердце"],
                    [7, "Соя", "Желтый", 20, "product7.png", "Соевое мясо"],
                    [8, "Тофу", "Желтый", 80, "product8.png", "Тофу"],
                    [9, "Яйца", "Зеленый", 35, "product9.png", "Куриное, перепелиное (5 шт. - 1 куриное)", 55],
                    [10, "Творог", "Зеленый", 55, "product10.png", "1-5%"],
                    [11, "Йогурт", "Зеленый", 90, "product11.png", "Йогурты с высоким содержанием белка"],
                ]
            },
            {
                id: 1,
                data: [
                    [1, "Сыр", "Желтый", 40, "cheese.png", "Твердые сорта 30-45%"],
                    [2, "Масло растительное", "Желтый", 15, "product13.png", "масло оливковое, масло растительное, масло кокосовое, масло кунжутное"],
                    [3, "Масло сливочное", "Желтый", 15, "product14.png", "Масло жирностью 82%"],
                    [4, "Орехи", "Желтый", 20, "product15.png", "Миндаль, фундук, грецкий, бразильский, кешью, кедровый, урбеч из миндаля, арахис и арахисовая паста"],
                    [5, "Семена", "Желтый", 20, "product16.png", "Мак, чиа, семена тыквенные, семена подсолнечника, семена льна, семена кунжута, урбеч из кунжута"],
                    [6, "Сливки", "Желтый", 100, "product17.png", "Жирность 10%"],
                    [7, "Сметана", "Желтый", 75, "product18.png", "Жирность 15%"],
                    [8, "Авокадо", "Желтый", 55, "product19.png", "Авокадо"],
                    [9, "Плоды", "Зеленый", 85, "product20.png", "Оливки, маслины"],
                ]
            },
            {
                id: 2,
                data: [
                    [1, "Крупа", "Желтый", 60, "product21.png", "Гречневая, пшеничная, перловая, ячневая, пшенная, овсяные хлопья для варки, кускус, кукурузная, полба, булгур, киноа, рис нешлифованный, хлебцы цельнозерновые"],
                    [2, "Макароны", "Желтый", 60, "product22.png", "Макароны из твердых сортов пшеницы, лапша рисовая, лапша гречневая"],
                    [3, "Мука", "Желтый", 60, "product23.png", "Высшего сорта, цельнозерновая, рисовая, овсяная, гречневая, лаваш тонкий"],
                    [4, "Бобовые", "Зеленый", 330, "product24.png", "Фасоль спаржевая, горошек зеленый свежий (замороженный)"],
                    [5, "Бобовые", "Желтый", 60, "product25.png", "Фасоль белая, фасоль красная, чечевица зеленая, чечевица красная, чечевица желтая, нут, маш"],
                    [6, "Овощи", "Зеленый", 820, "product26.png", "Помидор, огурец, кабачок, цукини, баклажан, капуста цветная, капуста белокочанная, брокколи, капуста брюссельская, перец сладкий, лук репчатый, салат, зеленый лук, укроп, петрушка, кинза, базилик"],
                    [7, "Овощи", "Желтый", 485, "product27.png", "Морковь, свекла, тыква"],
                    [8, "Консервы", "Желтый", 180, "product28.png", "Кукуруза консервированная"],
                    [9, "Сухофрукты", "Желтый", 90, "product29.png", "Курага, чернослив"],
                    [10, "Фрукты", "Желтый", 475, "product30.png", "Яблоко, персик, абрикос, апельсин, нектарин, мандарин, груша, грейпфрут, дыня, ананас, киви, инжир"],
                    [11, "Ягоды", "Желтый", 435, "product31.png", "Малина, черника, голубика, земляника, клубника, вишня, черешня, слива, смородина, клюква"],
                    [12, "Грибы", "Зеленый", 730, "product32.png", "Шампиньоны, белый гриб, лисички"]
                ]
            }
        ]
    };

    export const supper: IPlanVO = {
        id: 3,
        name: "Ужин",
        image: "cat4.png",
        calories: 1600,
        categories: [
            {
                id: 0,
                data: [
                    [1, "Мясо", "Зеленый", 110, "product2.png", "Курица филе, индейка филе"],
                    [2, "Мясо", "Желтый", 65, "product1.png", "Курица голень, курица бедро, утка филе, индейка бедро, перепел, говядина постная, свинина вырезка, кролик, мясо косули"],
                    [3, "Рыба", "Зеленый", 120, "product4.png", "Хек, дорадо, сибас, карась, камбала, аргентина, минтай, путассу, палтус, окунь морской, треска, лещ, карп, сазан, навага, судак, конгрио, щука, тунец свежий, тунец в собственном соку, кальмар, креветки, морские гребешки, осьминог, мидии"],
                    [4, "Рыба", "Желтый", 70, "product3.png", "Лосось, форель, семга, горбуша, скумбрия, сельдь, кижуч, сом, ставрида, севрюга, морской заяц, голец"],
                    [5, "Субпродукты", "Зеленый", 115, "product5.png", "Говяжьи легкие, говяжьи почки, свиные легкие, свиные почки"],
                    [6, "Субпродукты", "Желтый", 85, "product6.png", "Куриное сердце, куриная печень, куриные желудки, говяжий язык, говяжья печень, свиная печень, говяжье сердце, свиное сердце"],
                    [7, "Соя", "Желтый", 35, "product7.png", "Соевое мясо"],
                    [8, "Тофу", "Желтый", 145, "product8.png", "Тофу"],
                    [9, "Яйца", "Зеленый", 65, "product9.png", "Куриное, перепелиное (5 шт. - 1 куриное)", 55],
                    [10, "Творог", "Зеленый", 105, "product10.png", "1-5%"],
                    [11, "Йогурт", "Зеленый", 170, "product11.png", "Йогурты с высоким содержанием белка"],
                ]
            },
            {
                id: 1,
                data: [
                    [1, "Сыр", "Желтый", 25, "cheese.png", "Твердые сорта 30-45%"],
                    [2, "Масло растительное", "Желтый", 10, "product13.png", "масло оливковое, масло растительное, масло кокосовое, масло кунжутное"],
                    [3, "Масло сливочное", "Желтый", 10, "product14.png", "Масло жирностью 82%"],
                    [4, "Орехи", "Желтый", 10, "product15.png", "Миндаль, фундук, грецкий, бразильский, кешью, кедровый, урбеч из миндаля, арахис и арахисовая паста"],
                    [5, "Семена", "Желтый", 15, "product16.png", "Мак, чиа, семена тыквенные, семена подсолнечника, семена льна, семена кунжута, урбеч из кунжута"],
                    [6, "Сливки", "Желтый", 60, "product17.png", "Жирность 10%"],
                    [7, "Сметана", "Желтый", 45, "product18.png", "Жирность 15%"],
                    [8, "Авокадо", "Желтый", 35, "product19.png", "Авокадо"],
                    [9, "Плоды", "Зеленый", 50, "product20.png", "Оливки, маслины"],
                ]
            },
            {
                id: 2,
                data: [
                    [1, "Крупа", "Желтый", 25, "product21.png", "Гречневая, пшеничная, перловая, ячневая, пшенная, овсяные хлопья для варки, кускус, кукурузная, полба, булгур, киноа, рис нешлифованный, хлебцы цельнозерновые"],
                    [2, "Макароны", "Желтый", 25, "product22.png", "Макароны из твердых сортов пшеницы, лапша рисовая, лапша гречневая"],
                    [3, "Мука", "Желтый", 25, "product23.png", "Высшего сорта, цельнозерновая, рисовая, овсяная, гречневая, лаваш тонкий"],
                    [4, "Бобовые", "Зеленый", 135, "product24.png", "Фасоль спаржевая, горошек зеленый свежий (замороженный)"],
                    [5, "Бобовые", "Желтый", 25, "product25.png", "Фасоль белая, фасоль красная, чечевица зеленая, чечевица красная, чечевица желтая, нут, маш"],
                    [6, "Овощи", "Зеленый", 340, "product26.png", "Помидор, огурец, кабачок, цукини, баклажан, капуста цветная, капуста белокочанная, брокколи, капуста брюссельская, перец сладкий, лук репчатый, салат, зеленый лук, укроп, петрушка, кинза, базилик"],
                    [7, "Овощи", "Желтый", 205, "product27.png", "Морковь, свекла, тыква"],
                    [8, "Консервы", "Желтый", 75, "product28.png", "Кукуруза консервированная"],
                    [9, "Сухофрукты", "Желтый", 35, "product29.png", "Курага, чернослив"],
                    [10, "Фрукты", "Желтый", 200, "product30.png", "Яблоко, персик, абрикос, апельсин, нектарин, мандарин, груша, грейпфрут, дыня, ананас, киви, инжир"],
                    [11, "Ягоды", "Желтый", 180, "product31.png", "Малина, черника, голубика, земляника, клубника, вишня, черешня, слива, смородина, клюква"],
                    [12, "Грибы", "Зеленый", 305, "product32.png", "Шампиньоны, белый гриб, лисички"]
                ]
            }
        ]
    };


    export const supportCathegories: IPlanVO[] = [
        PlanConfigSupport.breakfast,
        PlanConfigSupport.dinner,
        PlanConfigSupport.lunch,
        PlanConfigSupport.supper
    ];
}