import MeasurementForm from './measurementForm';
import { inject } from 'mobx-react';
import { MeasurementUtils } from './measurementsUtil';
import MeasurementActions from '../../connector/measurementActions';
import ProtectedComponent from '../../components/Base/protected';
import { UiStore } from 'stores';

@inject('measurementStore', 'uiStore', 'userStore', 'recipeStore')
export class MeasurementsSubmit extends ProtectedComponent {
  private async onSave(data: any) {
    const measurementPayload = {
      user_timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      weight: parseFloat(data.weight),
      belly: parseFloat(data.belly),
      buttocks: parseFloat(data.buttocks),
      menstrual_day: MeasurementUtils.parseIntNaNCheck(data.menstrualDay),
      food_level: MeasurementUtils.parseIntNaNCheck(data.foodLevel),
      power_exercises_count: parseInt(data.powerExercises),
      light_exercises_count: parseInt(data.lightExercises),
      steps: MeasurementUtils.parseIntNaNCheck(data.steps),
      info: data.info ? data.info : '',
    };

    const result = await MeasurementActions.postMeasurement(measurementPayload);
    const uiStore: UiStore = this.props.uiStore;

    if (result.status === 201) {
      uiStore.showDialog(
        'Следующие замеры вы можете отправить не ранее, чем через 24 часа.',
        'Ваши замеры приняты',
        () => {
          window.location.href = '/profile';
        },
        () => {
          window.location.href = '/profile';
        },
        'popup--style popup--blue'
      );
    }
  }

  render() {
    return <MeasurementForm onSave={this.onSave.bind(this)} />;
  }
}
