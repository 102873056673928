import {Component} from "react";
import {Typography} from "@material-ui/core";

export class NotFound extends Component<any, any> {
    componentDidMount() {
        this.props.uiStore.loadingOff();
    }
    render() {
        return <Typography variant="h2">Произошла ошибка при загрузке данных с сервера</Typography> ;
    }
}