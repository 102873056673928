export const titles = [
  {
    path: '/',
    title:
      'Личный кабинет. Фитнес-тренер онлайн — Татьяна Метельская! Онлайн-тренировки для похудения',
  },
  { path: '/error', title: 'Ошибка' },
  { path: '/login', title: 'Вход' },
  { path: '/verify-email', title: 'Подтверждение почты' },
  { path: '/reset-password', title: 'Сброс пароля' },
  { path: '/signup', title: 'Регистрация' },
  { path: '/registration', title: 'Регистрация' },
  { path: '/plan/menu', title: 'Готовое меню' },
  { path: '/plan/basket', title: 'Корзина' },
  { path: '/plan', title: 'План питания' },
  { path: '/recipe', title: 'Рецепт' },
  { path: '/support', title: 'Обучение' },
  { path: '/exercises', title: 'Тренировки' },
  { path: '/exercises_week', title: 'Упражнения на неделю' },
  { path: '/leaderboard/weight', title: 'Таблица веса' },
  { path: '/leaderboard/steps', title: 'Таблица шагов' },
  { path: '/measurement', title: 'Таблица замеров' },
  { path: '/measurement/submit', title: 'Внесение измерений' },
  { path: '/measurement/:id', title: 'Изменение измерения' },
  { path: '/profile', title: 'Профиль' },
  { path: '/profile/info', title: 'Информация о профиле' },
  { path: '/test', title: 'Тест' },
  { path: '/photo', title: 'Фото' },
  { path: '/photo/add', title: 'Добавить фото' },
  { path: '/payments', title: 'Платежи' },
];

export enum Role {
  BASE = 0,
  CURATOR = 999,
  ADMIN = 1000,
  EXCERCISES = 11,
  CHATS = 12,
  MEASUREMENTS = 13,
  CHANGE_GOAL = 14,
  CHANGE_PLAN = 15,
}

export const GOOGLE_ARCHIVE_LINK: string =
  'https://drive.google.com/drive/folders/1CpNFawqexBwhl54LqF_XepxrlD3sACKF?usp=sharing';
export const YANDEX_ARCHIVE_LINK: string =
  'https://disk.yandex.ru/d/pQHTlvn-Q4asCw';

export const TG_BOT: string = 'https://telegram.me/app_4yourfit_bot';
export const TN_BOT = 'https://t.me/Tanya_yourfit_bot';

export const CHATS_1 = {
  question: 'https://t.me/+55oOc4FvXkFiYWMy',
  talks: 'https://t.me/+55oOc4FvXkFiYWMy',
  announcements: 'https://t.me/+55oOc4FvXkFiYWMy',
};

export const CHATS_2 = {
  question: 'https://t.me/+3XOkUFrAuVQzMmUy',
  talks: 'https://t.me/+3XOkUFrAuVQzMmUy',
  announcements: 'https://t.me/+3XOkUFrAuVQzMmUy',
};

export const CHATS_3 = {
  question: 'https://t.me/+R8jKp70ILi85MDcy',
  talks: 'https://t.me/+VGUj5hSCZPdiMGRi',
  announcements: 'https://t.me/+PVPmPaGisHczMGMy',
};

export const subscriptionsChats = [
  { subscription: '20Н90', chats: CHATS_1 },
  { subscription: '20Н365', chats: CHATS_1 },
  { subscription: '19Н90', chats: CHATS_2 },
  { subscription: '19Н365', chats: CHATS_2 },
  { subscription: '18Н90', chats: CHATS_3 },
  { subscription: '18Н365', chats: CHATS_3 },
  { subscription: '17Н90', chats: CHATS_3 },
  { subscription: '17Н365', chats: CHATS_3 },
  { subscription: '11Н365', chats: CHATS_3 },
  { subscription: '12Н365', chats: CHATS_3 },
  { subscription: '12Н365П', chats: CHATS_3 },
  { subscription: '13Н365П', chats: CHATS_3 },
  { subscription: '14Н365', chats: CHATS_3 },
  { subscription: '14Н365', chats: CHATS_3 },
  { subscription: 'SUPERSTAR', chats: CHATS_3 },
];

export const ALLOWED_FOODS: string =
  'https://drive.google.com/file/d/1pCel18swvsM67dl0cyRbfhOebkyW2Wtd/view?usp=sharing';
export const HOW_TO_CHOOSE_EXERCISE: string =
  'https://drive.google.com/file/d/12Z2sVs0ZKsAgq1ej-LOFOAC_WJtTxjGB/view?usp=sharing';

export const MealTime = ['завтрак', 'обед', 'перекус', 'ужин'];

export const FoodCategories = [
  'напитки',
  'молочные продукты',
  'фрукты',
  'орехи',
  'соки',
  'алкоголь',
  'сырье и приправы',
  'мясо',
  'выпечка',
  'морепродукты',
  'хлопья',
  'грибы',
  'овощи',
  'мучные изделия',
  'хлеб',
  'ягоды',
  'снэки',
  'десерты',
  'масло',
  'колбаса',
  'яйца',
  'сыр',
  'другое',
];

export const countryMap = {
  Belarus: 'Беларусь',
  Russia: 'Россия',
  Ukraine: 'Украина',
};

export const diseasesList = [
  'Хронический гломерулонефрит',
  'Хронический пиелонефрит',
  'Мочекаменная болезнь',
  'Сахарный диабет 1 типа',
  'Сахарный диабет 2 типа',
  'Инсулинорезистентность',
  'Аутоиммунный тиреоидит (без снижения функции ЩЖ)',
  'Гипотиреоз: снижение функции ЩЖ',
  'Хронический холецистит',
  'Желчекаменная болезнь',
  'Гастрит с повышенной кислотностью',
  'Эзофагит',
  'Язвенная болезнь',
  'Запор',
  'Сердечно-сосудистые заболевания',
];

export const plans = [
  1000, 1050, 1100, 1150, 1200, 1250, 1300, 1350, 1400, 1450, 1500, 1550, 1600,
  1650, 1700, 1750, 1800, 1850, 1900, 1950, 2000, 2050, 2100, 2150, 2200,
];

export const controls = ['Без контроля', 'Умеренный', 'Строгий'];

export const IntensiveTrains = [
  {
    id: 'i1',
    title: 'Интенсивная тренировка №1',
    week: 1,
    href: 'HejiXKPp5Dw',
  },
  {
    id: 'i2',
    title: 'Интенсивная тренировка №2',
    week: 1,
    href: '93J21QeZU1k',
  },
  {
    id: 'i3',
    title: 'Интенсивная тренировка №3',
    week: 1,
    href: 'nGujioISK-8',
  },
  {
    id: 'i4',
    title: 'Интенсивная тренировка №4',
    week: 2,
    href: '5RjQx21MH6k',
  },
  {
    id: 'i5',
    title: 'Интенсивная тренировка №5',
    week: 2,
    href: 'YhEr-5HYV9Y',
  },
  {
    id: 'i6',
    title: 'Интенсивная тренировка №6',
    week: 2,
    href: '4wxD2MM3Fds',
  },
  {
    id: 'i7',
    title: 'Интенсивная тренировка №7',
    week: 3,
    href: 'Qd4TPO7Xfu0',
  },
  {
    id: 'i8',
    title: 'Интенсивная тренировка №8',
    week: 3,
    href: 'vYko82OsxRs',
  },
  {
    id: 'i9',
    title: 'Интенсивная тренировка №9',
    week: 3,
    href: 'BqEd7MMYPDE',
  },
  {
    id: 'i10',
    title: 'Интенсивная тренировка №10',
    week: 4,
    href: '9RAa3vB-u7I',
  },
  {
    id: 'i11',
    title: 'Интенсивная тренировка №11',
    week: 4,
    href: '2zKstrNWK5s',
  },
  {
    id: 'i12',
    title: 'Интенсивная тренировка №12',
    week: 4,
    href: '-YJMUjvDBAg',
  },
  {
    id: 'i13',
    title: 'Интенсивная тренировка №13',
    week: 5,
    href: 'EEbx1a0fmho',
  },
  {
    id: 'i14',
    title: 'Интенсивная тренировка №14',
    week: 5,
    href: 'NE2iFXW2bhc',
  },
  {
    id: 'i15',
    title: 'Интенсивная тренировка №15',
    week: 5,
    href: 'iX02YSFfmug',
  },
  {
    id: 'i16',
    title: 'Интенсивная тренировка №16',
    week: 6,
    href: 'MzLpXByfnm0',
  },
  {
    id: 'i17',
    title: 'Интенсивная тренировка №17',
    week: 6,
    href: 'Lt0LV6oFoCE',
  },
  {
    id: 'i18',
    title: 'Интенсивная тренировка №18',
    week: 6,
    href: 'CumLhcVTNKE',
  },
  {
    id: 'i19',
    title: 'Интенсивная тренировка №19',
    week: 7,
    href: 'tZtkZV_B1cg',
  },
  {
    id: 'i20',
    title: 'Интенсивная тренировка №20',
    week: 7,
    href: 'Km-btxwPOyg',
  },
  {
    id: 'i21',
    title: 'Интенсивная тренировка №21',
    week: 7,
    href: 'Ag4eqnH5UR8',
  },
  {
    id: 'i22',
    title: 'Интенсивная тренировка №22',
    week: 8,
    href: 'Fcy_l4V_4W4',
  },
  {
    id: 'i23',
    title: 'Интенсивная тренировка №23',
    week: 8,
    href: 'M921Tj5h_S0',
  },
  {
    id: 'i24',
    title: 'Интенсивная тренировка №24',
    week: 8,
    href: '0VNrJe3FYII',
  },
  {
    id: 'i25',
    title: 'Интенсивная тренировка №25',
    week: 9,
    href: 'mIr7ky16xKU',
  },
  {
    id: 'i26',
    title: 'Интенсивная тренировка №26',
    week: 9,
    href: 'gZiJvYVLPzw',
  },
  {
    id: 'i27',
    title: 'Интенсивная тренировка №27',
    week: 9,
    href: '-C3hs5hXug8',
  },
  {
    id: 'i28',
    title: 'Интенсивная тренировка №28',
    week: 10,
    href: 'a3DNcD665CQ',
  },
  {
    id: 'i29',
    title: 'Интенсивная тренировка №29',
    week: 10,
    href: '8lci66Ynypk',
  },
  {
    id: 'i30',
    title: 'Интенсивная тренировка №30',
    week: 10,
    href: '-ruNLKd8GkY',
  },
  {
    id: 'i31',
    title: 'Интенсивная тренировка №31',
    week: 11,
    href: 'c-KO00FkZFY',
  },
  {
    id: 'i32',
    title: 'Интенсивная тренировка №32',
    week: 11,
    href: '4lzKTfVKnpk',
  },
  {
    id: 'i33',
    title: 'Интенсивная тренировка №33',
    week: 11,
    href: 'vY9RLzhQzsM',
  },
  {
    id: 'i34',
    title: 'Интенсивная тренировка №34',
    week: 12,
    href: 'm7byBDyku_I',
  },
  {
    id: 'i35',
    title: 'Интенсивная тренировка №35',
    week: 12,
    href: 'EmHAfzUYlEs',
  },
  {
    id: 'i36',
    title: 'Интенсивная тренировка №36',
    week: 12,
    href: 'gQ5RNDDcqC0',
  },
  {
    id: 'i37',
    title: 'Интенсивная тренировка №37',
    week: 13,
    href: '5MCrjl_O93k',
  },
  {
    id: 'i38',
    title: 'Интенсивная тренировка №38',
    week: 13,
    href: 'X88UqdvyZrg',
  },
  {
    id: 'i39',
    title: 'Интенсивная тренировка №39',
    week: 13,
    href: 'Vi6nGu4X2H4',
  },
];

export const LightTrains = [
  { id: 'l1', title: 'Легкая тренировка №1', week: 1, href: '65FP0xOzJes' },
  { id: 'l2', title: 'Легкая тренировка №2', week: 1, href: 'jvo6eRD9mF8' },
  { id: 'l3', title: 'Легкая тренировка №3', week: 2, href: 'wd-gCjDZqoI' },
  { id: 'l4', title: 'Легкая тренировка №4', week: 2, href: 'emNPzCyl_qE' },
  { id: 'l5', title: 'Легкая тренировка №5', week: 3, href: 'm-2hFRu_IV4' },
  { id: 'l6', title: 'Легкая тренировка №6', week: 3, href: 'SGWs9flhpCc' },
  { id: 'l7', title: 'Легкая тренировка №7', week: 4, href: 'PXlyKNKkZ9Y' },
  { id: 'l8', title: 'Легкая тренировка №8', week: 4, href: 'zmQ-pMlO33E' },
  { id: 'l9', title: 'Легкая тренировка №9', week: 5, href: 'vpLLstRkvwc' },
  { id: 'l10', title: 'Легкая тренировка №10', week: 5, href: 'bLEUL-fPfBM' },
  { id: 'l11', title: 'Легкая тренировка №11', week: 6, href: 'TjQq6_jzjHk' },
  { id: 'l12', title: 'Легкая тренировка №12', week: 6, href: 'iOomJuTHSdc' },
  { id: 'l13', title: 'Легкая тренировка №13', week: 7, href: '_r4T6UYKRIE' },
  { id: 'l14', title: 'Легкая тренировка №14', week: 7, href: 'lrS7xPSHFV0' },
  { id: 'l15', title: 'Легкая тренировка №15', week: 8, href: 'R7oNJNCo41Y' },
  { id: 'l16', title: 'Легкая тренировка №16', week: 8, href: '5QcE0ydEIsU' },
  { id: 'l17', title: 'Легкая тренировка №17', week: 9, href: '8MvkwRGIZDE' },
  { id: 'l18', title: 'Легкая тренировка №18', week: 9, href: 'jFKDiCYCnSI' },
  { id: 'l19', title: 'Легкая тренировка №19', week: 10, href: 'RK3VXCYHlsA' },
  { id: 'l20', title: 'Легкая тренировка №20', week: 10, href: '6AZePpjAgTA' },
  { id: 'l21', title: 'Легкая тренировка №21', week: 11, href: '2CCZA544My0' },
  { id: 'l22', title: 'Легкая тренировка №22', week: 11, href: '-27ZH5MZZhk' },
  { id: 'l23', title: 'Легкая тренировка №23', week: 12, href: 'qge1eNrE-fY' },
  { id: 'l24', title: 'Легкая тренировка №24', week: 12, href: 'EEPgHLJiS8Y' },
  { id: 'l25', title: 'Легкая тренировка №25', week: 13, href: 'N4kprKjVS8s' },
  { id: 'l26', title: 'Легкая тренировка №26', week: 13, href: '4I9ALdkr0xM' },
];

export const UsefulVideo = [
  { id: 'u1', title: 'Про проект', week: 1, href: 'rZNYqiofMIk' },
  {
    id: 'u2',
    title: 'Как пользоваться планом питания',
    week: 1,
    href: 'Cm0BBb7NuJ0',
  },
  {
    id: 'u3',
    title:
      'Антисрыв: почему мы срываемся, когда хотим похудеть, и что делать, чтобы этого избежать?',
    week: 2,
    href: '8Xczg1_k2jU',
  },
  {
    id: 'u4',
    title: 'Белки | Разбираемся в своем питании',
    week: 3,
    href: '_eRN4TYzTIA',
  },
  {
    id: 'u5',
    title: 'Жиры | Разбираемся в своем питании',
    week: 4,
    href: 'lBjn4AoLVh0',
  },
  {
    id: 'u6',
    title: 'Углеводы | Разбираемся в своем питании',
    week: 5,
    href: 'LWX-m2zo0o0',
  },
  {
    id: 'u7',
    title: 'Вода | Разбираемся в своем питании',
    week: 6,
    href: 'rKYdnAGAkL8',
  },
];

export const YoutubeExercises = [
  {
    id: 'y1',
    title:
      'Универсальная Утренняя ЗАРЯДКА за 10 минут | Идеальное начало дня с Татьяной Метельской',
    href: 'R4pfQKRs--k',
  },
  {
    id: 'y2',
    title:
      'Интенсивная Утренняя ЗАРЯДКА для ПОХУДЕНИЯ за 5 минут | Идеально подойдет если совсем нет Времени',
    href: 'h8so7Z7nJxg',
  },
  {
    id: 'y3',
    title:
      'Королевская осанка за 10 минут в день | Простые упражнения для красивой спины в домашних условиях',
    href: '01-EDmv5OkI',
  },
  {
    id: 'y4',
    title:
      'Лимфодренажная Тренировка | Активация лимфатической системы - Сделай это и убери отеки быстро!',
    href: 'GCmQ1Bum5C4',
  },
  {
    id: 'y5',
    title: 'Гимнастика для пожилых людей | Упражнения для бабушек и дедушек',
    href: 'Dt1oWNMC8TY',
  },
  {
    id: 'y6',
    title:
      'Утренняя ЗАРЯДКА на 5 минут | Делайте это КАЖДЫЙ ДЕНЬ и будете ЗДОРОВЫ',
    href: 'tV6UGufmSic',
  },
  {
    id: 'y7',
    title:
      'Утренняя ЗАРЯДКА для Похудения за 10 минут | ЗАРЯДИСЬ на Хороший день!',
    href: 'ZiON45GbXpI',
  },
  {
    id: 'y8',
    title:
      'Лимфодренажная зарядка | Как быстро убрать отеки и улучшить качество кожи за 15 минут',
    href: 'ckk5LW-62mw',
  },
  {
    id: 'y9',
    title:
      'Утренняя 3D зарядка для Здоровья за 10 минут | Спина НЕ будет Болеть, появится Легкость в Пояснице',
    href: '_KQCkcr5N7Y',
  },
  {
    id: 'y10',
    title:
      'Идеальная Утренняя ЗАРЯДКА для Девушек за 10 минут | Легкая РАЗМИНКА всего тела НЕ вставая с Кровати',
    href: 'YzuE-jJwzf4',
  },
  {
    id: 'y11',
    title:
      'Легкая ЗАРЯДКА для всего Тела за 10 минут | ЛУЧШИЙ Комплекс Полезных упражнений СТОЯ',
    href: 'Kbr2JY0CPEc',
  },
  {
    id: 'y13',
    title:
      'Легкая Утренняя ЗАРЯДКА для здоровья за 15 минут | Идеальное начало дня с Татьяной Метельской',
    href: 'JUeRSHQ0fBU',
  },
  {
    id: 'y14',
    title:
      'Легкая ЗАРЯДКА для Здоровья за 25 минут | Комплекс Универсальной Зарядки для ЗДОРОВЫХ суставов',
    href: 'xdSYc5Oxtc0',
  },
  {
    id: 'y15',
    title:
      'ЛУЧШАЯ Универсальная утренняя 3D Зарядка БЕЗ СЛОВ с Татьяной Метельской за 15 минут | EXPONENTA',
    href: 'ZgXPQbmZUfc',
  },
  {
    id: 'y16',
    title:
      'РАСТЯЖКА на ВСЕ ТЕЛО за 30 минут ДОМА | Расслабляющая Растяжка перед сном После Работы',
    href: 'Gk_mskUWokY',
  },
  {
    id: 'y17',
    title:
      'РАСТЯЖКА на ВСЁ ТЕЛО на полу за 10 минут | РАСТЯЖКА для НАЧИНАЮЩИХ Дома с Татьяной Метельской',
    href: 'Ndy3mCHHyLw',
  },
  {
    id: 'y18',
    title:
      'Интенсивная Утренняя ЗАРЯДКА для Похудения | Делай это КАЖДОЕ УТРО!',
    href: 'h537TxxT2_M',
  },
  {
    id: 'y19',
    title:
      'РАСТЯЖКА на ВСЁ ТЕЛО на полу за 10 минут | РАСТЯЖКА для НАЧИНАЮЩИХ | Классная Растяжка Дома с Таней',
    href: 'E8VFfCEoVaM',
  },
  {
    id: 'y20',
    title:
      'Утренняя ЗАРЯДКА Стоя за 10 минут | Эффективные Универсальные Упражнения Стоя от Татьяны Метельской',
    href: 'XbZjvNtC3Xk',
  },
  {
    id: 'y21',
    title:
      'Быстрая Утренняя ЗАРЯДКА Стоя за 8 минут | Универсальные Упражнения Стоя с Татьяной Метельской',
    href: 'bOOrSdo-p-k',
  },
  {
    id: 'y22',
    title:
      'РАСТЯЖКА на ВСЁ ТЕЛО Дома | Эффективные УПРАЖНЕНИЯ для Начинающих в ДОМАШНИХ Условиях',
    href: 'IB-V3YvkDSw',
  },
  {
    id: 'y23',
    title:
      'Лимфодренажная ЗАРЯДКА СТОЯ за 10 минут | Универсальные УПРАЖНЕНИЯ от ОТЁКОВ в Домашних Условиях',
    href: 'UNzfhXvJ3MQ',
  },
  {
    id: 'y24',
    title:
      'Динамическая РАСТЯЖКА на все тело за 10 минут | Динамический СТРЕТЧИНГ в Домашних условиях',
    href: 'AUysfkSIP9E',
  },
  {
    id: 'y25',
    title:
      'Быстрая Утренняя ЗАРЯДКА Стоя за 10 минут | Делайте это КАЖДЫЙ ДЕНЬ и будете ЗДОРОВЫ!̆',
    href: '3c53Jc5UDWg',
  },
  {
    id: 'y26',
    title:
      'Быстрая Утренняя ЗАРЯДКА Стоя за 5 минут | Идеальное начало дня с Татьяной Метельской!̆',
    href: 'HB2uRYPrkFk',
  },
  {
    id: 'y27',
    title:
      "Утренняя ЗАРЯДКА Стоя под Музыку в стиле Джаз ('Jazz') | Суставная ГИМНАСТИКА за 10 минут",
    href: 'Gm8J0n-zjl0',
  },
  {
    id: 'y28',
    title:
      'Утренняя ЗАРЯДКА на ВСЕ ТЕЛО на Полу за 10 минут | Спортивное УТРО с Татьяной Метельской!',
    href: 'Zio9aj3ghVQ',
  },
  {
    id: 'y29',
    title:
      'Лимфодренажная ТРЕНИРОВКА стоя за 5 минут | Эффективные Лимфодренажные Упражнения от ОТЁКОВ',
    href: 'w7wc9YeMlmw',
  },
  {
    id: 'y30',
    title:
      'Утренняя ЗАРЯДКА на полу за 10 минут | Простые и Универсальные УПРАЖНЕНИЯ на ВСЕ ТЕЛО',
    href: '_pKT-K6-CzM',
  },
  {
    id: 'y31',
    title:
      "Утренняя ЗАРЯДКА Стоя под Музыку в стиле Хип-Хоп 'Hip-Hop' | Заряжаемся на Хороший День за 10 минут",
    href: 'gk9FyTo2zwM',
  },
  {
    id: 'y32',
    title:
      'Быстрая Утренняя ЗАРЯДКА на полу за 5 минут | Комплекс упражнений в Домашних Условиях',
    href: 'AbHwhP1nz9g',
  },
  {
    id: 'y33',
    title:
      'Интенсивная Утренняя ЗАРЯДКА за 10 минут | Комплекс Упражнений в Домашних Условиях',
    href: 'nKysCW7k9Ds',
  },
  {
    id: 'y34',
    title:
      'Утренняя ЗАРЯДКА стоя за 10 минут - Зарядись на целый день! | Фитнес Дома',
    href: 'A2ucEvTNGr0',
  },
  {
    id: 'y35',
    title:
      'Утренняя ЗАРЯДКА на ПОЛУ за 10 минут - Зарядись на целый день! | Фитнес Дома',
    href: 'fDoaNKlLWEo',
  },
  {
    id: 'y36',
    title:
      'Лимфодренажная ЗАРЯДКА Стоя за 10 минут | Это поможет быстро УБРАТЬ ОТЕКИ Утром!',
    href: 'UNzfhXvJ3MQ',
  },
];

export const StepExercises = [
  {
    id: 'st1',
    title:
      '10000 Шагов НЕ выходя из Дома | Ходьба c Лесли на Русском языке | Сжигаем 500 калорий БЕЗ прыжков',
    href: 'H6iHamGsr44',
  },
  {
    id: 'st2',
    title:
      '5000 Шагов НЕ выходя из Дома за 30 минут | Ходьба для Похудения БЕЗ прыжков',
    href: 'uXA3e614xM4',
  },
  {
    id: 'st3',
    title:
      '10000 Шагов Дома | Ходьба c Лесли Сансон на Русском языке | Кардио тренировка для Похудения',
    href: 'V4pt-vcviIY',
  },
  {
    id: 'st4',
    title:
      '5000 Шагов ДОМА | Аэробика для Похудения | Ходьба для ПОХУДЕНИЯ дома | Ходьба с Лесли',
    href: '6cYfsID0vgc',
  },
  {
    id: 'st5',
    title: '5000 Шагов ДОМА | Ходьба для ПОХУДЕНИЯ дома с Татьяной Метельской',
    href: 'aTVFVAs5SXE',
  },
  {
    id: 'st6',
    title:
      '10000 Шагов Дома | Ходьба Для ПОХУДЕНИЯ не Выходя из ДОМА | Сжигаем 500 калорий с Улыбкой',
    href: 'UiAm338bZmU',
  },
];

export const CircularExercises = [
  {
    id: 'c1',
    title:
      'Круговая ТРЕНИРОВКА на все тело ДОМА за 45 минут | ЖИРОСЖИГАЮЩАЯ Тренировка для Девушек ДОМА',
    href: 'oa8bA9e5Ow8',
  },
  {
    id: 'c2',
    title:
      'Круговая ТРЕНИРОВКА на все тело ДОМА за 45 минут | ЖИРОСЖИГАЮЩАЯ Тренировка для Девушек БЕЗ Прыжков',
    href: 'AfGAtKWruPI',
  },
  {
    id: 'c3',
    title:
      'Круговая ТРЕНИРОВКА на все тело ДОМА за 45 минут | Упражнения для Девушек в Домашних Условиях',
    href: 'tzxJTPctaLQ',
  },
  {
    id: 'c4',
    title:
      'Круговая Тренировка на ВСЁ ТЕЛО с полотенцем | Тренировка Дома БЕЗ оборудования за 45 минут',
    href: 'oIxfkxgIUb4',
  },
  {
    id: 'c5',
    title:
      'Круговая ТРЕНИРОВКА на ВСЕ ТЕЛО за 45 минут | Эффективные УПРАЖНЕНИЯ для ДЕВУШЕК под музыку Hallman',
    href: 'idxR_xK38e4',
  },
  {
    id: 'c6',
    title:
      'Жиросжигающая ТРЕНИРОВКА на Всё Тело БЕЗ ПОВТОРОВ | Универсальные УПРАЖНЕНИЯ для Девушек на ВСЕ ТЕЛО',
    href: '-YGYpLieRRo',
  },
  {
    id: 'c7',
    title:
      'ТРЕНИРОВКА на Всё Тело БЕЗ ПОВТОРОВ | Простые и Эффективные УПРАЖНЕНИЯ для Девушек на ВСЕ ТЕЛО',
    href: 'Lkky6nOjxb0',
  },
];

export const PilatesExercises = [
  {
    id: 'p1',
    title:
      'Королевский ПИЛАТЕС для Стройности и Похудения за 30 минут | УПРАЖНЕНИЯ Пилатес на Все Тело ДОМА',
    href: '8LKRZ8-ildU',
  },
  {
    id: 'p2',
    title:
      'Роскошный ПИЛАТЕС на Все Тело за 30 минут | Упражнения Пилатес ДОМА для ПОХУДЕНИЯ',
    href: 'sPcOHrOBx20',
  },
  {
    id: 'p3',
    title:
      'Прекрасный ПИЛАТЕС на Полу за 15 минут | Тренировка Пилатес ДОМА для Среднего Уровня',
    href: 'cQC5Yw2fG2Q',
  },
  {
    id: 'p4',
    title:
      'Пилатес для Легкой и Гибкой СПИНЫ за 15 минут | Лучшие УПРАЖНЕНИЯ для Спины под Японские Мотивы',
    href: 'zWEa0epVQNg',
  },
  {
    id: 'p5',
    title:
      'Королевский ПИЛАТЕС Для Похудения за 30 минут | Упражнения ПИЛАТЕС Дома на Все Тело | Пилатес Стоя',
    href: '-7mKINH-ORw',
  },
  {
    id: 'p6',
    title:
      'Роскошный ПИЛАТЕС на Все Тело за 30 минут | Пилатес ДОМА на полу для СТРОЙНОСТИ и ПОХУДЕНИЯ',
    href: 'vzxweUcSpaU',
  },
  {
    id: 'p7',
    title:
      'ПИЛАТЕС для Начинающих на Полу за 30 минут | Роскошный Пилатес Дома | Тренировка PILATES',
    href: '0YL3i_ENcVI',
  },
  {
    id: 'p8',
    title:
      'Пилатес Стоя для ПОХУДЕНИЯ в Домашних Условиях за 30 минут | Тренировка ПИЛАТЕС дома БЕЗ ПРЫЖКОВ',
    href: '8XYZuOWDbyU',
  },
  {
    id: 'p9',
    title:
      'ПИЛАТЕС на Всё Тело Стоя за 20 минут | Спокойная Тренировка БЕЗ ПРЫЖКОВ | Тренировка PILATES',
    href: 's9P2t2YmV28',
  },
  {
    id: 'p10',
    title:
      'ПИЛАТЕС на Полу Дома за 20 минут | Эффективная Тренировка на ЯГОДИЦЫ и ПРЕСС без выпадов и прыжков',
    href: 'fhz-zyLbhec',
  },
];

export const TabataExercises = [
  {
    id: 't1',
    title:
      'ТАБАТА на ВСЕ ТЕЛО за 30 минут | Жиросжигающая Тренировка ДЛЯ ДЕВУШЕК дома',
    href: 'tPrkvStDdns',
  },
  {
    id: 't2',
    title:
      'Огненная ТАБАТА на ВСЁ ТЕЛО под Танцевальную Музыку | Жиросжигающая ТРЕНИРОВКА за 30 минут',
    href: 'HDK0NXv03C8',
  },
  {
    id: 't3',
    title:
      'Жиросжигающая ТАБАТА для Похудения | Топим Жир БЕЗ Оборудования в Домашних условиях',
    href: 'aHEB2gK5VJ8',
  },
  {
    id: 't4',
    title:
      'Тренировка ТАБАТА на ВСЁ ТЕЛО под Зажигательную Музыку | Топим жир за 30 минут | Tabata Workout',
    href: 'eVUCosZ1S5A',
  },
  {
    id: 't5',
    title:
      'Жиросжигающая Тренировка ТАБАТА на Все Тело за 30 минут | Топим Жир - Интервальный Тренинг ДОМА',
    href: '4w5USXEzmu0',
  },
];

export const SmartFitnessExercises = [
  {
    id: 'sm1',
    title:
      'Королевская осанка за 10 минут в день | Простые упражнения для красивой спины в домашних условиях',
    href: '01-EDmv5OkI',
  },
  {
    id: 'sm2',
    title:
      'Тренировка при ДИАСТАЗЕ | Эффективные УПРАЖНЕНИЯ на глубокие мышцы живота и ТАЗОВОГО ДНА',
    href: 'y_KjBM2lcZQ',
  },
  {
    id: 'sm3',
    title:
      'Тренировка с МЯЧОМ для ПЛОСКОГО ЖИВОТА и Мышц Тазового Дна | Тренировка на ПРЕСС при ДИАСТАЗЕ',
    href: 'HIDGWYlvNj0',
  },
  {
    id: 'sm4',
    title:
      'Упражнения для Стоп за 10 минут | Работа со Стопами c Мячом - Просто и Эффективно!',
    href: 'qoILpqJo-HE',
  },
  {
    id: 'sm5',
    title:
      'Тренировка на РУКИ и ПЛЕЧИ дома БЕЗ оборудования за 20 минут | Тренировка ДОМА в Спокойном Темпе',
    href: '1pehgzaFRog',
  },
  {
    id: 'sm6',
    title:
      'Упражнения Для Здоровой СПИНЫ и Красивой ОСАНКИ | Тренировка для Начинающих в Домашних Условиях',
    href: '9oS5NEt1RDM',
  },
  {
    id: 'sm7',
    title:
      'Как улучшить подвижность тазобедренных суставов | Комплекс упражнений для тазобедренного сустава',
    href: '2hIshhPB5mM',
  },
];

export const ExercisesDays = [
  {
    title: 'понедельник',
    id: 'd1',
    content: [
      {
        id: 'p1',
        title: 'Жиросжигающая тренировка на все тело',
        time: '19:00',
        duration: '55 минут',
        star1: '3',
        star2: '3',
        star3: '1',
        image: 'train11.png',
        vid: '',
      },
    ],
  },
  {
    title: 'вторник',
    id: 'd2',
    content: [
      {
        id: 'v1',
        title: 'Силовая тренировки (Ноги и Ягодицы)',
        time: '18:00',
        duration: '50 минут',
        star1: '3',
        star2: '3',
        star3: '2',
        image: 'train21.png',
        vid: '',
      },
    ],
  },
  {
    title: 'среда',
    id: 'd3',
    content: [
      {
        id: 'с2',
        title: 'Табата (высокоинтенсивная тренировка)',
        time: '19:00',
        duration: '55 минут',
        star1: '3',
        star2: '3',
        star3: '1',
        image: 'train32.png',
        vid: '',
      },
    ],
  },
  {
    title: 'четверг',
    id: 'd4',
    content: [
      {
        id: 't1',
        title: 'Жиросжигающая тренировка на все тело',
        time: '19:00',
        duration: '40 минут',
        star1: '3',
        star2: '3',
        star3: '3',
        image: 'train41.png',
        vid: '',
      },
    ],
  },
  {
    title: 'пятница',
    id: 'd5',
    content: [
      {
        id: 'pt1',
        title: 'Силовая тренировки (Спина, Руки, Плечи, Пресс)',
        time: '19:00',
        duration: '50 минут',
        star1: '3',
        star2: '3',
        star3: '2',
        image: 'train51.png',
        vid: '',
      },
    ],
  },
];

export const FoodGroups = [
  { id: 'M1', title: 'Молочный отдел' },
  { id: 'M2', title: 'Мясо' },
  { id: 'M3', title: 'Рыба' },
  { id: 'M4', title: 'Овощи и фрукты' },
  { id: 'M5', title: 'Бакалея' },
  { id: 'M6', title: 'Орехи и сухофрукты' },
  { id: 'M7', title: 'Хлебный отдел' },
  { id: 'M8', title: 'Прочие продукты' },
];
