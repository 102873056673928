export interface IRecipeSessionStorage {
  selectedCategory: number;
}

export class SessionStorageUtil {
  public static RECIPES_SELECTED_CATEGORY: string = 'recipes.selectedCategory';
  public static RECIPES_SEARCH_FILTER: string = 'recipes.searchFilter';
  public static RECIPES_SCROLL_POSITION: string = 'recipes.scrollPosition';

  public static getInt(key: string): number {
    const result: number = Number.parseInt(sessionStorage.getItem(key));
    return result ? result : 0;
  }

  public static setInt(key: string, value: number) {
    sessionStorage.setItem(key, value.toString());
  }

  public static getString(key: string): string {
    const result: string = sessionStorage.getItem(key);
    return result ? result : '';
  }

  public static setString(key: string, value: string) {
    sessionStorage.setItem(key, value);
  }

  public static remove(key: string) {
    sessionStorage.removeItem(key);
  }
}
