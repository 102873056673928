import {IPlanVO} from "../types/IPlanVO";
import {IPlanCategoryData} from "../types/IPlanCategory";

export namespace PlanConfigKeto {

    export const categories: IPlanCategoryData[] = [
        {
            id: 0,
            name: "Категория 1",
            type: "Белки",
            hrefId: "cat1",
            image: "turkey.png",
            description: "Продукты 1-ой категории",
        },
        {
            id: 1,
            name: "Категория 2",
            hrefId: "cat2",
            type: "Жиры",
            image: "avocado.png",
            description: "Продукты 2-ой категории",
        },
        {
            id: 2,
            name: "Категория 3",
            hrefId: "cat3",
            type: "Углеводы",
            image: "paprika.png",
            description: "Продукты 3-ой категории",
        }
    ];

    export const breakfast: IPlanVO = {
        id: 0,
        name: "Завтрак",
        calories: 1600,
        image: "cat1.png",
        categories: [
            {
                id: 0,
                data: [
                    [1, "Мясо", "Желтый", 70, "product1.png", "Говядина жирная, свинина, баранина, курица голень, курица крыло, курица бедро"],
                    [2, "Рыба", "Зеленый", 165, "product4.png", "Масляная, палтус, тунец, кальмары, креветки, устрицы, морские гребешки, мидии, осьминоги, крабы"],
                    [3, "Рыба", "Желтый", 100, "product3.png", "Скумбрия, сайра, сардины, осетр, сельдь, севрюга, сом, лосось, горбуша, кета, анчоусы, килька, салака"],
                    [4, "Субпродукты", "Желтый", 115, "product6.png", "Куриное сердце, куриная печень, куриные желудки, говяжий язык, говяжья печень, свиная печень, говяжье сердце, свиное сердце"],
                    [5, "Тофу", "Желтый", 210, "product8.png", "Тофу"],
                    [6, "Яйца", "Зеленый", 95, "product9.png", "Куриное, перепелиное (5 шт. - 1 куриное)", 55],
                    [7, "Творог", "Зеленый", 65, "product10.png", "18% и выше"]
                ]
            },
            {
                id: 1,
                data: [
                    [1,"Сыр","Желтый",90, "cheese.png", "Маскарпоне, швейцарский, чеддер, горгонзола, камамбер, бри, филадельфия, моцарелла"],
                    [2,"Масло растительное","Желтый",30, "product13.png", "Масло кокосовое, масло какао, масло оливковое холодного отжима, масло авокадо"],
                    [3,"Масло сливочное","Желтый",35, "product14.png", "Сало, масло топленое (ГХИ), масло сливочное 82,5%, свиной жир"],
                    [4,"Орехи","Желтый",45, "product15.png", "Макадамия, фундук, кокосовая стружка, пекан, кедровый, бразильский, грецкий, миндаль, миндальная мука"],
                    [5,"Семена","Желтый",55, "product16.png", "Семена кунжута, семена тыквы, семена льна, семена чиа, семена конопли"],
                    [6,"Сливки","Желтый",110, "product17.png", "Сливки 33%, кокосовые сливки"],
                    [7,"Сметана","Желтый",120, "product18.png", "Жирность 25%"],
                    [8,"Авокадо","Желтый",140, "product19.png", "Авокадо"],
                    [9,"Молоко","Желтый",205, "product17.png", "Кокосовое молоко, миндальное молоко"],
                    [10,"Плоды","Зеленый",210, "product20.png", "Оливки, маслины"],
                    [11,"Кокос","Желтый",85, "product33.png", "Кокос"],
                    [12,"Бекон","Желтый",60, "product34.png", "Бекон"]
                ]
            },
            {
                id: 2,
                data: [
                    [1,"Овощи","Зеленый",180, "product26.png", "Капуста цветная, брокколи, савойская капуста, капуста белокочанная, перец болгарский, кабачок, фасоль стручковая, баклажан, шпинат, дайкон, спаржа, помидор, редис, огурец, капуста пекинская, сельдерей стебли"],
                    [2,"Овощи","Желтый",95, "product27.png", "Капуста брюссельская, капуста кольраби"],
                    [3,"Зелень","Зеленый",140, "product35.png", "Петрушка, укроп, базилик, руккола, кинза, салат"],
                    [4,"Фрукты","Желтый",100, "product30.png", "Нектарин, киви, яблоко, персик, абрикос, груша, слива, мандарин, грейпфрут, лимон"],
                    [5,"Ягоды","Желтый",100, "product31.png", "Вишня, малина, черника, крыжовник, земляника, клубника, ежевика, голубика, физалис"],
                    [6,"Грибы","Зеленый",150, "product32.png", "Шампиньоны, белый гриб, лисички"]
                ]
            }
        ]
    };

    export const dinner: IPlanVO = {
        id: 1,
        name: "Обед",
        calories: 1600,
		image: "cat2.png",
        categories: breakfast.categories
    };

    export const lunch: IPlanVO = {
        id: 2,
        name: "Перекус",
        image: "cat3.png",
        calories: 1600,
        categories: [
            {
                id: 0,
                data: [
                    [1, "Мясо", "Желтый", 35, "product1.png", "Говядина жирная, свинина, баранина, курица голень, курица крыло, курица бедро"],
                    [2, "Рыба", "Зеленый", 85, "product4.png", "Масляная, палтус, тунец, кальмары, креветки, устрицы, морские гребешки, мидии, осьминоги, крабы"],
                    [3, "Рыба", "Желтый", 50, "product3.png", "Скумбрия, сайра, сардины, осетр, сельдь, севрюга, сом, лосось, горбуша, кета, анчоусы, килька, салака"],
                    [4, "Субпродукты", "Желтый", 55, "product6.png", "Куриное сердце, куриная печень, говяжий язык, язык свиной, говяжья печень, свиная печень, говяжье сердце, свиное сердце"],
                    [5, "Тофу", "Желтый", 105, "product8.png", "Тофу"],
                    [6, "Яйца", "Зеленый", 45, "product9.png", "Куриное, перепелиное (5 шт. - 1 куриное)", 55],
                    [7, "Творог", "Зеленый", 35, "product10.png", "18% и выше"]
                ]
            },
            {
                id: 1,
                data: [
                    [1, "Сыр", "Желтый", 75, "cheese.png", "Маскарпоне, швейцарский, чеддер, горгонзола, камамбер, бри, филадельфия, моцарелла"],
                    [2, "Масло растительное", "Желтый", 25, "product13.png", "Масло кокосовое, масло какао, масло оливковое холодного отжима, масло авокадо"],
                    [3, "Масло сливочное", "Желтый", 30, "product14.png", "Сало, масло топленое (ГХИ), масло сливочное 82,5%, свиной жир"],
                    [4, "Орехи", "Желтый", 35, "product15.png", "Макадамия, фундук, кокосовая стружка, пекан, кедровый, бразильский, грецкий, миндаль, миндальная мука"],
                    [5, "Семена", "Желтый", 45, "product16.png", "Семена кунжута, семена тыквы, семена льна, семена чиа, семена конопли"],
                    [6, "Сливки", "Желтый", 90, "product17.png", "Сливки 33%, кокосовые сливки"],
                    [7, "Сметана", "Желтый", 100, "product18.png", "Жирность 25%"],
                    [8, "Авокадо", "Желтый", 115, "product19.png", "Авокадо"],
                    [9,"Молоко","Желтый",170, "product17.png", "Кокосовое молоко, миндальное молоко"],
                    [10,"Плоды","Зеленый",175, "product20.png", "Оливки, маслины"],
                    [11,"Кокос","Желтый",70, "product33.png", "Кокос"],
                    [12,"Бекон","Желтый",50, "product34.png", "Бекон"]
                ]
            },
            {
                id: 2,
                data: [
                    [1,"Овощи","Зеленый",240, "product26.png", "Капуста цветная, брокколи, савойская капуста, капуста белокочанная, перец болгарский, кабачок, фасоль стручковая, баклажан, шпинат, дайкон, спаржа, помидор, редис, огурец, капуста пекинская, сельдерей стебли"],
                    [2,"Овощи","Желтый",125, "product27.png", "Капуста брюссельская, капуста кольраби"],
                    [3,"Зелень","Зеленый",185, "product35.png", "Петрушка, укроп, базилик, руккола, кинза, салат"],
                    [4,"Фрукты","Желтый",135, "product30.png", "Нектарин, киви, яблоко, персик, абрикос, груша, слива, мандарин, грейпфрут, лимон"],
                    [5,"Ягоды","Желтый",130, "product31.png", "Вишня, малина, черника, крыжовник, земляника, клубника, ежевика, голубика, физалис"],
                    [6,"Грибы","Зеленый",200, "product32.png", "Шампиньоны, белый гриб, лисички"]
                ]
            }
        ]
    };

    export const supper: IPlanVO = {
        id: 3,
        name: "Ужин",
		image: "cat4.png",
        calories: 1600,
        categories: [
            {
                id: 0,
                data: [
                    [1, "Мясо", "Желтый", 50, "product1.png", "Говядина жирная, свинина, баранина, курица голень, курица крыло, курица бедро"],
                    [2, "Рыба", "Зеленый", 120, "product4.png", "Масляная, палтус, тунец, кальмары, креветки, устрицы, морские гребешки, мидии, осьминоги, крабы"],
                    [3, "Рыба", "Желтый", 70, "product3.png", "Скумбрия, сайра, сардины, осетр, сельдь, севрюга, сом, лосось, горбуша, кета, анчоусы, килька, салака"],
                    [4, "Субпродукты", "Желтый", 80, "product6.png", "Куриное сердце, куриная печень, говяжий язык, язык свиной, говяжья печень, свиная печень, говяжье сердце, свиное сердце"],
                    [5, "Тофу", "Желтый", 150, "product8.png", "Тофу"],
                    [6, "Яйца", "Зеленый", 70, "product9.png", "Куриное, перепелиное (5 шт. - 1 куриное)", 55],
                    [7, "Творог", "Зеленый", 45, "product10.png", "18% и выше"]
                ]
            },
            {
                id: 1,
                data: [
                    [1, "Сыр", "Желтый", 45, "cheese.png", "Маскарпоне, швейцарский, чеддер, горгонзола, камамбер, бри, филадельфия, моцарелла"],
                    [2, "Масло растительное", "Желтый", 15, "product13.png", "Масло кокосовое, масло какао, масло оливковое холодного отжима, масло авокадо"],
                    [3, "Масло сливочное", "Желтый", 20, "product14.png", "Сало, масло топленое (ГХИ), масло сливочное 82,5%, свиной жир"],
                    [4, "Орехи", "Желтый", 20, "product15.png", "Макадамия, фундук, кокосовая стружка, пекан, кедровый, бразильский, грецкий, миндаль, миндальная мука"],
                    [5, "Семена", "Желтый", 30, "product16.png", "Семена кунжута, семена тыквы, семена льна, семена чиа, семена конопли"],
                    [6, "Сливки", "Желтый", 55, "product17.png", "Сливки 33%, кокосовые сливки"],
                    [7, "Сметана", "Желтый", 60, "product18.png", "Жирность 25%"],
                    [8, "Авокадо", "Желтый", 70, "product19.png", "Авокадо"],
                    [9,"Молоко","Желтый",100, "product17.png", "Кокосовое молоко, миндальное молоко"],
                    [10,"Плоды","Зеленый",105, "product20.png", "Оливки, маслины"],
                    [11,"Кокос","Желтый",40, "product33.png", "Кокос"],
                    [12,"Бекон","Желтый",30, "product34.png", "Бекон"]
                ]
            }
        ]
    };

    export const ketoCathegories: IPlanVO[] = [
        PlanConfigKeto.breakfast,
        PlanConfigKeto.dinner,
        PlanConfigKeto.lunch,
        PlanConfigKeto.supper
    ];
}