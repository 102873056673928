import { Component } from 'react';
import { images } from '../../../../components/Utils/componentUtils';
import { IPlanVO } from '../../../../types/IPlanVO';
import { PlanCategory } from '../PlanCategory';
import { inject, observer } from 'mobx-react';
import { PlanStore, UserStore } from 'stores';

@inject('planStore')
@inject('userStore')
@observer
export class PlanCategories extends Component<any, any> {
  render() {
    const planStore: PlanStore = this.props.planStore;
    const userStore: UserStore = this.props.userStore;

    if (userStore.profile === null) {
      return <div>Загрузка профиля....</div>;
    }

    const planVO: IPlanVO = planStore.getSelectedCategory();

    function scrollToElement(element) {
      if (document.body.contains(document.getElementById(element))) {
        window.scrollTo({
          top: document.getElementById(element).offsetTop - 65,
          behavior: 'smooth',
        });
      }
    }

    return (
      <div>
        <div className="category__title" id="food1">
          {planVO.name}
          <img src={images[planVO.image].default} alt="категория" />
        </div>
        {planVO.categories.map((categoryVO) => {
          return <PlanCategory key={categoryVO.id} categoryVO={categoryVO} />;
        })}
      </div>
    );
  }
}
