import { useEffect, useState } from 'react';
import {
  images,
  PendingError,
  PhoneNumber,
} from '../../../components/Utils/componentUtils';
import moment from 'moment';
import { Role } from '../../../data';
import { inject, observer } from 'mobx-react';
import { Controller, useForm } from 'react-hook-form';
import { RegexpConstants } from '../../../components/Constants/RegexpConstants';
import UserActions from '../../../connector/userActions';
import { UiStore, UserStore } from 'stores';
import { hasAccessRole } from 'utils/utils';
import Select from 'react-dropdown-select';
import { countries } from 'data/countries';
import { DiseasesStore } from 'stores/diseasesStore';
import {
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';

export interface IProfileInfoInputs {
  lastName?: string;
  firstName?: string;
  patronymic?: string;
  dob?: string;
  currentWeight?: number;
  targetWeight?: string;
  height?: string;
  caloriesPlan?: number;
  mealPlan?: number;
  country?: string;
  city?: string;
  phoneNumber?: string;
  diseases?: any;
}

const ProfileInfo = inject(
  'userStore',
  'uiStore',
  'recipeStore',
  'diseasesStore'
)(
  observer((props) => {
    const [requestError, setRequestError] = useState(null);

    const userStore: UserStore = props.userStore;
    const uiStore: UiStore = props.uiStore;
    const user = userStore.profile;
    const diseasesStore: DiseasesStore = props.diseasesStore;

    const onErrorClose = () => {
      setRequestError(null);
      // window.location.pathname = '/';
    };

    const {
      register,
      handleSubmit,
      setValue,
      control,
      formState: { errors },
    } = useForm<IProfileInfoInputs>({ mode: 'onChange' });

    const readValuesFromStore = () => {
      setValue('lastName', userStore.profile?.lastName || '');
      setValue('firstName', userStore.profile?.firstName || '');
      setValue('patronymic', userStore.profile?.patronymic || '');
      setValue(
        'dob',
        userStore.profile?.dob
          ? moment(userStore.profile?.dob).format('DD.MM.YYYY')
          : null
      );
      setValue(
        'currentWeight',
        userStore.profile?.measurements.length
          ? userStore.profile?.measurements[
              userStore.profile?.measurements.length - 1
            ]?.weight
          : ''
      );
      setValue('targetWeight', userStore.profile?.targetWeight || '');
      setValue('height', userStore.profile?.height || '');
      setValue('caloriesPlan', userStore.profile?.caloriesPlan);
      setValue('mealPlan', userStore.profile?.mealPlan);
      setValue('country', userStore.profile?.country || '');
      setValue('city', userStore.profile?.city || '');
      setValue('phoneNumber', userStore.profile?.phoneNumber || '');
      setValue('diseases', userStore.profile?.diseases || []);
    };

    useEffect(() => {
      const fetchData = async () => {
        uiStore.loadingOn();
        await props.diseasesStore.getAllDiseases();
        userStore.getProfile().then(() => {
          readValuesFromStore();
          uiStore.loadingOff();
        });
      };

      fetchData();

      // eslint-disable-next-line
    }, []);

    const onSaveProfileInfo = (data: IProfileInfoInputs) => {
      const profileInfo = {
        first_name: data.firstName,
        last_name: data.lastName,
        patronymic: data.patronymic,
        dob: moment(data.dob, 'DD.MM.YYYY').format('YYYY-MM-DD'),
        height: parseInt(data.height),
        country: data.country,
        city: data.city,
        phone_number: data.phoneNumber,
        calories_plan: Number(data.caloriesPlan) || null,
        meal_plan: Number(data.mealPlan) || null,
        target_weight: parseInt(data.targetWeight),
        diseases: data.diseases,
      };

      uiStore.loadingOn();

      if (!userStore.profile.firstName || !userStore.profile.lastName) {
        UserActions.updateProfileInfo(profileInfo, null, () => {
          uiStore.loadingOff();
          uiStore.showDialog(
            'Анкета участника заполнена! Пожалуйста, внесите свои замеры.',
            'Профиль обновлен. ',
            () => {
              uiStore.clearDialog();
              window.location.href = '/measurement/submit';
            },
            () => {
              uiStore.clearDialog();
              window.location.href = '/measurement/submit';
            },
            'popup--style popup--blue'
          );
        });
      } else {
        UserActions.updateProfileInfo(profileInfo, null, () => {
          uiStore.loadingOff();
          uiStore.showDialog(
            'Калораж и план питания можно обновлять не чаще раза в сутки.',
            'Профиль обновлен. ',
            () => {
              uiStore.clearDialog();
              window.location.href = '/profile';
            },
            () => {
              uiStore.clearDialog();
              window.location.href = '/profile';
            },
            'popup--style popup--blue'
          );
        });
      }
    };

    return user ? (
      <div className="container">
        <h1>
          Личная <span>карта пользователя</span>
          <img width="30" src={images['pencil.png'].default} alt="pencil" />
        </h1>
        {/* <AvatarPost /> */}

        <form
          className="profil"
          action="/"
          encType="multipart/form-data"
          method="post"
          onSubmit={handleSubmit(onSaveProfileInfo)}
        >
          {/* Фамилия */}
          <div className="formquestion">
            <div className="blocktitle">1. Фамилия</div>
            <input
              type="text"
              name="lastName"
              placeholder="Ваша фамилия"
              {...register('lastName', {
                required: 'Требуется указать фамилию',
                maxLength: {
                  value: 32,
                  message: 'Значение не может быть длиннее 32 символов',
                },
              })}
            />
            {errors.lastName && (
              <div className="measure_err">{errors.lastName?.message}</div>
            )}
          </div>

          {/* Имя */}
          <div className="formquestion">
            <div className="blocktitle">2. Имя</div>
            <input
              type="text"
              name="firstName"
              placeholder="Ваше имя"
              {...register('firstName', {
                required: 'Требуется указать имя',
                maxLength: {
                  value: 32,
                  message: 'Значение не может быть длиннее 32 символов',
                },
              })}
            />
            {errors.firstName && (
              <div className="measure_err">{errors.firstName?.message}</div>
            )}
          </div>

          {/* Отчество */}
          <div className="formquestion">
            <div className="blocktitle">3. Отчество</div>
            <input
              type="text"
              name="patronymic"
              placeholder="Ваше отчество"
              {...register('patronymic', {
                maxLength: {
                  value: 32,
                  message: 'Значение не может быть длиннее 32 символов',
                },
              })}
            />
            {errors.patronymic && (
              <div className="measure_err">{errors.patronymic?.message}</div>
            )}
          </div>

          {/* Телефон */}
          <div className="formquestion">
            <div className="blocktitle">4. Телефон</div>
            <Controller
              control={control}
              name={'phoneNumber'}
              rules={{
                required: 'Номер телефона обязателен',
              }}
              render={({ fieldState: { isTouched, invalid }, field }) => {
                const isInValid =
                  isTouched &&
                  (!field.value || invalid || field.value?.length < 15);

                return (
                  <>
                    <PhoneNumber
                      className={
                        isInValid ? 'signUpInputNotValid' : 'signUpInputDefault'
                      }
                      {...field}
                    />
                    {isInValid && (
                      <div className="signUpError">
                        {'Невалидное значение. Пример "+79112223344"'}
                      </div>
                    )}
                  </>
                );
              }}
            />
          </div>

          {/* Дата рождения */}
          <div className="formquestion">
            <div className="blocktitle">5. Дата рождения: день.месяц.год</div>
            <input
              type="text"
              name="dob"
              placeholder="Дата рождения: дд.мм.гггг"
              {...register('dob', {
                required: 'Требуется указать дату рождения. Формат дд.мм.гггг',
                pattern: {
                  value: RegexpConstants.dateRegexp,
                  message:
                    'Невалидное значение. Формат дд.мм.гггг Пример "01.12.1986"',
                },
                validate: (value) => {
                  const ts = moment(value, 'DD.MM.YYYY').valueOf();
                  const invalid =
                    ts > moment().subtract(14, 'year').valueOf() ||
                    ts < moment().subtract(99, 'year').valueOf();
                  if (invalid) {
                    return 'Допустимый возраст от 14 до 99 лет';
                  }
                },
              })}
            />
            {errors.dob && (
              <div className="measure_err">{errors.dob?.message}</div>
            )}
          </div>

          {/* Текущий вес */}
          {/* <div className="formquestion">
            <div className="blocktitle">6. Текущий вес</div>
            <div className="currentWeight">
              <input
                type="text"
                name="currentWeight"
                placeholder="Вес (последние замеры, кг)"
                {...register('currentWeight')}
              />
            </div>
          </div> */}

          {/* Желаемый вес */}
          <div className="formquestion">
            <div className="blocktitle">6. Желаемый вес</div>
            <input
              type="text"
              name="targetWeight"
              placeholder="Желаемый вес (кг)"
              {...register('targetWeight', {
                required: 'Введите значение от 30 до 140',
                pattern: {
                  value: RegexpConstants.floatRegexp,
                  message: 'Невалидное значение. Пример "59.8"',
                },
                min: {
                  value: 30,
                  message: 'Значение не может быть меньше 30',
                },
                max: {
                  value: 140,
                  message: 'Значение не может быть больше 140',
                },
              })}
            />
            {errors.targetWeight && (
              <div className="measure_err">{errors.targetWeight?.message}</div>
            )}
          </div>

          {/* Рост */}
          <div className="formquestion">
            <div className="blocktitle">7. Рост</div>
            <input
              type="text"
              name="height"
              placeholder="Рост (см)"
              {...register('height', {
                required: 'Введите целое значение от 50 до 250',
                pattern: {
                  value: RegexpConstants.intRegexp,
                  message: 'Невалидное значение. Пример "158"',
                },
                min: {
                  value: 50,
                  message: 'Значение не может быть меньше 50',
                },
                max: {
                  value: 250,
                  message: 'Значение не может быть больше 250',
                },
              })}
            />
            {errors.height && (
              <div className="measure_err">{errors.height?.message}</div>
            )}
          </div>

          {/* Заболевания */}
          <div className="formquestion">
            <div className="blocktitle">8. Заболевания</div>
            <Controller
              name="diseases"
              control={control}
              render={({ field }) => (
                <Autocomplete
                  className={'signUpInputDefault'}
                  multiple
                  id="diseases"
                  options={diseasesStore?.diseases}
                  getOptionLabel={(option) => option.title || ''}
                  onChange={(_, selectedOptions) => {
                    const selectedIds = selectedOptions.map(
                      (option) => option.id
                    );
                    field.onChange(selectedIds);
                  }}
                  value={
                    field.value?.map((id) =>
                      diseasesStore?.diseases.find(
                        (disease) => disease.id === id
                      )
                    ) || []
                  }
                  filterSelectedOptions
                  renderInput={(params) => (
                    <TextField {...params} type="search" />
                  )}
                />
              )}
              rules={{}}
            />
            {errors.diseases && (
              <div className="measure_err">{errors.diseases?.message}</div>
            )}
          </div>

          {/* Калораж */}
          {userStore.profile?.lastName && (
            <div className="formquestion">
              <div className="blocktitle">9. Калораж</div>
              <select
                defaultValue={
                  userStore.userCaloriesPlan?.id ===
                    userStore.profile?.caloriesPlan &&
                  userStore.userCaloriesPlan?.calories
                }
                style={{
                  color: !hasAccessRole(userStore, Role.CHANGE_PLAN)
                    ? 'black'
                    : 'gray',
                }}
                // disabled={!hasAccessRole(userStore, Role.CHANGE_PLAN)}
                name="caloriesPlan"
                {...register('caloriesPlan')}
                placeholder={'Выберите калораж'}
              >
                {!userStore.profile?.caloriesPlan && (
                  <option value={null}>Выберите калораж</option>
                )}
                {userStore?.caloriesPlan.map((caloriesPlanItem) => {
                  return (
                    <option
                      key={caloriesPlanItem?.id}
                      value={caloriesPlanItem?.id}
                    >
                      {caloriesPlanItem?.calories}
                    </option>
                  );
                })}
              </select>
              {userStore.userCaloriesPlan?.calories <= 1200 ? (
                <div className="infoblock">
                  Мы категорически не рекомендуем использовать план питания
                  менее 1200 ккал. Это может серьезно навредить вашему здоровью.
                </div>
              ) : (
                ''
              )}
              {errors.caloriesPlan && (
                <div className="measure_err">
                  {errors.caloriesPlan?.message}
                </div>
              )}
            </div>
          )}

          {/* Цель */}
          {!userStore.profile?.lastName && (
            <div className="formquestion formlabels">
              <div className="blocktitle">
                {!userStore.profile?.lastName ? '9' : '10'}. Какая у вас цель?
              </div>
              <Controller
                rules={{ required: 'Необходимо указать, цель' }}
                control={control}
                name={'mealPlan'}
                render={({ field: { value, onBlur, onChange } }) => (
                  <RadioGroup onChange={onChange} onBlur={onBlur}>
                    {userStore?.mealsPlan.map((mealPlanItem) => {
                      if (!mealPlanItem.descriptionForClient) return null;

                      return (
                        <FormControlLabel
                          key={mealPlanItem.id}
                          value={mealPlanItem.id}
                          control={<Radio />}
                          label={mealPlanItem.descriptionForClient}
                          checked={mealPlanItem.id === Number(value)}
                        />
                      );
                    })}
                  </RadioGroup>
                )}
              />
              {errors.mealPlan && (
                <div className="measure_err">{errors.mealPlan?.message}</div>
              )}
            </div>
          )}

          {/* План питания */}
          {userStore.profile?.lastName && (
            <div className="formquestion">
              <div className="blocktitle">
                {!userStore.profile?.lastName ? '9' : '10'}. План питания
              </div>
              <select
                defaultValue={
                  userStore.userMealPlan?.id === userStore.profile?.mealPlan &&
                  userStore.userMealPlan?.title
                }
                style={{
                  color: !hasAccessRole(userStore, Role.CHANGE_GOAL)
                    ? 'black'
                    : 'gray',
                }}
                // disabled={!hasAccessRole(userStore, Role.CHANGE_GOAL)}
                name="mealPlan"
                {...register('mealPlan')}
                placeholder={'План питания'}
              >
                {!userStore?.profile.mealPlan && (
                  <option value={null}>Выберите план питания</option>
                )}
                {userStore?.mealsPlan.map((mealPlanItem) => {
                  return (
                    <option key={mealPlanItem.id} value={mealPlanItem.id}>
                      {mealPlanItem.title}
                    </option>
                  );
                })}
              </select>
              {errors.mealPlan && (
                <div className="measure_err">{errors.mealPlan?.message}</div>
              )}
            </div>
          )}

          {/* Страна */}
          <div className="formquestion">
            <div className="blocktitle">
              {!userStore.profile?.lastName ? '10' : '11'}. Страна
            </div>
            <Controller
              name="country"
              control={control}
              render={({ field }) => (
                <Select
                  className="dropdown"
                  style={{
                    border: 'none',
                    borderRadius: 10,
                    padding: 16,
                  }}
                  multi={false}
                  searchable={true}
                  dropdownHandle={false}
                  options={countries}
                  values={[
                    {
                      label: field.value || '',
                      value: field.value || '',
                    },
                  ]}
                  placeholder="Страна проживания"
                  onChange={(value) => setValue('country', value[0]?.value)}
                />
              )}
              rules={{ required: 'Требуется указать страну проживания' }}
            />
            {errors.country && (
              <div className="measure_err">{errors.country?.message}</div>
            )}
          </div>

          {/* Город */}
          <div className="formquestion">
            <div className="blocktitle">
              {!userStore.profile?.lastName ? '11' : '12'}. Город
            </div>
            <input
              type="text"
              name="city"
              placeholder="Город проживания"
              {...register('city', {
                required: 'Требуется указать город проживания',
              })}
            />
            {errors.city && (
              <div className="measure_err">{errors.city?.message}</div>
            )}
          </div>

          <button className="profil__save" type="submit">
            сохранить
            <img width="20" src={images['diet.png'].default} alt="diet" />
          </button>
        </form>
      </div>
    ) : (
      <PendingError
        error={requestError}
        message={requestError?.message}
        onClose={onErrorClose}
      />
    );
  })
);

export default ProfileInfo;
