import { action, computed, makeObservable, observable } from 'mobx';
import { IRecipe } from '../types/IRecipe';
import { IStores } from '../types/IStores';
import { IBasketRecipe } from '../types/IBasketRecipe';
import { IBasketFood } from '../types/IBasketFood';
import { IFood } from '../types/IFood';
import BasketActions from '../connector/basketActions';
import { BaseStore } from 'stores';

export class BasketStore extends BaseStore {
  private countTimeout: ReturnType<typeof setTimeout>;

  @observable
  basketRecipes: any[] = null;

  @observable
  foods: any[] = null;

  @observable
  uiLocked: boolean;

  constructor(stores: IStores) {
    super(stores);
    makeObservable(this);
  }

  @action
  isInBasket(recipe) {
    return this.basketRecipes?.some((v) => v.id === recipe.id);
  }

  @action
  async loadBasket(cb?) {
    if (this.basketRecipes && this.foods) {
      return;
    }

    await BasketActions.getBasket()
      .then((response: any) => {
        this.basketRecipes = response.data.recipes;
        this.foods = response.data.foods;
        cb?.();
      })
      .catch((reason) => {
        this.stores.uiStore.showError('Не удалось загрузить корзину');
      });
  }

  @action
  decrementRecipe(recipe: IBasketRecipe) {
    if (recipe.count > 1) {
      recipe.count--;
      this.postRecipeCount(recipe);
    }
  }

  @action
  incrementRecipe(recipe: IBasketRecipe) {
    if (!recipe.count) {
      recipe.count = 1;
    }

    recipe.count++;
    this.postRecipeCount(recipe);
  }

  postRecipeCount(recipe: IBasketRecipe) {
    if (this.countTimeout) {
      clearTimeout(this.countTimeout);
    }
    this.countTimeout = setTimeout(() => {
      BasketActions.setBasketRecipeCount(recipe.id, recipe.count).catch(() => {
        this.stores.uiStore.showDialog(
          'Не удалось изменить количество',
          'Ошибка',
          () => this.loadBasket()
        );
      });
      this.countTimeout = null;
    }, 1000);
  }

  @action
  deleteRecipe(recipe: IBasketRecipe) {
    if (this.uiLocked) return;

    this.uiLocked = true;
    const oldRecipes = this.basketRecipes;
    this.basketRecipes = this.basketRecipes.filter((v) => v.id !== recipe.id);

    BasketActions.deleteRecipe(
      recipe.id,
      () => {
        this.basketRecipes = oldRecipes;
        this.stores.uiStore.showError('Не удалось удалить рецепт');
      },
      (response) => {
        this.setBasket(response.data);
      }
    );
    this.uiLocked = false;
  }

  @action
  addRecipe(recipe: IRecipe) {
    if (this.uiLocked) return;

    this.uiLocked = true;
    const oldRecipes = this.basketRecipes;
    this.basketRecipes = this.basketRecipes.concat([recipe]);
    BasketActions.addRecipe(
      recipe,
      () => {
        this.basketRecipes = oldRecipes;
        this.stores.uiStore.showError('Не удалось добавить рецепт');
      },
      () => {}
    );
    this.uiLocked = false;
  }

  @action
  setBasket(basket) {
    this.basketRecipes = basket.recipes;
    this.foods = basket.foods;
  }

  @action
  removeFood(foodId: number) {
    this.foods = this.foods.filter((item) => item !== foodId);

    BasketActions.removeFood(foodId).catch(() => {
      this.stores.uiStore.showDialog('', 'Ошибка', () => this.loadBasket());
    });
  }

  @action
  addFood(foodId: number) {
    this.foods.push(foodId);

    BasketActions.addFood(foodId).catch(() => {
      this.stores.uiStore.showDialog('', 'Ошибка', () => this.loadBasket());
    });
  }

  @action
  resetBasket() {
    if (this.uiLocked) return;

    this.uiLocked = true;
    BasketActions.resetBasket()
      .then((response: any) => {
        this.basketRecipes = response.data.recipes;
        this.foods = response.data.foods;
        this.uiLocked = false;
      })
      .catch(() => {
        this.stores.uiStore.showDialog(
          'Не удалось очистить корзину',
          'Ошибка',
          () => this.loadBasket()
        );
      });
  }

  @computed
  get sumBasketFoods(): IBasketFood[] {
    const foodsDict: { [id: number]: IBasketFood } = {};
    this.basketRecipes.forEach((recipe) => {
      recipe.foods.forEach((food: any) => {
        if (foodsDict[food.id]) {
          if (food.RecipeComponent.weight) {
            foodsDict[food.id].weight +=
              food.RecipeComponent.weight * recipe.count;
          }
          if (food.RecipeComponent.quantity) {
            foodsDict[food.id].quantity +=
              food.RecipeComponent.quantity * recipe.count;
          }
        } else {
          foodsDict[food.id] = {
            id: food.id,
            title: food.title,
            image: food.image,
            weight: food.RecipeComponent.weight * recipe.count,
            quantity: food.RecipeComponent.quantity * recipe.count,
            order: food.RecipeComponent.order,
            group: food.group,
          };
        }
      });
    });

    const result = [];
    for (let prop in foodsDict) {
      const food: IBasketFood = foodsDict[prop];
      food.isBought = this.foods.includes(food.id);
      result.push(food);
    }
    return result;
  }

  findRecipeInBasket(recipe: IRecipe): IBasketRecipe {
    return this.basketRecipes?.find((item) => item.id === recipe.id);
  }

  getFoodIndexInBasket(food: IFood): number {
    return this.foods.findIndex((item) => item === food.id);
  }
}
