export const countries = [
  {
    label: 'Австралия',
    value: 'Австралия',
  },
  {
    label: 'Австрия',
    value: 'Австрия',
  },
  {
    label: 'Азербайджан',
    value: 'Азербайджан',
  },
  {
    label: 'Албания',
    value: 'Албания',
  },
  {
    label: 'Алжир',
    value: 'Алжир',
  },
  {
    label: 'Ангилья ',
    value: 'Ангилья ',
  },
  {
    label: 'Ангола',
    value: 'Ангола',
  },
  {
    label: 'Андорра',
    value: 'Андорра',
  },
  {
    label: 'Антигуа и Барбуда',
    value: 'Антигуа и Барбуда',
  },
  {
    label: 'Аргентина ',
    value: 'Аргентина ',
  },
  {
    label: 'Армения ',
    value: 'Армения ',
  },
  {
    label: 'Аруба',
    value: 'Аруба',
  },
  {
    label: 'Афганистан',
    value: 'Афганистан',
  },
  {
    label: 'Багамские Острова',
    value: 'Багамские Острова',
  },
  {
    label: 'Бангладеш',
    value: 'Бангладеш',
  },
  {
    label: 'Барбадос',
    value: 'Барбадос',
  },
  {
    label: 'Бахрейн',
    value: 'Бахрейн',
  },
  {
    label: 'Бенин',
    value: 'Бенин',
  },
  {
    label: 'Белиз',
    value: 'Белиз',
  },
  {
    label: 'Беларусь',
    value: 'Беларусь',
  },
  {
    label: 'Бельгия',
    value: 'Бельгия',
  },
  {
    label: 'Бермуды ',
    value: 'Бермуды ',
  },
  {
    label: 'Бирма (Мьянма)',
    value: 'Бирма (Мьянма)',
  },
  {
    label: 'Болгария',
    value: 'Болгария',
  },
  {
    label: 'Боливия',
    value: 'Боливия',
  },
  {
    label: 'Босния и Герцеговина',
    value: 'Босния и Герцеговина',
  },
  {
    label: 'Ботсвана ',
    value: 'Ботсвана ',
  },
  {
    label: 'Бразилия ',
    value: 'Бразилия ',
  },
  {
    label: 'Бруней',
    value: 'Бруней',
  },
  {
    label: 'Буркина-Фасо',
    value: 'Буркина-Фасо',
  },
  {
    label: 'Бурунди',
    value: 'Бурунди',
  },
  {
    label: 'Бутан',
    value: 'Бутан',
  },
  {
    label: 'Вануату',
    value: 'Вануату',
  },
  {
    label: 'Великобритания',
    value: 'Великобритания',
  },
  {
    label: 'Венгрия',
    value: 'Венгрия',
  },
  {
    label: 'Венесуэла',
    value: 'Венесуэла',
  },
  {
    label: 'Виргинские острова',
    value: 'Виргинские острова',
  },
  {
    label: 'Виргинские острова Америка',
    value: 'Виргинские острова Америка',
  },
  {
    label: 'Восточный Тимор',
    value: 'Восточный Тимор',
  },
  {
    label: 'Вьетнам',
    value: 'Вьетнам',
  },
  {
    label: 'Габон',
    value: 'Габон',
  },
  {
    label: 'Гаити',
    value: 'Гаити',
  },
  {
    label: 'Гайана',
    value: 'Гайана',
  },
  {
    label: 'Гамбия',
    value: 'Гамбия',
  },
  {
    label: 'Гана',
    value: 'Гана',
  },
  {
    label: 'Гваделупа',
    value: 'Гваделупа',
  },
  {
    label: 'Гватемала',
    value: 'Гватемала',
  },
  {
    label: 'Гвинея',
    value: 'Гвинея',
  },
  {
    label: 'Гвинея-Бисау',
    value: 'Гвинея-Бисау',
  },
  {
    label: 'Германия',
    value: 'Германия',
  },
  {
    label: 'Гибралтар',
    value: 'Гибралтар',
  },
  {
    label: 'Гондурас',
    value: 'Гондурас',
  },
  {
    label: 'Гренада',
    value: 'Гренада',
  },
  {
    label: 'Гренландия',
    value: 'Гренландия',
  },
  {
    label: 'Греция',
    value: 'Греция',
  },
  {
    label: 'Грузия',
    value: 'Грузия',
  },
  {
    label: 'Гуам',
    value: 'Гуам',
  },
  {
    label: 'Дания',
    value: 'Дания',
  },
  {
    label: 'Демократическая Республика Конго',
    value: 'Демократическая Республика Конго',
  },
  {
    label: 'Джибути',
    value: 'Джибути',
  },
  {
    label: 'Джонстон (атолл)',
    value: 'Джонстон (атолл)',
  },
  {
    label: 'Доминика',
    value: 'Доминика',
  },
  {
    label: 'Доминиканская Республика',
    value: 'Доминиканская Республика',
  },
  {
    label: 'Египет ',
    value: 'Египет ',
  },
  {
    label: 'Замбия',
    value: 'Замбия',
  },
  {
    label: 'Зимбабве',
    value: 'Зимбабве',
  },
  {
    label: 'Израиль',
    value: 'Израиль',
  },
  {
    label: 'Индия',
    value: 'Индия',
  },
  {
    label: 'Индонезия',
    value: 'Индонезия',
  },
  {
    label: 'Иордания',
    value: 'Иордания',
  },
  {
    label: 'Ирак',
    value: 'Ирак',
  },
  {
    label: 'Иран',
    value: 'Иран',
  },
  {
    label: 'Ирландия',
    value: 'Ирландия',
  },
  {
    label: 'Исландия',
    value: 'Исландия',
  },
  {
    label: 'Испания',
    value: 'Испания',
  },
  {
    label: 'Италия',
    value: 'Италия',
  },
  {
    label: 'Йемен',
    value: 'Йемен',
  },
  {
    label: 'Кабо-Верде',
    value: 'Кабо-Верде',
  },
  {
    label: 'Казахстан',
    value: 'Казахстан',
  },
  {
    label: 'Каймановы острова',
    value: 'Каймановы острова',
  },
  {
    label: 'Камбоджа',
    value: 'Камбоджа',
  },
  {
    label: 'Камерун ',
    value: 'Камерун ',
  },
  {
    label: 'Канада',
    value: 'Канада',
  },
  {
    label: 'Канарские острова',
    value: 'Канарские острова',
  },
  {
    label: 'Катар',
    value: 'Катар',
  },
  {
    label: 'Кения',
    value: 'Кения',
  },
  {
    label: 'Кипр',
    value: 'Кипр',
  },
  {
    label: 'Кирибати',
    value: 'Кирибати',
  },
  {
    label: 'Китай',
    value: 'Китай',
  },
  {
    label: 'Колумбия',
    value: 'Колумбия',
  },
  {
    label: 'Коморы',
    value: 'Коморы',
  },
  {
    label: 'Конго',
    value: 'Конго',
  },
  {
    label: 'Корейская Народно-Демократическая Республика',
    value: 'Корейская Народно-Демократическая Республика',
  },
  {
    label: 'Косово',
    value: 'Косово',
  },
  {
    label: 'Коста-Рика ',
    value: 'Коста-Рика ',
  },
  {
    label: 'Кот-д’Ивуар',
    value: 'Кот-д’Ивуар',
  },
  {
    label: 'Куба',
    value: 'Куба',
  },
  {
    label: 'Кувейт',
    value: 'Кувейт',
  },
  {
    label: 'Кыргызстан',
    value: 'Кыргызстан',
  },
  {
    label: 'Лаос',
    value: 'Лаос',
  },
  {
    label: 'Латвия',
    value: 'Латвия',
  },
  {
    label: 'Лесото',
    value: 'Лесото',
  },
  {
    label: 'Либерия',
    value: 'Либерия',
  },
  {
    label: 'Ливан',
    value: 'Ливан',
  },
  {
    label: 'Ливия',
    value: 'Ливия',
  },
  {
    label: 'Литва',
    value: 'Литва',
  },
  {
    label: 'Лихтенштейн',
    value: 'Лихтенштейн',
  },
  {
    label: 'Люксембург',
    value: 'Люксембург',
  },
  {
    label: 'Маврикий',
    value: 'Маврикий',
  },
  {
    label: 'Мавритания',
    value: 'Мавритания',
  },
  {
    label: 'Мадагаскар',
    value: 'Мадагаскар',
  },
  {
    label: 'Майотта',
    value: 'Майотта',
  },
  {
    label: 'Макао',
    value: 'Макао',
  },
  {
    label: 'Малави',
    value: 'Малави',
  },
  {
    label: 'Малайзия',
    value: 'Малайзия',
  },
  {
    label: 'Мали',
    value: 'Мали',
  },
  {
    label: 'Мальдивы',
    value: 'Мальдивы',
  },
  {
    label: 'Мальта',
    value: 'Мальта',
  },
  {
    label: 'Марокко',
    value: 'Марокко',
  },
  {
    label: 'Мартиника',
    value: 'Мартиника',
  },
  {
    label: 'Маршалловы Острова',
    value: 'Маршалловы Острова',
  },
  {
    label: 'Мексика',
    value: 'Мексика',
  },
  {
    label: 'Мидуэй',
    value: 'Мидуэй',
  },
  {
    label: 'Мозамбик',
    value: 'Мозамбик',
  },
  {
    label: 'Молдавия',
    value: 'Молдавия',
  },
  {
    label: 'Монако',
    value: 'Монако',
  },
  {
    label: 'Монголия',
    value: 'Монголия',
  },
  {
    label: 'Монтсеррат',
    value: 'Монтсеррат',
  },
  {
    label: 'Намибия',
    value: 'Намибия',
  },
  {
    label: 'Науру',
    value: 'Науру',
  },
  {
    label: 'Непал',
    value: 'Непал',
  },
  {
    label: 'Нигер',
    value: 'Нигер',
  },
  {
    label: 'Нигерия',
    value: 'Нигерия',
  },
  {
    label: 'Нидерландские Антильские острова',
    value: 'Нидерландские Антильские острова',
  },
  {
    label: 'Нидерланды',
    value: 'Нидерланды',
  },
  {
    label: 'Никарагуа',
    value: 'Никарагуа',
  },
  {
    label: 'Ниуэ',
    value: 'Ниуэ',
  },
  {
    label: 'Новая Зеландия',
    value: 'Новая Зеландия',
  },
  {
    label: 'Новая Каледония',
    value: 'Новая Каледония',
  },
  {
    label: 'Норвегия',
    value: 'Норвегия',
  },
  {
    label: 'Объединённые Арабские Эмираты',
    value: 'Объединённые Арабские Эмираты',
  },
  {
    label: 'Оман',
    value: 'Оман',
  },
  {
    label: 'Остров Рождества',
    value: 'Остров Рождества',
  },
  {
    label: 'Остров Святой Елены',
    value: 'Остров Святой Елены',
  },
  {
    label: 'Пакистан',
    value: 'Пакистан',
  },
  {
    label: 'Палау',
    value: 'Палау',
  },
  {
    label: 'Палестина',
    value: 'Палестина',
  },
  {
    label: 'Пальмира',
    value: 'Пальмира',
  },
  {
    label: 'Панама',
    value: 'Панама',
  },
  {
    label: 'Папуа — Новая Гвинея',
    value: 'Папуа — Новая Гвинея',
  },
  {
    label: 'Парагвай',
    value: 'Парагвай',
  },
  {
    label: 'Перу',
    value: 'Перу',
  },
  {
    label: 'Польша',
    value: 'Польша',
  },
  {
    label: 'Португалия',
    value: 'Португалия',
  },
  {
    label: 'Пуэрто-Рико',
    value: 'Пуэрто-Рико',
  },
  {
    label: 'Республика Корея',
    value: 'Республика Корея',
  },
  {
    label: 'Республика Македония',
    value: 'Республика Македония',
  },
  {
    label: 'Реюньон',
    value: 'Реюньон',
  },
  {
    label: 'Российская Федерация',
    value: 'Российская Федерация',
  },
  {
    label: 'Руанда',
    value: 'Руанда',
  },
  {
    label: 'Румыния',
    value: 'Румыния',
  },
  {
    label: 'Сальвадор',
    value: 'Сальвадор',
  },
  {
    label: 'Самоа',
    value: 'Самоа',
  },
  {
    label: 'Сан-Марино',
    value: 'Сан-Марино',
  },
  {
    label: 'Сан-Томе и Принсипи',
    value: 'Сан-Томе и Принсипи',
  },
  {
    label: 'Саудовская Аравия',
    value: 'Саудовская Аравия',
  },
  {
    label: 'Свазиленд',
    value: 'Свазиленд',
  },
  {
    label: 'Северная Ирландия',
    value: 'Северная Ирландия',
  },
  {
    label: 'Северные Марианские острова',
    value: 'Северные Марианские острова',
  },
  {
    label: 'Сейшельские Острова',
    value: 'Сейшельские Острова',
  },
  {
    label: 'Сен-Мартен',
    value: 'Сен-Мартен',
  },
  {
    label: 'Сен-Пьер и Микелон',
    value: 'Сен-Пьер и Микелон',
  },
  {
    label: 'Сенегал',
    value: 'Сенегал',
  },
  {
    label: 'Сент-Винсент и Гренадины',
    value: 'Сент-Винсент и Гренадины',
  },
  {
    label: 'Сент-Китс и Невис',
    value: 'Сент-Китс и Невис',
  },
  {
    label: 'Сент-Люсия',
    value: 'Сент-Люсия',
  },
  {
    label: 'Сербия',
    value: 'Сербия',
  },
  {
    label: 'Сингапур',
    value: 'Сингапур',
  },
  {
    label: 'Сирия',
    value: 'Сирия',
  },
  {
    label: 'Словакия',
    value: 'Словакия',
  },
  {
    label: 'Словения',
    value: 'Словения',
  },
  {
    label: 'Соломоновы Острова',
    value: 'Соломоновы Острова',
  },
  {
    label: 'Сомали',
    value: 'Сомали',
  },
  {
    label: 'Судан',
    value: 'Судан',
  },
  {
    label: 'Суринам',
    value: 'Суринам',
  },
  {
    label: 'США',
    value: 'США',
  },
  {
    label: 'Сьерра-Леоне',
    value: 'Сьерра-Леоне',
  },
  {
    label: 'Таджикистан',
    value: 'Таджикистан',
  },
  {
    label: 'Таиланд',
    value: 'Таиланд',
  },
  {
    label: 'Тайвань',
    value: 'Тайвань',
  },
  {
    label: 'Танзания',
    value: 'Танзания',
  },
  {
    label: 'Теркс и Кайкос',
    value: 'Теркс и Кайкос',
  },
  {
    label: 'Того',
    value: 'Того',
  },
  {
    label: 'Тонга',
    value: 'Тонга',
  },
  {
    label: 'Тринидад и Тобаго',
    value: 'Тринидад и Тобаго',
  },
  {
    label: 'Тувалу',
    value: 'Тувалу',
  },
  {
    label: 'Тунис',
    value: 'Тунис',
  },
  {
    label: 'Турецкая Республика Северного Кипра',
    value: 'Турецкая Республика Северного Кипра',
  },
  {
    label: 'Туркмения',
    value: 'Туркмения',
  },
  {
    label: 'Турция',
    value: 'Турция',
  },
  {
    label: 'Уганда',
    value: 'Уганда',
  },
  {
    label: 'Узбекистан',
    value: 'Узбекистан',
  },
  {
    label: 'Украина',
    value: 'Украина',
  },
  {
    label: 'Уоллис и Футуна',
    value: 'Уоллис и Футуна',
  },
  {
    label: 'Уругвай',
    value: 'Уругвай',
  },
  {
    label: 'Уэйк',
    value: 'Уэйк',
  },
  {
    label: 'Уэльс',
    value: 'Уэльс',
  },
  {
    label: 'Федеративные Штаты Микронезии',
    value: 'Федеративные Штаты Микронезии',
  },
  {
    label: 'Фиджи',
    value: 'Фиджи',
  },
  {
    label: 'Филиппины',
    value: 'Филиппины',
  },
  {
    label: 'Финляндия',
    value: 'Финляндия',
  },
  {
    label: 'Фолклендские острова',
    value: 'Фолклендские острова',
  },
  {
    label: 'Франция',
    value: 'Франция',
  },
  {
    label: 'Французская Гвиана',
    value: 'Французская Гвиана',
  },
  {
    label: 'Французская Полинезия',
    value: 'Французская Полинезия',
  },
  {
    label: 'Французские Южные и Антарктические Территории',
    value: 'Французские Южные и Антарктические Территории',
  },
  {
    label: 'Хорватия',
    value: 'Хорватия',
  },
  {
    label: 'Центральноафриканская Республика',
    value: 'Центральноафриканская Республика',
  },
  {
    label: 'Чад',
    value: 'Чад',
  },
  {
    label: 'Черногория',
    value: 'Черногория',
  },
  {
    label: 'Чехия',
    value: 'Чехия',
  },
  {
    label: 'Чили',
    value: 'Чили',
  },
  {
    label: 'Швейцария',
    value: 'Швейцария',
  },
  {
    label: 'Швеция',
    value: 'Швеция',
  },
  {
    label: 'Шпицберген и Ян-Майен',
    value: 'Шпицберген и Ян-Майен',
  },
  {
    label: 'Шри-Ланка',
    value: 'Шри-Ланка',
  },
  {
    label: 'Эквадор',
    value: 'Эквадор',
  },
  {
    label: 'Экваториальная Гвинея',
    value: 'Экваториальная Гвинея',
  },
  {
    label: 'Эритрея',
    value: 'Эритрея',
  },
  {
    label: 'Эстония',
    value: 'Эстония',
  },
  {
    label: 'Эфиопия',
    value: 'Эфиопия',
  },
  {
    label: 'Южная Георгия и Южные Сандвичевы острова',
    value: 'Южная Георгия и Южные Сандвичевы острова',
  },
  {
    label: 'Южно-Африканская Республика',
    value: 'Южно-Африканская Республика',
  },
  {
    label: 'Ямайка',
    value: 'Ямайка',
  },
  {
    label: 'Япония',
    value: 'Япония',
  },
];
