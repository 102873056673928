import {BaseStore} from "./baseStore";
import {IStores} from "../types/IStores";
import {action, makeObservable, observable} from "mobx";

export class ValidationFormStore extends BaseStore {

    @observable
    invalidFields: string[] = [];

    @observable
    validationEnabled: boolean = false;

    constructor(stores: IStores) {
        super(stores);
        makeObservable(this);
    }

    @action
    reset() {
        this.invalidFields = [];
        this.validationEnabled = false;
    }

    @action
    markAsValid(fieldName: string) {
        this.invalidFields = this.invalidFields.filter(v => v!== fieldName);
    }

    @action
    markAsInvalid(fieldName: string) {
        this.invalidFields = this.invalidFields.concat(fieldName);
    }

    @action
    enableValidation() {
        this.validationEnabled = true;
    }

    @action
    disableValidation() {
        this.validationEnabled = false;
    }

    @action
    isInvalid(fieldName) {
        return this.invalidFields.includes(fieldName);
    }

}