import React from "react";
import {Link} from "react-router-dom";
import {images} from "../Utils/componentUtils";
import ProtectedComponent from "../Base/protected";

class StepsLeaderboard extends ProtectedComponent {

    render() {
        return (
            <div className="container">
                <h1>Лидерборд<br/><span>10 неделя</span> <img width="36" src={images["shoes.png"].default} alt="shoes"/></h1>
                <div className="title">ТОП 10 по результатам среднего количества шагов за 7 дней</div>
                <div className="board row board--steps">
                    <div className="board__col">
                        <div className="board__block">
                            <div className="board__img">
                                <img width="72" src={images["avatar3.png"].default} alt="avatar"/>
                                    <div className="board__place">1 место</div>
                            </div>
                            <div className="blocktitle">Слюсарева Наталья</div>
                            <table>
                                <tr>
                                    <td>
                                        <div>Шаги за день (среднее знач.)</div>
                                        <p>20 913</p></td>
                                    <td>
                                        <div>лотерейка</div>
                                        <p>10</p></td>
                                </tr>
                            </table>
                        </div>
                    </div>
                    <div className="board__col">
                        <div className="board__block">
                            <div className="board__img">
                                <img width="72" src={images["avatar4.png"].default} alt="avatar"/>
                                    <div className="board__place">2 место</div>
                            </div>
                            <div className="blocktitle">Богдан Алина</div>
                            <table>
                                <tr>
                                    <td>
                                        <div>Шаги за день (среднее знач.)</div>
                                        <p>20 585</p></td>
                                    <td>
                                        <div>лотерейка</div>
                                        <p>9</p></td>
                                </tr>
                            </table>
                        </div>
                    </div>
                    <div className="board__col">
                        <div className="board__block">
                            <div className="board__img">
                                <img width="72" src={images["avatar.png"].default} alt="avatar"/>
                                    <div className="board__place">3 место</div>
                            </div>
                            <div className="blocktitle">Анастасия Коваленко</div>
                            <table>
                                <tr>
                                    <td>
                                        <div>Шаги за день (среднее знач.)</div>
                                        <p>19 101</p></td>
                                    <td>
                                        <div>лотерейка</div>
                                        <p>8</p></td>
                                </tr>
                            </table>
                        </div>
                    </div>
                    <div className="board__col">
                        <div className="board__block">
                            <div className="board__img">
                                <img width="72" src={images["avatar2.png"].default} alt="avatar"/>
                                    <div className="board__place">4 место</div>
                            </div>
                            <div className="blocktitle">Рабцевич Кристина</div>
                            <table>
                                <tr>
                                    <td>
                                        <div>Шаги за день (среднее знач.)</div>
                                        <p>18 864</p></td>
                                    <td>
                                        <div>лотерейка</div>
                                        <p>7</p></td>
                                </tr>
                            </table>
                        </div>
                    </div>
                    <div className="board__col">
                        <div className="board__block">
                            <div className="board__img">
                                <img width="72" src={images["avatar.png"].default} alt="avatar"/>
                                    <div className="board__place">5 место</div>
                            </div>
                            <div className="blocktitle">Карницкая Наталья</div>
                            <table>
                                <tr>
                                    <td>
                                        <div>Шаги за день (среднее знач.)</div>
                                        <p>18 683</p></td>
                                    <td>
                                        <div>лотерейка</div>
                                        <p>6</p></td>
                                </tr>
                            </table>
                        </div>
                    </div>
                    <div className="board__col">
                        <div className="board__block">
                            <div className="board__img">
                                <img width="72" src={images["avatar5.png"].default} alt="avatar"/>
                                    <div className="board__place">6 место</div>
                            </div>
                            <div className="blocktitle">Елена Владимировна</div>
                            <table>
                                <tr>
                                    <td>
                                        <div>Шаги за день (среднее знач.)</div>
                                        <p>17 405</p></td>
                                    <td>
                                        <div>лотерейка</div>
                                        <p>5</p></td>
                                </tr>
                            </table>
                        </div>
                    </div>
                    <div className="board__col">
                        <div className="board__block">
                            <div className="board__img">
                                <img width="72" src={images["avatar6.png"].default} alt="avatar"/>
                                    <div className="board__place">7 место</div>
                            </div>
                            <div className="blocktitle">Зубенко Анна</div>
                            <table>
                                <tr>
                                    <td>
                                        <div>Шаги за день (среднее знач.)</div>
                                        <p>16 613</p></td>
                                    <td>
                                        <div>лотерейка</div>
                                        <p>4</p></td>
                                </tr>
                            </table>
                        </div>
                    </div>
                    <div className="board__col">
                        <div className="board__block">
                            <div className="board__img">
                                <img width="72" src={images["avatar7.png"].default} alt="avatar"/>
                                    <div className="board__place">8 место</div>
                            </div>
                            <div className="blocktitle">Мисник Татьяна</div>
                            <table>
                                <tr>
                                    <td>
                                        <div>Шаги за день (среднее знач.)</div>
                                        <p>14 713</p></td>
                                    <td>
                                        <div>лотерейка</div>
                                        <p>3</p></td>
                                </tr>
                            </table>
                        </div>
                    </div>
                    <div className="board__col">
                        <div className="board__block">
                            <div className="board__img">
                                <img width="72" src={images["avatar.png"].default} alt="avatar"/>
                                    <div className="board__place">9 место</div>
                            </div>
                            <div className="blocktitle">Моисеева Ирина</div>
                            <table>
                                <tr>
                                    <td>
                                        <div>Шаги за день (среднее знач.)</div>
                                        <p>14 304</p></td>
                                    <td>
                                        <div>лотерейка</div>
                                        <p>2</p></td>
                                </tr>
                            </table>
                        </div>
                    </div>
                    <div className="board__col">
                        <div className="board__block">
                            <div className="board__img">
                                <img width="72" src={images["avatar.png"].default} alt="avatar"/>
                                    <div className="board__place">10 место</div>
                            </div>
                            <div className="blocktitle">Баланина Наталья</div>
                            <table>
                                <tr>
                                    <td>
                                        <div>Шаги за день (среднее знач.)</div>
                                        <p>14 208</p></td>
                                    <td>
                                        <div>лотерейка</div>
                                        <p>1</p></td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
                <div className="info">Лидерборд сформирован по результатам среднего количества шагов за 7 дней с
                    имеющимся фотоподтверждением, присланных куратору до 10.00 понедельника (11.01.2021)
                </div>
                <div className="chat__block board__chat">
                    <div className="chat__title">ТОП 10 <img width="19" src={images["trophy.png"].default} alt="trophy"/></div>
                    <p>По результатам замеров</p>
                    <Link to="/leaderboard/weight" className="btn">подробнее</Link>
                </div>
            </div>
        )
    }
}

export default StepsLeaderboard;