import React from "react";
import {Link} from "react-router-dom";
import {images} from "../Utils/componentUtils";
import ProtectedComponent from "../Base/protected";

class WeightLeaderboard extends ProtectedComponent {

    render() {
        return (
            <div className="container">
                <h1>Лидерборд<br/><span>10 неделя</span> <img width="36" src={images["trophy.png"].default} alt="trophy"/></h1>
                <div className="title">ТОП 10 по результатам замеров 11.01.2021 г.</div>
                <div className="board row">
                    <div className="board__col">
                        <div className="board__block">
                            <div className="board__img">
                                <img width="72" src={images["avatar.png"].default} alt="avatar"/>
                                    <div className="board__place">1 место</div>
                            </div>
                            <div className="blocktitle">Анастасия Коваленко</div>
                            <table>
                                <tr>
                                    <td>
                                        <div>Потеря веса</div>
                                        <p>1,30 кг</p></td>
                                    <td>
                                        <div>% потери веса</div>
                                        <p>2.16 %</p></td>
                                    <td>
                                        <div>лотерейка</div>
                                        <p>10</p></td>
                                </tr>
                            </table>
                        </div>
                    </div>
                    <div className="board__col">
                        <div className="board__block">
                            <div className="board__img">
                                <img width="72" src={images["avatar2.png"].default} alt="avatar"/>
                                    <div className="board__place">2 место</div>
                            </div>
                            <div className="blocktitle">Рабцевич Кристина</div>
                            <table>
                                <tr>
                                    <td>
                                        <div>Потеря веса</div>
                                        <p>1,20 кг</p></td>
                                    <td>
                                        <div>% потери веса</div>
                                        <p>2.05 %</p></td>
                                    <td>
                                        <div>лотерейка</div>
                                        <p>9</p></td>
                                </tr>
                            </table>
                        </div>
                    </div>
                    <div className="board__col">
                        <div className="board__block">
                            <div className="board__img">
                                <img width="72" src={images["avatar4.png"].default} alt="avatar"/>
                                    <div className="board__place">3 место</div>
                            </div>
                            <div className="blocktitle">Слюсарева Наталья</div>
                            <table>
                                <tr>
                                    <td>
                                        <div>Потеря веса</div>
                                        <p>1,20 кг</p></td>
                                    <td>
                                        <div>% потери веса</div>
                                        <p>1,94 %</p></td>
                                    <td>
                                        <div>лотерейка</div>
                                        <p>8</p></td>
                                </tr>
                            </table>
                        </div>
                    </div>
                    <div className="board__col">
                        <div className="board__block">
                            <div className="board__img">
                                <img width="72" src={images["avatar4.png"].default} alt="avatar"/>
                                    <div className="board__place">4 место</div>
                            </div>
                            <div className="blocktitle">Богдан Алина</div>
                            <table>
                                <tr>
                                    <td>
                                        <div>Потеря веса</div>
                                        <p>1,00 кг</p></td>
                                    <td>
                                        <div>% потери веса</div>
                                        <p>1,79 %</p></td>
                                    <td>
                                        <div>лотерейка</div>
                                        <p>7</p></td>
                                </tr>
                            </table>
                        </div>
                    </div>
                    <div className="board__col">
                        <div className="board__block">
                            <div className="board__img">
                                <img width="72" src={images["avatar.png"].default} alt="avatar"/>
                                    <div className="board__place">5 место</div>
                            </div>
                            <div className="blocktitle">Карницкая Наталья</div>
                            <table>
                                <tr>
                                    <td>
                                        <div>Потеря веса</div>
                                        <p>1,30 кг</p></td>
                                    <td>
                                        <div>% потери веса</div>
                                        <p>1,56 %</p></td>
                                    <td>
                                        <div>лотерейка</div>
                                        <p>6</p></td>
                                </tr>
                            </table>
                        </div>
                    </div>
                    <div className="board__col">
                        <div className="board__block">
                            <div className="board__img">
                                <img width="72" src={images["avatar5.png"].default} alt="avatar"/>
                                    <div className="board__place">6 место</div>
                            </div>
                            <div className="blocktitle">Елена Владимировна</div>
                            <table>
                                <tr>
                                    <td>
                                        <div>Потеря веса</div>
                                        <p>1,00 кг</p></td>
                                    <td>
                                        <div>% потери веса</div>
                                        <p>1,41 %</p></td>
                                    <td>
                                        <div>лотерейка</div>
                                        <p>5</p></td>
                                </tr>
                            </table>
                        </div>
                    </div>
                    <div className="board__col">
                        <div className="board__block">
                            <div className="board__img">
                                <img width="72" src={images["avatar6.png"].default} alt="avatar"/>
                                    <div className="board__place">7 место</div>
                            </div>
                            <div className="blocktitle">Зубенко Анна</div>
                            <table>
                                <tr>
                                    <td>
                                        <div>Потеря веса</div>
                                        <p>1,00 кг</p></td>
                                    <td>
                                        <div>% потери веса</div>
                                        <p>1,30 %</p></td>
                                    <td>
                                        <div>лотерейка</div>
                                        <p>4</p></td>
                                </tr>
                            </table>
                        </div>
                    </div>
                    <div className="board__col">
                        <div className="board__block">
                            <div className="board__img">
                                <img width="72" src={images["avatar7.png"].default} alt="avatar"/>
                                    <div className="board__place">8 место</div>
                            </div>
                            <div className="blocktitle">Мисник Татьяна</div>
                            <table>
                                <tr>
                                    <td>
                                        <div>Потеря веса</div>
                                        <p>0,70 кг</p></td>
                                    <td>
                                        <div>% потери веса</div>
                                        <p>1,10 %</p></td>
                                    <td>
                                        <div>лотерейка</div>
                                        <p>3</p></td>
                                </tr>
                            </table>
                        </div>
                    </div>
                    <div className="board__col">
                        <div className="board__block">
                            <div className="board__img">
                                <img width="72" src={images["avatar.png"].default} alt="avatar"/>
                                    <div className="board__place">9 место</div>
                            </div>
                            <div className="blocktitle">Моисеева Ирина</div>
                            <table>
                                <tr>
                                    <td>
                                        <div>Потеря веса</div>
                                        <p>0,90 кг</p></td>
                                    <td>
                                        <div>% потери веса</div>
                                        <p>1,10 %</p></td>
                                    <td>
                                        <div>лотерейка</div>
                                        <p>2</p></td>
                                </tr>
                            </table>
                        </div>
                    </div>
                    <div className="board__col">
                        <div className="board__block">
                            <div className="board__img">
                                <img width="72" src={images["avatar.png"].default} alt="avatar"/>
                                    <div className="board__place">10 место</div>
                            </div>
                            <div className="blocktitle">Баланина Наталья</div>
                            <table>
                                <tr>
                                    <td>
                                        <div>Потеря веса</div>
                                        <p>0,60 кг</p></td>
                                    <td>
                                        <div>% потери веса</div>
                                        <p>1,05 %</p></td>
                                    <td>
                                        <div>лотерейка</div>
                                        <p>1</p></td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
                <div className="info">Лидерборд сформирован по результатам замеров, присланных до 10.00 понедельника
                    (11.01.2021)
                </div>
                <div className="chat__block board__chat">
                    <div className="chat__title">ТОП 10 <img width="19" src={images["shoes.png"].default} alt="shoes"/></div>
                    <p>По результатам среднего количества шагов за 7 дней</p>
                    <Link to="/leaderboard/steps" className="btn">подробнее</Link>
                </div>
            </div>
        )
    }
}

export default WeightLeaderboard;