export const foodLevels = {
    notSet: 0,
    enough: 1,
    many: 2,
    notEnough: 3
}

export const monthsStrings: string[] = [
    "янв", // 0
    "фев", // 1
    "мар", // 2
    "апр", // 3
    "май", // 4
    "июн", // 5
    "июл", // 6
    "авг", // 7
    "сен", // 8
    "окт", // 9
    "ноя", // 10
    "дек", // 11
];

export const timeRanges = {
    week: "week",
    month: "month",
    year: "year"
}