import { IMeasurement } from '../types/IMeasurement';
import { action, makeObservable, observable } from 'mobx';
import { IStores } from '../types/IStores';
import { timeRanges } from '../pages/Measurement/measurementsConstants';
import MeasurementActions from '../connector/measurementActions';
import { BaseStore } from 'stores';

export class MeasurementStore extends BaseStore {
  constructor(stores: IStores) {
    super(stores);
    makeObservable(this);
  }

  nowDate: Date = new Date();

  @observable
  clickedMeasurement: IMeasurement;

  @observable
  currentRange: string = timeRanges.month;

  @observable
  measurements: IMeasurement[] = [];

  @observable
  currentMeasurement: IMeasurement = {
    id: null,
    weight: 0,
    belly: 0,
    buttocks: 0,
    info: '',
    foodLevel: 0,
    lightExercises: 0,
    menstrualDay: 0,
    needComment: false,
    powerExercises: 0,
    steps: 0,
  };

  @action
  loadMeasurements() {
    this.stores.uiStore.loadingOn();
    MeasurementActions.getMeasurements(this.currentRange)
      .then((result: any) => {
        this.nowDate = result.data.nowDate;
        this.measurements = result.data.results;
        this.stores.uiStore.loadingOff();
        window.scroll(0, 0);
      })
      .catch(() => {
        this.stores.uiStore.showDialog(
          'Произошла ошибка при загрузке замеров. Попробуйте позже'
        );
        this.stores.uiStore.loadingOff();
      });
  }

  @action
  loadMeasurement(id: number, onSuccess) {
    MeasurementActions.getMeasurement(id)
      .then((result: any) => {
        this.currentMeasurement = result.data;
        onSuccess();
      })
      .catch(() => {
        this.stores.uiStore.showDialog(
          'Произошла ошибка при загрузке замера. Попробуйте позже'
        );
      });
  }

  @action
  setClickedMeasurement(measurement: IMeasurement) {
    this.clickedMeasurement = measurement;
  }

  @action
  setCurrentRange(range: string) {
    this.currentRange = range;
    this.loadMeasurements();
  }

  @action
  deleteMeasurement(id, onError) {
    MeasurementActions.deleteMeasurement(id)
      .then((result) => {
        this.measurements = this.measurements.filter((v) => v.id !== id);
        this.setClickedMeasurement(null);
        this.stores.uiStore.showDialog(
          'Замер удален. Закройте окно, чтобы вернуться к таблице замеров.',
          'Замер удален',
          undefined,
          undefined,
          'popup--style popup--blue'
        );
      })
      .catch(() => {
        this.showDeleteError(onError);
      });
  }

  private showDeleteError(onError) {
    this.stores.uiStore.showDialog(
      'Закройте окно, чтобы вернуться к таблице замеров.',
      'Ошибка при удалении замера',
      onError()
    );
  }
}
