import { inject, observer } from 'mobx-react';
import { ComponentProgress } from '../../components/Utils/componentUtils';
import BaseComponent from '../../components/Base/base';
import Plan from '../Plan';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { IconButton } from '@material-ui/core';

@inject('userStore', 'uiStore')
@observer
class Login extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      username: '',
      password: '',
      showPassword: false,
    };

    props.uiStore.loadingOff();
  }

  async handleSignIn(e) {
    e.preventDefault();

    const preparedUserName = this.state.username.includes('@')
      ? this.state.username
      : this.state.username.replace(/\D/g, '');

    if (!this.state.username || !this.state.password || !preparedUserName)
      return;

    this.props.userStore.logOut();

    await this.props.userStore.login(
      preparedUserName,
      this.state.password,
      async () => {
        const { pathname } = this.props.location;
        this.props.history.push(
          pathname === '/login' || !pathname ? '/' : pathname
        );
      }
    );
  }

  handlePhoneChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ username: event.target.value });
  };

  handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    this.setState({ password: event.target.value });
  };

  handleClickShowPassword(event) {
    event.preventDefault();
    this.setState({ showPassword: !this.state.showPassword });
  }

  render() {
    return this.props.userStore.hasToken() ? (
      <Plan />
    ) : (
      <div className="text-center userform__wrapper">
        <div className="userform">
          <h1 className="title">Вход</h1>
          <div className="profil">
            <form onSubmit={this.handleSignIn.bind(this)}>
              <input
                type="text"
                name="username"
                value={this.state.username}
                onChange={this.handlePhoneChange}
                placeholder="Email или телефон"
              />
              <input
                type={this.state.showPassword ? 'text' : 'password'}
                name="password"
                placeholder="Пароль"
                onChange={this.handlePasswordChange}
              />
              <IconButton
                aria-label="toggle password visibility"
                onClick={(event) => this.handleClickShowPassword(event)}
                edge="end"
              >
                {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
              {!this.props.uiStore.loading ? (
                <input
                  className="btn"
                  type="submit"
                  name="submit"
                  value="Войти"
                  onClick={this.handleSignIn.bind(this)}
                />
              ) : (
                <ComponentProgress />
              )}
            </form>
            <div
              className="userform__link"
              style={{
                display: 'flex',
                justifyContent: 'space-around',
              }}
            >
              <a onClick={() => this.props.history.push('/reset-password')}>
                Забыли пароль?
              </a>
              <a onClick={() => this.props.history.push('/registration')}>
                Создать учетную запись
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Login;
