import { Component } from "react";
import { images } from "../../../../components/Utils/componentUtils";
import { IPlanCategory } from "../../../../types/IPlanCategory";
import { PlanConfigSlimming } from "../../../../stores/planConfigSlimming";
import { observer } from "mobx-react";
import { PlanProduct } from '../PlanProduct';
import s from "../../Plan.module.scss"

export interface IPlanCategoryProp {
    categoryVO: IPlanCategory;
}
@observer
export class PlanCategory extends Component<any, any> {

    props: IPlanCategoryProp;

    render() {
        const categoryVO: IPlanCategory = this.props.categoryVO;
        const category = PlanConfigSlimming.categories[categoryVO.id];
        return (
            <>
                <div className="title cattitle" id={category.hrefId}>
                    <img width="38" src={images[category.image].default} alt="категория"/>
                    {category.description}
                </div>
                <div className={s.productwrap}>
                    {categoryVO.data.map((productVO, index) => {
                        return <PlanProduct key={index} productVO={productVO} foodCategoryId={category.id}/>;
                    })}

                </div>
            </>
        );
    }
}