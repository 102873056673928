import { Component, createRef } from 'react';
import { IProgressPhoto, IProgressPhotoSet } from '../../types/IProgressPhoto';
import { inject, observer } from 'mobx-react';
import {
  ComponentProgress,
  images,
} from '../../components/Utils/componentUtils';
import { ILoading } from '../../types/ILoading';
import { ProgressStore, UiStore } from 'stores';

export interface ProgressPhotoProps {
  title: string;
  type: string;
  progressStore?: ProgressStore;
  uiStore?: UiStore;
}

@inject('progressStore', 'uiStore')
@observer
export class ProgressPhotoComponent extends Component<
  ProgressPhotoProps,
  ILoading
> {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
    };
  }

  private async onAddPhoto(event) {
    this.setState({
      isLoading: true,
    });
    const files = event.target.files;
    if (files.length !== 1) {
      this.props.uiStore.showDialog('Выберите только один файл');
    } else if (files[0].type.indexOf('image') !== 0) {
      this.props.uiStore.showDialog(
        'Выберите файл с изображением: jpg, jpeg, gif, png'
      );
    } else {
      const formData: FormData = new FormData();
      formData.append('image', files[0]);
      formData.append('type', this.props.type);
      const lastPhotoSet: IProgressPhotoSet =
        this.props.progressStore.lastPhotoSet;
      if (lastPhotoSet && lastPhotoSet.canEdit) {
        formData.append('date', this.props.progressStore.lastPhotoSet.date);
      }
      await this.props.progressStore.addPhoto(formData);
    }
    this.setState({
      isLoading: false,
    });
  }

  private async onUpdatePhoto(event) {
    const progressStore: ProgressStore = this.props.progressStore;
    this.setState({
      isLoading: true,
    });
    const files = event.target.files;
    if (files.length !== 1) {
      this.props.uiStore.showDialog('Выберите только один файл');
    } else if (files[0].type.indexOf('image') !== 0) {
      this.props.uiStore.showDialog(
        'Выберите файл с изображением: jpg, jpeg, gif, png'
      );
    } else {
      const photo: IProgressPhoto =
        progressStore.lastPhotoSet.map[this.props.type];
      const formData: FormData = new FormData();
      formData.append('image', files[0]);
      await this.props.progressStore.updatePhoto(photo.id, formData);
    }
    this.setState({
      isLoading: false,
    });
  }

  private async onDeletePhoto(event) {
    const progressStore: ProgressStore = this.props.progressStore;
    this.setState({
      isLoading: true,
    });
    const photo: IProgressPhoto =
      progressStore.lastPhotoSet.map[this.props.type];
    await this.props.progressStore.deletePhoto(photo);
    this.setState({
      isLoading: false,
    });
  }

  render() {
    if (this.state.isLoading) {
      return (
        <div className="photoadd__col">
          <div className="photoadd__block">
            <div style={{ height: 20 }}>
              <ComponentProgress />
            </div>
          </div>
        </div>
      );
    }
    const progressStore: ProgressStore = this.props.progressStore;
    const photo: IProgressPhoto = progressStore.lastPhotoSet
      ? progressStore.lastPhotoSet.map[this.props.type]
      : null;
    if (photo) {
      return (
        <div className="photoadd__col">
          <div className="photoadd__block">
            <div className="photoadd__title">
              <img
                className="photoadd__delete"
                width="16"
                src={images['delete.png'].default}
                alt="delete"
                onClick={this.onDeletePhoto.bind(this)}
              />
              {this.props.title}
              <input
                className="photoadd__file"
                id={'photoUpdate-' + this.props.type}
                type="file"
                name="file"
                accept="image/*"
                onChange={this.onUpdatePhoto.bind(this)}
              />
              <label htmlFor={'photoUpdate-' + this.props.type}>
                <img
                  src={images['editphoto.svg'].default}
                  alt="Редактировать фото"
                />
              </label>
            </div>
            <div className="photoadd__body">
              <img
                width="70"
                src={progressStore.getPhotoURL(photo)}
                alt="Фото"
              />
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="photoadd__col">
          <div className="photoadd__block">
            <div className="photoadd__title">{this.props.title}</div>
            <div className="photoadd__body">
              <input
                className="photoadd__file"
                id={'photoAdd-' + this.props.type}
                type="file"
                name="file"
                accept="image/*"
                onChange={this.onAddPhoto.bind(this)}
              />
              <label className="btn" htmlFor={'photoAdd-' + this.props.type}>
                ДОБАВИТЬ
                <svg
                  width="14"
                  height="13"
                  viewBox="0 0 14 13"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M4.66667 6.66667L6.66667 4L8.66667 7V6H11.3333V1.33333C11.3333 0.598 10.7353 0 10 0H1.33333C0.598 0 0 0.598 0 1.33333V9.33333C0 10.0687 0.598 10.6667 1.33333 10.6667H6.66667V8H2L4 5.33333L4.66667 6.66667Z"
                    fill="white"
                  />
                  <path
                    d="M11.3333 7.3335H10V9.3335H8V10.6668H10V12.6668H11.3333V10.6668H13.3333V9.3335H11.3333V7.3335Z"
                    fill="white"
                  />
                </svg>
              </label>
            </div>
          </div>
        </div>
      );
    }
  }
}
