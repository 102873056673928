import { Component } from 'react';
import { Avatar, Tab, Tabs } from '@material-ui/core';
import { images } from '../../../../components/Utils/componentUtils';
import { inject, observer } from 'mobx-react';
import { SessionStorageUtil } from '../../../../components/Utils/sessionStorageUtil';
import { RecipeStore } from 'stores';

@inject('recipeStore')
@observer
export class MenuTabs extends Component<any, any> {
  componentDidMount() {
    const sessionSelectedCategory: number = SessionStorageUtil.getInt(
      SessionStorageUtil.RECIPES_SELECTED_CATEGORY
    );
    const recipeStore: RecipeStore = this.props.recipeStore;
    if (sessionSelectedCategory !== recipeStore.selectedCategory) {
      recipeStore.setCategory(sessionSelectedCategory);
    }
  }

  render() {
    const { recipeStore } = this.props;

    return (
      <Tabs
        value={this.props.recipeStore.selectedCategory}
        aria-label="Recipes categories tabs"
      >
        <Tab
          tabIndex={0}
          label={'Завтрак'}
          icon={<Avatar alt="Завтрак" src={images['cat1.png'].default} />}
          onClick={() => {
            recipeStore.setCategory(0, 'Завтрак');
          }}
        />
        <Tab
          tabIndex={1}
          label={'Обед'}
          icon={<Avatar alt="Обед" src={images['cat2.png'].default} />}
          onClick={() => {
            recipeStore.setCategory(1, 'Обед');
          }}
        />
        <Tab
          tabIndex={2}
          label={'Перекус'}
          icon={<Avatar alt="Перекус" src={images['cat3.png'].default} />}
          onClick={() => {
            recipeStore.setCategory(2, 'Перекус');
          }}
        />
        <Tab
          tabIndex={3}
          label={'Ужин'}
          icon={<Avatar alt="Ужин" src={images['cat4.png'].default} />}
          onClick={() => {
            recipeStore.setCategory(3, 'Ужин');
          }}
        />
      </Tabs>
    );
  }
}
