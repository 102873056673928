import { Link } from 'react-router-dom';
import { images } from '../../../../components/Utils/componentUtils';
import { inject, observer } from 'mobx-react';
import {
  ExercisesDays,
  GOOGLE_ARCHIVE_LINK,
  HOW_TO_CHOOSE_EXERCISE,
  Role,
  YANDEX_ARCHIVE_LINK,
} from '../../../../data';
import ProtectedComponent from '../../../../components/Base/protected';
import { hasAccessRole, roleErrorPopup } from 'utils/utils';

@inject('uiStore', 'userStore', 'recipeStore')
@observer
class ExercisesWeek extends ProtectedComponent {
  render() {
    let weekdaytext, trainsword;
    let weekday = new Date().getDay();
    if (weekday !== 0) {
      weekday = weekday - 1;
      weekdaytext = 'Сегодня';
    } else {
      weekdaytext = 'В понедельник';
    }
    trainsword =
      ExercisesDays[weekday].content.length > 1 ? 'тренировки' : 'тренировка';
    return (
      <div className="container">
        <h1>
          Тренировки <span>на неделю</span>{' '}
          <img width="38" src={images['menu3.png'].default} alt="trains" />
        </h1>
        <div className="planmenu">
          <ul>
            <li className="active">
              <Link to="/exercises_week">Прямые эфиры</Link>
            </li>
            <li>
              <Link to="/exercises">в записи</Link>
            </li>
          </ul>
        </div>
        <div className="title">
          {weekdaytext} {trainsword} в прямом эфире
        </div>
        {ExercisesDays[weekday].content.map((exercise_content, index) => {
          let exercise_link =
            exercise_content.vid === 'green'
              ? 'https://www.instagram.com/exponenta.by/'
              : exercise_content.vid === 'red'
              ? 'https://www.instagram.com/tanya_yourfit/'
              : 'https://www.instagram.com/tanya_yourfit_online/';
          return (
            <>
              <a
                key={index}
                href={exercise_link}
                rel="noreferrer"
                target="_blank"
                className="recipe__image trains__image"
                style={{
                  backgroundImage: `url(${
                    images[
                      `${
                        exercise_content.image
                          ? exercise_content.image
                          : 'train.png'
                      }`
                    ].default
                  })`,
                }}
              >
                <span className="wish"></span>
              </a>
              <div className="recipe__text trains__text">
                <div className="not__rating">
                  <span className="label label__time">
                    {exercise_content.time}
                  </span>
                  <img
                    width="12"
                    src={
                      images[
                        `${
                          exercise_content.star1 === '1'
                            ? 'star2.png'
                            : exercise_content.star1 === '2'
                            ? 'star_half2.png'
                            : 'star_active2.png'
                        }`
                      ].default
                    }
                    alt="star"
                  />
                  <img
                    width="12"
                    src={
                      images[
                        `${
                          exercise_content.star2 === '1'
                            ? 'star2.png'
                            : exercise_content.star2 === '2'
                            ? 'star_half2.png'
                            : 'star_active2.png'
                        }`
                      ].default
                    }
                    alt="star"
                  />
                  <img
                    width="12"
                    src={
                      images[
                        `${
                          exercise_content.star3 === '1'
                            ? 'star2.png'
                            : exercise_content.star3 === '2'
                            ? 'star_half2.png'
                            : 'star_active2.png'
                        }`
                      ].default
                    }
                    alt="star"
                  />
                  <span>Интенсивность</span>
                </div>
                <div className="recipe__title">
                  <a href={exercise_link} rel="noreferrer" target="_blank">
                    {exercise_content.title}
                  </a>
                </div>
                <div className="not__video">
                  <a href={exercise_link} rel="noreferrer" target="_blank">
                    <svg
                      width="39"
                      height="39"
                      viewBox="0 0 39 39"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect
                        width="39"
                        height="39"
                        rx="19.5"
                        fill="url(#paint0_linear)"
                      />
                      <path
                        d="M16.4849 12.1041C16.6047 12.0347 16.7388 12 16.8731 12C17.007 12 17.141 12.0347 17.2613 12.1041L26.5151 18.8278C26.7549 18.9663 26.9034 19.2228 26.9034 19.5001C26.9034 19.7774 26.7553 20.0338 26.5151 20.1722L17.2613 26.8962C17.0212 27.0346 16.7251 27.0346 16.4851 26.8962C16.2449 26.7574 16.0967 26.5008 16.0967 26.2237V12.7763C16.0967 12.4992 16.2447 12.2427 16.4849 12.1041Z"
                        fill="white"
                      />
                      <defs>
                        <linearGradient
                          id="paint0_linear"
                          x1="0.862832"
                          y1="10.2375"
                          x2="40.0088"
                          y2="11.6832"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop offset="0" stopColor="#F8764C" />
                          <stop offset="1" stopColor="#F55555" />
                        </linearGradient>
                      </defs>
                    </svg>
                  </a>
                  <span className="not__clock">
                    {exercise_content.duration}
                  </span>
                  <a
                    className="instagram"
                    href={exercise_link}
                    rel="noreferrer"
                    target="_blank"
                  >
                    Трансляция в instagram
                  </a>
                </div>
              </div>
            </>
          );
        })}
        <div className="text-center trains__watch">
          <div className="title">Просмотр тренировок</div>
          <p>
            Нажмите на тренировку, чтобы открыть аккаунт, где она будет
            проходить
          </p>
          <img src={images['trains.svg'].default} alt="trains" />
        </div>
        <div className="title">Расписание тренировок по дням</div>

        {ExercisesDays.map((day, index) => {
          return (
            <>
              <div key={day.id} className="day">
                <span>{day.title}</span>
              </div>
              <div className="row train__row">
                {day.content.map((c, i) => (
                  <div className="train__col" key={c.id}>
                    <a
                      className={
                        c.vid
                          ? `train__block train__block--${c.vid}`
                          : 'train__block'
                      }
                      href={
                        c.vid === 'green'
                          ? 'https://www.instagram.com/exponenta.by/'
                          : c.vid === 'red'
                          ? 'https://www.instagram.com/tanya_yourfit/'
                          : 'https://www.instagram.com/tanya_yourfit_online/'
                      }
                      rel="noreferrer"
                      target="_blank"
                    >
                      <span
                        className="train__img"
                        style={{
                          backgroundImage:
                            c.vid === 'green'
                              ? `url(${images['day3.svg'].default})`
                              : c.vid === 'red'
                              ? `url(${images['day2.svg'].default})`
                              : `url(${images['day1.svg'].default})`,
                        }}
                      >
                        <svg
                          width="39"
                          height="39"
                          viewBox="0 0 39 39"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <rect
                            width="39"
                            height="39"
                            rx="19.5"
                            fill="white"
                          ></rect>
                          <path
                            d="M16.4849 12.1041C16.6047 12.0347 16.7388 12 16.8731 12C17.007 12 17.141 12.0347 17.2613 12.1041L26.5151 18.8278C26.7549 18.9663 26.9034 19.2228 26.9034 19.5001C26.9034 19.7774 26.7553 20.0338 26.5151 20.1722L17.2613 26.8962C17.0212 27.0346 16.7251 27.0346 16.4851 26.8962C16.2449 26.7574 16.0967 26.5008 16.0967 26.2237V12.7763C16.0967 12.4992 16.2447 12.2427 16.4849 12.1041Z"
                            fill="#F55555"
                          ></path>
                        </svg>
                      </span>
                      <span className="not__rating">
                        <img
                          width="12"
                          src={
                            images[
                              `${
                                c.star1 === '1'
                                  ? 'star2.png'
                                  : c.star1 === '2'
                                  ? 'star_half2.png'
                                  : 'star_active2.png'
                              }`
                            ].default
                          }
                          alt="star"
                        />
                        <img
                          width="12"
                          src={
                            images[
                              `${
                                c.star2 === '1'
                                  ? 'star2.png'
                                  : c.star2 === '2'
                                  ? 'star_half2.png'
                                  : 'star_active2.png'
                              }`
                            ].default
                          }
                          alt="star"
                        />
                        <img
                          width="12"
                          src={
                            images[
                              `${
                                c.star3 === '1'
                                  ? 'star2.png'
                                  : c.star3 === '2'
                                  ? 'star_half2.png'
                                  : 'star_active2.png'
                              }`
                            ].default
                          }
                          alt="star"
                        />
                        <span>Интенсивность</span>
                      </span>
                      <span className="train__title">{c.title}</span>
                      <span className="label">{c.time}</span>{' '}
                      <span className="not__clock">{c.duration}</span>
                    </a>
                  </div>
                ))}
              </div>
            </>
          );
        })}

        <div className="note">
          Нажимайте на кнопку ниже и я расскажу вам как выбрать тренировку
          <a
            rel="noreferrer"
            href={HOW_TO_CHOOSE_EXERCISE}
            target="_blank"
            className="white_btn"
          >
            как выбрать тренировку?
          </a>
          <img
            className="note__img"
            src={images['sticker3.png'].default}
            alt="sticker"
          />
        </div>
        <div className="title">
          Ссылки на просмотр и скачивание тренировок из прямых эфиров
        </div>
        {hasAccessRole(this.props.userStore, Role.EXCERCISES) ? (
          <div className="row--sm">
            <div className="col-6--sm">
              <a
                className="train__downloadlink"
                href={GOOGLE_ARCHIVE_LINK}
                target="_blank"
                rel="noreferrer"
              >
                <span className="train__downloadlinkimg">
                  <img
                    width="18"
                    src={images['download3.png'].default}
                    alt="download"
                  />
                </span>
                Скачать с Google Диск
              </a>
            </div>
            <div className="col-6--sm">
              <a
                className="train__downloadlink"
                href={YANDEX_ARCHIVE_LINK}
                target="_blank"
                rel="noreferrer"
              >
                <span className="train__downloadlinkimg">
                  <img
                    width="18"
                    src={images['download4.png'].default}
                    alt="download"
                  />
                </span>
                Скачать с Яндекс.Диск
              </a>
            </div>
          </div>
        ) : (
          <div className="row--sm">
            <div className="col-6--sm">
              <a
                className="train__downloadlink"
                onClick={() => roleErrorPopup(this.props.uiStore)}
              >
                <span className="train__downloadlinkimg">
                  <img
                    width="18"
                    src={images['download3.png'].default}
                    alt="download"
                  />
                </span>
                Скачать с Google Диск
              </a>
            </div>
            <div className="col-6--sm">
              <a
                className="train__downloadlink"
                onClick={() => roleErrorPopup(this.props.uiStore)}
              >
                <span className="train__downloadlinkimg">
                  <img
                    width="18"
                    src={images['download4.png'].default}
                    alt="download"
                  />
                </span>
                Скачать с Яндекс.Диск
              </a>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default ExercisesWeek;
