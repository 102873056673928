import { ButtonType, SupportButton } from './SupportButton';
import { images } from '../../../components/Utils/componentUtils';
import { observer } from 'mobx-react';
import { sections } from '../data';
import { useStore } from 'utils/hooks';

export const NavigationButtons = observer(() => {
  const { supportStore } = useStore();
  const { currentContent, currentSection } = supportStore;
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        marginBottom: '16px',
      }}
    >
      <SupportButton
        type={ButtonType.WithIcon}
        leftIcon={images['backArrow.svg'].default}
        leftIconProps={{
          width: 18,
          height: 18,
          margin: '0 4px 0 0',
        }}
        contentAlign={'center'}
        text={currentContent?.isFirstSlide ? 'главное меню' : 'назад'}
        width={134}
        height={37}
        style={{
          background: '#E0E0E0',
          borderRadius: '4px',
          fontSize: '11px',
          color: '#000000',
          textTransform: 'uppercase',
        }}
        onclick={() => supportStore.goBack()}
      />
      <SupportButton
        type={ButtonType.WithIcon}
        leftIcon={
          currentContent?.isLastSlide &&
          (sections?.[currentSection.nextIndex]?.icon ?? sections[0].icon)
        }
        leftIconProps={
          sections[currentSection.nextIndex]?.navigationIconProps ??
          sections[0].navigationIconProps
        }
        rightIcon={images['forwardArrow.svg'].default}
        rightIconProps={{
          width: 18,
          height: 18,
          margin: '0 0 0 4px',
        }}
        contentAlign={'center'}
        text={
          currentContent?.isLastSlide
            ? sections[currentSection.nextIndex]?.text ?? sections[0].text
            : 'далее'
        }
        width={134}
        height={37}
        style={{
          borderRadius: '4px',
          fontSize: '11px',
          textTransform: 'uppercase',
        }}
        onclick={() => supportStore.goForward()}
      />
    </div>
  );
});
