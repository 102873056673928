import React, { CSSProperties } from "react";
import s from "./SupportButton.module.scss";
import { IconProps } from "../../data";

export enum ButtonType {
    Default,
    WithIcon
}

type TypeButton = {
    [ButtonType.Default]: object,
    [ButtonType.WithIcon]: WithImageOptions
}

type BaseButtonOptions = {
    text?: string
    width: CSSProperties["width"]
    height: CSSProperties["height"]
    cursor?: CSSProperties["cursor"]
    style?: CSSProperties
    onclick?: () => void
    contentAlign?: CSSProperties["alignItems"]
    children?: React.ReactChild
}

type WithImageOptions = {
    leftIcon?: string
    rightIcon?: string
    leftIconProps?: IconProps
    rightIconProps?: IconProps
}

type MapToOptions<Type> = {
    [key in keyof Type]: BaseButtonOptions
    & { /** Тип поля */ readonly type: key }
    & Type[key]
}

type MappedOptions = MapToOptions<TypeButton>

type Button = MappedOptions[keyof MappedOptions]

export function SupportButton(props: Button) {
    return (
        <button
            className={s.button}
            onClick={props.onclick}
            style={{
                width: props.width,
                height: props.height,
                cursor: props.cursor ?? "pointer",
                ...props.style
            }}
        >
            <div style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: props.contentAlign
            }}>
                {props.children}
                {props.type === ButtonType.WithIcon && props.leftIcon && <img
                    style={{
                        padding: props.leftIconProps?.padding,
                        margin: props.leftIconProps?.margin,
                        width: props.leftIconProps?.width ?? 50,
                        height: props.leftIconProps?.height ?? 50,
                    }}
                    // width={props.leftIconProps?.width ?? 50}
                    // height={props.leftIconProps?.height ?? 50}
                    src={props.leftIcon}
                    alt={props.leftIconProps?.alt ?? "icon"}
                />}
                {props.text && props.text}
                {props.type === ButtonType.WithIcon && props.rightIcon && <img
                    style={{
                        padding: props.rightIconProps?.padding,
                        margin: props.rightIconProps?.margin,
                    }}
                    width={props.rightIconProps?.width ?? 50}
                    height={props.rightIconProps?.height ?? 50}
                    src={props.rightIcon}
                    alt={props.rightIconProps?.alt ?? "icon"}
                />}
            </div>
        </button>
    );
}