import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { Provider } from 'mobx-react';
import App from './App';
import { IStores } from './types/IStores';
import { AlertDialog } from './components/Utils/alertDialog';
import { MeasurementStore } from './stores/measurementStore';
import Connector from './connector/connector';
import { BrowserRouter } from 'react-router-dom';
import {
  BasketStore,
  FoodStore,
  PlanStore,
  ProgressStore,
  RecipeStore,
  SupportStore,
  UiStore,
  UserStore,
  ValidationFormStore,
  DiseasesStore,
} from 'stores';
import ReactGA from 'react-ga4';

ReactGA.initialize('G-6Z6VXDDCH9');

const stores: IStores = new (class implements IStores {
  basketStore: BasketStore = new BasketStore(this);
  foodStore: FoodStore = new FoodStore(this);
  measurementStore: MeasurementStore = new MeasurementStore(this);
  planStore: PlanStore = new PlanStore(this);
  recipeStore: RecipeStore = new RecipeStore(this);
  uiStore: UiStore = new UiStore(this);
  userStore: UserStore = new UserStore(this);
  progressStore: ProgressStore = new ProgressStore(this);
  validationFormStore: ValidationFormStore = new ValidationFormStore(this);
  supportStore: SupportStore = new SupportStore(this);
  diseasesStore: DiseasesStore = new DiseasesStore(this);
})();

export const connector = new Connector(stores);
export const StoreContext = React.createContext(null);

ReactDOM.render(
  <Provider {...stores}>
    <BrowserRouter>
      <StoreContext.Provider value={stores}>
        <App />
        <AlertDialog />
      </StoreContext.Provider>
    </BrowserRouter>
  </Provider>,
  document.getElementById('root')
);
