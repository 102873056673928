export namespace PlanConstants {
  export const prop = {
    id: 1,
    name: 1,
    type: 2,
    grams: 3,
    image: 4,
    description: 5,
    isOne: 6,
  };

  export const baseCalorage: number = 1600;
  export const baseStep: number = 5;

  export const PROTEIN_BASE: Array<{ id: number; value: number }> = [
    { id: 1, value: 144 },
    { id: 2, value: 94 },
    { id: 3, value: 82 },
    { id: 4, value: 73.3 },
  ];
  export const FAT_BASE: Array<{ id: number; value: number }> = [
    { id: 1, value: 64 },
    { id: 2, value: 62 },
    { id: 3, value: 123 },
    { id: 4, value: 53.5 },
  ];

  export const CARBOHYDRATE_BASE: Array<{ id: number; value: number }> = [
    { id: 1, value: 102 },
    { id: 2, value: 156 },
    { id: 3, value: 42 },
    { id: 4, value: 206.7 },
  ];

  export const GOAL_LOSS: number = 1;
  export const GOAL_RETENTION: number = 2;
  export const GOAL_KETO: number = 3;
  export const GOAL_VEGAN: number = 4;
}
