import { connector } from '../index';

export default class PasswordActions {
  static async passwordRecoveryCheck(email: string, onError, onSuccess) {
    return connector.post(
      '/auth/reset-password/request/',
      { email },
      onError,
      onSuccess
    );
  }

  static async resetPasswordConfirm(data) {
    return connector.post('/auth/reset-password/confirm/', data);
  }
}
