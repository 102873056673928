import { Component } from 'react';
import { Link } from 'react-router-dom';
import { images } from '../Utils/componentUtils';

class Header extends Component<any, any> {
  render() {
    function menuOpen() {
      document.querySelector('.mobilemenu').classList.toggle('active');
      document.querySelector('.mobilemenu__overlay').classList.toggle('active');
      document.querySelector('body').classList.add('bodynotscrolled');
      document.querySelector('html').classList.add('bodynotscrolled');
    }
    function menuClose() {
      document.querySelector('.mobilemenu').classList.remove('active');
      document.querySelector('.mobilemenu__overlay').classList.remove('active');
      document.querySelector('body').classList.remove('bodynotscrolled');
      document.querySelector('html').classList.remove('bodynotscrolled');
    }

    if (window.location.pathname === '/payments') return null;

    return (
      <div>
        <header className="header">
          <div className="container">
            <Link to="/">
              <img width="137" src={images['logo.png'].default} alt="logo" />
            </Link>
            {/* <div className="header__menuopen" onClick={menuOpen}><span/><span/><span/></div>*/}
            {/* <a className="btn header__btn" href="https://4yourfit.by">перейти на сайт</a> */}
          </div>
        </header>
        <div className="mobilemenu">
          <div className="header__menuopened active" onClick={menuClose}>
            <span />
            <span />
            <span />
          </div>
          {/* <ul>
                        <li><a onClick={menuClose} href="/home">План на неделю</a></li>
                        <li><a onClick={menuClose} href="/plan">План питания</a></li>
                        <li><a onClick={menuClose} href="/menu">Готовое меню на неделю</a></li>
                        <li><a onClick={menuClose} href="/exercises">Тренировки</a></li>
                        <li><a onClick={menuClose} href="/profile">Профиль</a></li>
                        <li><a onClick={menuClose} href="/measurement">Внести замеры</a></li>
                        <li><a onClick={menuClose} href="/leaderboard/weight">Лидерборд по замерам</a></li>
                        <li><a onClick={menuClose} href="/leaderboard/steps">Лидерборд по шагам</a></li>
                        <li><a onClick={menuClose} href="/support">Общение, поддержка и обучение</a></li>
                    </ul>
                    <a className="btn" href="/">техническая поддержка</a>*/}
          <img
            className="mobilemenu__img"
            src={images['photo.png'].default}
            alt="photo"
          />
        </div>
        <div className="mobilemenu__overlay" onClick={menuClose} />
      </div>
    );
  }
}

export default Header;
