import { images, ProgressLoader } from '../../components/Utils/componentUtils';
import { Line } from 'react-chartjs-2';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { inject, observer } from 'mobx-react';
import { Role } from '../../data';
import { IProfile } from '../../types/IProfile';
import { IMeasurement } from '../../types/IMeasurement';
import ProtectedComponent from '../../components/Base/protected';
import { UiStore, UserStore } from 'stores';
import { getFullName, hasAccessRole, roleErrorPopup } from 'utils/utils';
import { Icon } from '@material-ui/core';

const options = {
  scales: {
    yAxes: [
      {
        ticks: {
          beginAtZero: true,
        },
      },
    ],
    xAxes: [],
  },
};

@inject('uiStore', 'userStore', 'recipeStore')
@observer
class Profile extends ProtectedComponent {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
      data2: null,
      data3: null,
      error: null,
      weightProgressOpened: false,
      bellyProgressOpened: false,
      buttocksProgressOpened: false,
    };
  }

  async componentDidMount(): Promise<void> {
    await super.componentDidMount();
    this.initProfile();
  }

  toggleOpenChart(param) {
    this.setState({ [param]: !this.state[param] });
  }

  initProfile() {
    const profile: IProfile = this.props.userStore?.profile || {};
    const measurements: IMeasurement[] = (profile.measurements || []).sort(
      (a, b) => moment(a.date).valueOf() - moment(b.date).valueOf()
    );
    //
    const regday = moment(profile?.regDate).format('DD.MM.YY');
    const measuredays =
      measurements.length > 0
        ? measurements.map((v) => moment(v.date).format('DD.MM.YY'))
        : '';
    const weightArray = [parseFloat(profile.startWeight)].concat(
      measurements.map((v) => parseFloat(v.weight))
    );
    const bellyArray = [parseFloat(profile.startBelly)].concat(
      measurements.map((v) => parseFloat(v.belly))
    );
    const buttocksArray = [parseFloat(profile.startButtocks)].concat(
      measurements.map((v) => parseFloat(v.buttocks))
    );
    this.setState({
      data: {
        labels: [regday].concat(measuredays),
        datasets: [
          {
            borderColor: '#F55755',
            fill: false,
            pointRadius: 5,
            pointBackgroundColor: '#F55755',
            label: 'Вес',
            data: weightArray,
          },
        ],
      },
      data2: {
        labels: [regday].concat(measuredays),
        datasets: [
          {
            borderColor: '#F55755',
            fill: false,
            pointRadius: 5,
            pointBackgroundColor: '#F55755',
            label: 'Живот',
            data: bellyArray,
          },
        ],
      },
      data3: {
        labels: [regday].concat(measuredays),
        datasets: [
          {
            borderColor: '#F55755',
            fill: false,
            pointRadius: 5,
            pointBackgroundColor: '#F55755',
            label: 'Ягодицы',
            data: buttocksArray,
          },
        ],
      },
    });
  }

  getWeightProgress(user: IProfile) {
    return Math.min(
      (100 * user.targetWeight) /
        user?.measurements[user?.measurements.length - 1]?.weight,
      100
    );
  }

  unsubscribe = () => {
    this.props.uiStore.showDialog(
      `Нажимая кнопку “Отписаться” вы соглашаетесь с тем, что ваша подписка перестанет действовать после ${moment(
        this.props.userStore?.profile.subscriptions[0].expiredAt
      ).format('DD.MM.YY')}`,
      'Вы уверены, что хотите отписаться?',
      () => {},
      () => {
        this.props.userStore.unsubscribe((response) => {
          if (response.status === 200) {
            this.props.uiStore.showDialog(
              response.data.message,
              'Успешно!',
              () => {},
              () => {},
              'popup--style popup--blue',
              'Хорошо'
            );
          }
        });
      },
      'popup--style popup--red',
      'Отписаться'
    );
  };

  render() {
    const uiStore: UiStore = this.props.uiStore;
    const userStore: UserStore = this.props.userStore;
    const profile: IProfile = userStore.profile;

    if (!profile) {
      return <ProgressLoader open={true} />;
    }
    return (
      <div className="container">
        <div className="profil__container">
          <div className="profil__block profil__block--top">
            {/* <Avatar src={this.props.userStore.profile.avatar} /> */}
            <div className="title">{getFullName(profile)}</div>
            <Link to="/profile/info" className="btn">
              Личная карта пользователя
              <img width="23" src={images['pencil.png'].default} alt="pencil" />
            </Link>
            {profile?.subscriptions?.length ? (
              <div className="subscription">
                <div className="bird_wrapper">
                  <svg
                    className="bird_checkMark"
                    width="10"
                    height="9"
                    viewBox="0 0 10 9"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M3.69753 8.3349C3.36962 8.3349 3.04174 8.19436 2.85437 7.91332L0.231229 4.54069C-0.143515 4.07227 -0.0498128 3.41649 0.418601 3.04174C0.887014 2.667 1.5428 2.7607 1.91754 3.22912L3.69753 5.52438L7.63227 0.418601C8.00702 -0.0498127 8.6628 -0.143515 9.13121 0.231229C9.59963 0.605973 9.69333 1.26176 9.31859 1.73017L4.54068 7.91332C4.30648 8.14753 4.02544 8.3349 3.69753 8.3349Z"
                      fill="white"
                    />
                  </svg>
                </div>
                <pre>Подписка активна до: </pre>
                <span className="subscription__date">
                  {moment(profile.subscriptions[0].expiredAt).format(
                    'DD.MM.YY'
                  )}
                </span>
              </div>
            ) : null}
          </div>
          <div className="title">Динамика результатов</div>
          <div className="dynamics__block">
            <img
              className="dynamics__img"
              width="80"
              src={images['dynamics.png'].default}
              alt="Динамика результатов"
            />
            <div className="dynamics__title">Таблица замеров</div>
            <Link to="/measurement" className="btn">
              просмотр
            </Link>

            {/* ALERT Заглушка инверсией, так как нет ролей */}
            {!hasAccessRole(userStore, Role.MEASUREMENTS) ? (
              <Link to="/measurement/submit" className="btn">
                <span>внести замеры</span>{' '}
                <img width="12" src={images['diet.png'].default} alt="diet" />
              </Link>
            ) : (
              <a
                className="btn btn--disabled"
                onClick={() => roleErrorPopup(uiStore)}
              >
                <span>внести замеры</span>{' '}
                <img width="12" src={images['diet.png'].default} alt="diet" />
              </a>
            )}
          </div>
          <div className="title">
            Вес{' '}
            <img width="22" src={images['weight.png'].default} alt="weight" />
          </div>
          <div className="profil__block">
            <div className="profil__paramsline">
              <div className="profil__params">
                <p>
                  {moment(profile?.startMeasurements.date).format('DD.MM.YYYY')}
                </p>
                <div>{profile.startMeasurements?.weight} кг</div>
              </div>
              <div className="profil__params">
                <p>текущий</p>
                <div>
                  {
                    profile?.measurements[profile.measurements.length - 1]
                      ?.weight
                  }{' '}
                  кг
                </div>
              </div>
              <div className="profil__params">
                <p>целевой</p>
                <div>{profile?.targetWeight}</div>
              </div>
            </div>
            <div className="profil__line">
              <div style={{ width: this.getWeightProgress(profile) + '%' }} />
            </div>
            <div
              className={`openblock ${
                this.state.weightProgressOpened ? 'opened' : ''
              }`}
            >
              <span
                onClick={() => this.toggleOpenChart('weightProgressOpened')}
              >
                Посмотреть развернутую динамику
              </span>
            </div>
            <div
              className={`hiddenblock ${
                this.state.weightProgressOpened ? 'opened' : ''
              }`}
            >
              <Line type="line" data={this.state.data} options={options} />
            </div>
          </div>
          <div className="title">
            Живот <img width="24" src={images['slim.png'].default} alt="slim" />
          </div>
          <div className="profil__block">
            <div className="profil__paramsline">
              <div className="profil__params">
                <p>
                  {moment(profile?.startMeasurements.date).format('DD.MM.YYYY')}
                </p>
                <div>{profile?.startMeasurements.belly} см</div>
              </div>
              <div className="profil__params">
                <p>текущий</p>
                <div>
                  {
                    profile?.measurements[profile.measurements.length - 1]
                      ?.belly
                  }{' '}
                  см
                </div>
              </div>
              <div className="profil__params">
                <p>Динамика</p>
                <div>
                  {profile?.measurements[profile.measurements.length - 1]
                    ?.belly - profile?.startMeasurements.belly || 0}{' '}
                  см
                </div>
              </div>
            </div>
            <div
              className={`openblock ${
                this.state.bellyProgressOpened ? 'opened' : ''
              }`}
            >
              <span onClick={() => this.toggleOpenChart('bellyProgressOpened')}>
                Посмотреть развернутую динамику
              </span>
            </div>
            <div
              className={`hiddenblock ${
                this.state.bellyProgressOpened ? 'opened' : ''
              }`}
            >
              <Line type="line" data={this.state.data2} options={options} />
            </div>
          </div>
          <div className="title">
            Ягодицы{' '}
            <img width="22" src={images['slim2.png'].default} alt="slim" />
          </div>
          <div className="profil__block">
            <div className="profil__paramsline">
              <div className="profil__params">
                <p>
                  {moment(profile?.startMeasurements.date).format('DD.MM.YYYY')}
                </p>
                <div>{profile?.startMeasurements.buttocks} см</div>
              </div>
              <div className="profil__params">
                <p>текущий</p>
                <div>
                  {
                    profile?.measurements[profile.measurements.length - 1]
                      ?.buttocks
                  }{' '}
                  см
                </div>
              </div>
              <div className="profil__params">
                <p>Динамика</p>
                <div>
                  {profile?.measurements[profile.measurements.length - 1]
                    ?.buttocks - profile?.startMeasurements.buttocks || 0}{' '}
                  см
                </div>
              </div>
            </div>
            <div
              className={`openblock ${
                this.state.buttocksProgressOpened ? 'opened' : ''
              }`}
            >
              <span
                onClick={() => this.toggleOpenChart('buttocksProgressOpened')}
              >
                Посмотреть развернутую динамику
              </span>
            </div>
            <div
              className={`hiddenblock ${
                this.state.buttocksProgressOpened ? 'opened' : ''
              }`}
            >
              <Line type="line" data={this.state.data3} options={options} />
            </div>
          </div>

          {profile?.subscriptions[0].isAutoRenewalEnabled && (
            <div className="text-center">
              <button
                onClick={this.unsubscribe}
                className="btn unsubscribe-btn"
              >
                Отменить автоматическое продление подписки
              </button>
            </div>
          )}

          <div className="text-center">
            <Link
              to={'/login'}
              onClick={() => {
                userStore.logOut();
                window.location.href = '/login';
              }}
              className="btn"
            >
              выйти из кабинета <Icon>logout</Icon>
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

export default Profile;
