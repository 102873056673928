import { Component, createRef } from 'react';
import { FixedSizeList } from 'react-window';
import { inject, observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import { MealTime } from '../../../../data';
import { IRecipe } from '../../../../types/IRecipe';
import { images } from '../../../../components/Utils/componentUtils';
import { BasketLabel } from './basketLabel';
import { WishLabel } from './wishLabel';
import { RecipeStore } from 'stores';
import { SessionStorageUtil } from 'components/Utils/sessionStorageUtil';

const scrollRef = createRef<FixedSizeList>();

@inject('basketStore', 'recipeStore')
@observer
export class MenuCategoryList extends Component<any, any> {
  private mounted: boolean;

  componentDidMount() {
    const scrollOffset: number = SessionStorageUtil.getInt(
      SessionStorageUtil.RECIPES_SCROLL_POSITION
    );

    this.mounted = true;

    if (scrollOffset === 0) {
      return;
    }

    scrollRef.current?.scrollTo(scrollOffset);
  }

  render() {
    const recipeStore: RecipeStore = this.props.recipeStore;

    const recipes = recipeStore.recipes?.filter((recipe) => {
      return recipe.mealTime === recipeStore.selectedCategory;
    });

    return (
      <>
        {recipes?.length > 0 && (
          <div className="title__find">Найдено {recipes?.length} рецептов</div>
        )}

        {recipes?.length > 0 && (
          <FixedSizeList
            ref={scrollRef}
            height={recipes?.length > 0 ? 500 : 0}
            itemSize={270}
            itemCount={recipes.length}
            itemData={recipes}
            onScroll={({ scrollOffset }) => {
              if (this.mounted) {
                SessionStorageUtil.setInt(
                  SessionStorageUtil.RECIPES_SCROLL_POSITION,
                  scrollOffset
                );
              }
            }}
          >
            {(props) => {
              const recipeIndex = props.index;
              const style = props.style;
              const recipe: IRecipe = recipes[recipeIndex];

              return (
                <div
                  style={style}
                  className="item"
                  key={'categoryRecipe' + recipeIndex}
                >
                  <div className="recipe__item">
                    <BasketLabel recipe={recipe} />
                    <WishLabel recipe={recipe} />
                    <Link
                      to={'/recipe/' + recipe.id}
                      className="recipe__image"
                      style={{
                        backgroundImage: `url(${recipe.image})`,
                      }}
                    >
                      {' '}
                    </Link>
                    <Link to={'/recipe/' + recipe.id} className="recipe__text">
                      <span className="recipe__desc">
                        {MealTime[recipe.mealTime]}
                        {recipe.timeToCook ? '|' + recipe.timeToCook : null}
                      </span>
                      <span className="recipe__title">{recipe.title}</span>
                      <span className="recipe__links">
                        {recipe.components.map((food, index) => {
                          return index < 3 ? (
                            <span
                              className={recipeStore.foodClassNames[index % 3]}
                              key={'category' + recipeIndex + food.title}
                            >
                              {food.title}
                            </span>
                          ) : null;
                        })}
                        {!recipe?.expanded && recipe.components.length > 3 ? (
                          <span
                            style={{ cursor: 'pointer' }}
                            className={recipeStore.foodClassNames[0]}
                            key={'daily' + recipeIndex + 'more'}
                          >
                            ...
                          </span>
                        ) : null}
                        {recipe.components.map((food, index) => {
                          return recipe?.expanded && index >= 3 ? (
                            <span
                              className={recipeStore.foodClassNames[index % 3]}
                              key={'daily' + recipeIndex + food.title}
                            >
                              {food.title}
                            </span>
                          ) : null;
                        })}
                      </span>
                    </Link>
                  </div>
                </div>
              );
            }}
          </FixedSizeList>
        )}

        {recipeStore?.searchItems.length && !recipes?.length ? (
          <div style={{ height: 270 }}>
            <div className="notfound notfound--search">
              <img src={images['notfound.png'].default} alt="Нет рецептов" />
              <div className="notfound__title">
                Нет рецептов, соответствующих условиям поиска
              </div>
              <p>Пожалуйста, измените, условия поиска</p>
            </div>
          </div>
        ) : null}
      </>
    );
  }
}
