export interface ErrorDescription {
  title?: string;
  message: string;
  shouldReload?: boolean;
}

export const customError = (reason: any): ErrorDescription => {
  const errorsResponse = reason.response?.data;

  for (const key in errorsResponse) {
    if (Object.prototype.hasOwnProperty.call(errorsResponse, key)) {
      return {
        title: 'Ошибка!',
        message: errorsResponse[key],
        shouldReload: false,
      };
    }
  }
};
