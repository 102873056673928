import { Component } from 'react';
import { FixedSizeList } from 'react-window';
import { Link } from 'react-router-dom';
import { MealTime } from '../../../../data';
import { inject, observer } from 'mobx-react';
import { AutoSizer } from 'react-virtualized';
import { WishLabel } from './wishLabel';
import { BasketLabel } from './basketLabel';
import { RecipeStore } from 'stores';

@inject('recipeStore', 'basketStore')
@observer
export class MenuDailySlider extends Component<any, any> {
  slider: any;

  protected sliderDataType: string = 'daily';

  render() {
    const recipeStore: RecipeStore = this.props.recipeStore;
    const recipes = this.props.recipeStore.dailyRecipes || [];

    if (!recipes.length) {
      return (
        <>
          <div className="notfound notfound__title--daily">
            <div className="notfound__title">Нет рецептов на день</div>
          </div>
        </>
      );
    }

    return (
      <div style={{ height: '300px' }}>
        <AutoSizer>
          {({ width, height }) => (
            <FixedSizeList
              className="menuSlider"
              layout="horizontal"
              height={height}
              width={width}
              itemSize={310}
              itemCount={recipes.length}
              itemData={recipes}
            >
              {(props) => {
                const { data, index, style } = props;

                const recipe = data[index];
                return (
                  <div className="item" key={'dayRecipe' + index} style={style}>
                    <div className="recipe__item">
                      <BasketLabel recipe={recipe} />
                      <WishLabel recipe={recipe} />
                      <Link
                        to={'/recipe/' + recipe.id}
                        className="recipe__image"
                        style={{
                          backgroundImage: `url(${recipe.image})`,
                        }}
                      >
                        {' '}
                      </Link>
                      <Link
                        to={'/recipe/' + recipe.id}
                        className="recipe__text"
                      >
                        <span className="recipe__desc">
                          {MealTime[recipe.mealTime]}
                          {recipe.timeToCook ? '|' + recipe.timeToCook : null}
                        </span>
                        <span className="recipe__title">{recipe.title}</span>
                        <span className="recipe__links">
                          {recipe.components.map((food, foodIndex) => {
                            return foodIndex < 3 ? (
                              <span
                                className={
                                  recipeStore.foodClassNames[foodIndex % 3]
                                }
                                key={this.sliderDataType + index + food.title}
                              >
                                {food.title}
                              </span>
                            ) : null;
                          })}
                          {!recipe.expanded && recipe.components.length > 3 ? (
                            <span
                              style={{ cursor: 'pointer' }}
                              className={recipeStore.foodClassNames[0]}
                              key={this.sliderDataType + index + 'more'}
                            >
                              ...
                            </span>
                          ) : null}
                          {recipe.components.map((food, foodIndex) => {
                            return recipe.expanded && foodIndex >= 3 ? (
                              <span
                                className={
                                  recipeStore.foodClassNames[foodIndex % 3]
                                }
                                key={this.sliderDataType + index + food.title}
                              >
                                {food.title}
                              </span>
                            ) : null;
                          })}
                        </span>
                      </Link>
                    </div>
                  </div>
                );
              }}
            </FixedSizeList>
          )}
        </AutoSizer>
      </div>
    );
  }
}
