import React, { Component } from 'react';
import { BaseDialog, ComponentDialog } from './componentUtils';
import Dialog from '@material-ui/core/Dialog';
import { inject, observer } from 'mobx-react';
import { UiStore } from 'stores';

export interface IAlertDialogProps {
  uiStore?: UiStore;
}
@inject('uiStore')
@observer
export class AlertDialog extends Component<any, any> {
  public props: IAlertDialogProps;

  private handleClose() {
    const uiStore: UiStore = this.props.uiStore;
    if (uiStore.dialog && uiStore.dialog.onClose) {
      uiStore.dialog.onClose();
    }
    uiStore.clearDialog();
  }

  private handleAccept() {
    const uiStore: UiStore = this.props.uiStore;
    uiStore.dialog.onAccept();
    uiStore.clearDialog();
  }

  render() {
    const uiStore: UiStore = this.props.uiStore;
    const dialog = uiStore.dialog;
    if (dialog) {
      const title: string = uiStore.dialog ? uiStore.dialog.title : '';
      const message: string | React.ReactNode = uiStore.dialog
        ? uiStore.dialog.message
        : '';
      const isOpen: boolean = uiStore.dialog ? uiStore.dialog.isOpen : false;
      const classes: string = uiStore.dialog ? uiStore.dialog.classes : '';
      const bottom: string | React.ReactNode = uiStore.dialog
        ? uiStore.dialog.bottom
        : '';
      return React.isValidElement(message) ? (
        <ComponentDialog
          children={message}
          title={title}
          open={isOpen}
          handleClose={this.handleClose.bind(this)}
        />
      ) : (
        <BaseDialog
          message={message}
          title={title}
          isOpen={isOpen}
          handleClose={this.handleClose.bind(this)}
          handleAccept={this.handleAccept.bind(this)}
          classes={classes}
          bottom={bottom}
        />
      );
    } else {
      return (
        <div>
          <Dialog open={false} />
        </div>
      );
    }
  }
}
