import { connector } from '../index';

export default class RecipeActions {
  static async getFavorite() {
    return connector.get('/foods/recipes/?favorites=true');
  }

  static async addToFavorite(recipeId: number, onError, onSuccess) {
    return connector.post(
      '/foods/recipes/favorite/',
      { recipe: recipeId },
      onError,
      onSuccess
    );
  }

  static async removeFromFavorite(recipeId, onError, onSuccess) {
    return connector.delete(
      `/foods/recipes/favorite/${recipeId}/`,
      {},
      onError,
      onSuccess
    );
  }

  static async getAll(query?: string) {
    return connector.get('/foods/recipes/', {
      q: query ? query : null,
    });
  }

  static async getDailyRecipes() {
    return connector.get('/foods/recipes/daily/');
  }

  static async getRecipe(id: number) {
    return connector.get(`/foods/recipes/${id}/`);
  }

  static async getSimilarRecipes(id: string) {
    return connector.get(`/foods/recipes/similar/${id}/`);
  }

  //////
  static async getAllFood() {
    return connector.get('/food');
  }
}
