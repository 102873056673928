import { Component } from 'react';
import { inject, observer } from 'mobx-react';

@inject('basketStore')
@observer
export class BasketLabel extends Component<any, any> {
  switch() {
    const inBasket = this.props.basketStore.basketRecipes?.some(
      (item) => item.id === this.props.recipe.id
    );
    inBasket
      ? this.props.basketStore.deleteRecipe(this.props.recipe)
      : this.props.basketStore.addRecipe(this.props.recipe);
  }

  render() {
    const inBasket = this.props.basketStore.basketRecipes?.some(
      (item) => item.id === this.props.recipe.id
    );

    return (
      <span
        className={inBasket ? 'tobasket active' : 'tobasket'}
        onClick={this.switch.bind(this)}
      />
    );
  }
}
