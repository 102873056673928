import { inject, observer } from 'mobx-react';
import { ComponentProgress } from '../../components/Utils/componentUtils';
import BaseComponent from '../../components/Base/base';
import Plan from '../Plan';

@inject('userStore', 'uiStore')
@observer
class Migrate extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      progress: false,
    };

    props.uiStore.loadingOff();
  }

  componentDidMount(): Promise<void> {
    return this.getEmail();
  }

  async getEmail() {
    await this.props.userStore.getEmail((response) => {
      this.setState({ email: response.data.email });
    });
  }

  async handleSignIn(e) {
    e.preventDefault();
    this.props.userStore.logOut();
    this.setState({ progress: true });

    await this.props.userStore.migrate(
      this.state.email,
      async (response) => {
        this.setState({ progress: false });

        const { status } = response;

        if (status === 200) {
          this.props.uiStore.showDialog(
            `На почту ${this.state.email} отправлено письмо с дальнейшей инструкцией по активации учетной записи.`,
            'Поздравляем!',
            () => {
              this.props.history.push('/login');
            },
            () => {
              this.props.history.push('/login');
            },
            'popup--style popup--blue'
          );
        }
      },
      () => {
        this.setState({
          progress: false,
        });
        this.props.uiStore.showDialog(
          '',
          '',
          () => {},
          'popup--style popup--red'
        );
      }
    );
  }

  handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ email: event.target.value });
  };

  render() {
    return this.props.userStore.hasToken() ? (
      <Plan />
    ) : (
      <div className="text-center userform__wrapper">
        <div className="userform">
          <h1 className="title">Введите свой e-mail</h1>
          <div className="profil">
            <form onSubmit={this.handleSignIn.bind(this)}>
              <input
                type="email"
                name="email"
                value={this.state.email}
                onChange={this.handleEmailChange}
                placeholder="Email"
              />

              {!this.state.progress ? (
                <input
                  className="btn"
                  type="submit"
                  name="submit"
                  value="Отправить"
                  onClick={this.handleSignIn.bind(this)}
                />
              ) : (
                <ComponentProgress />
              )}
            </form>
          </div>
        </div>
      </div>
    );
  }
}

export default Migrate;
