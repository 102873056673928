import { Component } from 'react';
import {
  ProgressLoader,
  images,
} from '../../../../components/Utils/componentUtils';
import { inject, observer } from 'mobx-react';
import ModalVideo from 'react-modal-video';
import 'react-modal-video/css/modal-video.min.css';
import { PlanConstants } from '../../constants';
import { Toggle } from '../../../../components/Toggle/Toggle';
import { UserStore } from 'stores';

@inject('uiStore', 'basketStore', 'recipeStore', 'userStore', 'uiStore')
@observer
export class PlanHeader extends Component<any, any> {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
    };
    this.openModal = this.openModal.bind(this);
  }

  openModal() {
    this.setState({ isOpen: true });
  }

  private getRoundGrams(planId: number, baseAmount: number): number {
    return Math.round((planId * baseAmount) / PlanConstants.baseCalorage);
  }

  private renderNutrientInfo(nutrientType: string, nutrientBase: any) {
    const userStore: UserStore = this.props.userStore;
    const { calories } = userStore.userCaloriesPlan || {};
    const nutrientValue = nutrientBase.find(
      (item) => item.id === userStore.profile?.mealPlan
    )?.value;

    const grams =
      userStore.profile?.mealPlan && !isNaN(calories) && !isNaN(nutrientValue)
        ? `${this.getRoundGrams(calories, nutrientValue)} г`
        : '-';

    return (
      <td key={nutrientType}>
        <div className="recipe__char">
          {nutrientType === 'protein' && 'Белки'}
          {nutrientType === 'fat' && 'Жиры'}
          {nutrientType === 'carbohydrate' && 'Углеводы'}
        </div>
        {grams}
      </td>
    );
  }

  render() {
    const userStore: UserStore = this.props.userStore;
    const { token, userMealPlan, userCaloriesPlan, profile } = userStore;

    if (
      !token ||
      this.props.uiStore.loading ||
      !userMealPlan ||
      !userCaloriesPlan ||
      !profile
    ) {
      return <ProgressLoader open={true} />;
    }

    return (
      <div>
        <Toggle />
        {userCaloriesPlan.calories && userMealPlan.title ? (
          <div className="plan__norm">
            <div className="plan__normtitle">
              <span>ПЛАН ПИТАНИЯ</span> <span>{userMealPlan?.title}</span>
            </div>
            <div className="title">
              Твоя суточная норма:&nbsp;
              <span className="red">
                {userCaloriesPlan?.calories &&
                  `${userCaloriesPlan?.calories} ккал`}
              </span>
            </div>
            <table>
              <tbody>
                <tr>
                  {this.renderNutrientInfo(
                    'protein',
                    PlanConstants.PROTEIN_BASE
                  )}
                  {this.renderNutrientInfo('fat', PlanConstants.FAT_BASE)}
                  {this.renderNutrientInfo(
                    'carbohydrate',
                    PlanConstants.CARBOHYDRATE_BASE
                  )}
                </tr>
              </tbody>
            </table>
          </div>
        ) : (
          <div style={{ height: '135px' }} />
        )}
        {userCaloriesPlan?.calories <= 1200 ? (
          <div className="infoblock">
            Мы категорически не рекомендуем использовать план питания менее 1200
            ккал. Это может серьезно навредить вашему здоровью.
          </div>
        ) : (
          ''
        )}

        <div>
          <div className="note">
            Нажмите на кнопку, чтобы посмотреть короткое видео “Как пользоваться
            Планом-конструктором?“
            <a onClick={this.openModal} className="white_btn">
              как пользоваться
            </a>
            <img
              className="note__img"
              src={images['sticker3.png'].default}
              alt="sticker"
            />
          </div>
          <ModalVideo
            channel="youtube"
            autoplay
            isOpen={this.state.isOpen}
            videoId="Cm0BBb7NuJ0"
            onClose={() => this.setState({ isOpen: false })}
          />
          <div className="title">Выберите приём пищи</div>
        </div>
      </div>
    );
  }
}
