import { action, makeObservable, observable } from 'mobx';
import { BaseStore } from './baseStore';
import { IStores } from '../types/IStores';
import DiseasesActions from 'connector/diseasesActions';
import { IDiseases } from 'types/IDiseases';

export class DiseasesStore extends BaseStore {
  @observable
  diseases: Array<IDiseases> = [];

  constructor(stores: IStores) {
    super(stores);
    makeObservable(this);
  }

  @action
  async getAllDiseases() {
    await DiseasesActions.getAllDiseases().then((response) => {
      this.diseases = response.data.results;
    });
  }
}
