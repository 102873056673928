import { Component } from 'react';
import { inject, observer } from 'mobx-react';

@inject('recipeStore')
@observer
export class WishLabel extends Component<any, any> {
  async switch() {
    await this.props.recipeStore.switchIsFavorite(this.props.recipe);
  }

  render() {
    return (
      <span
        className={
          this.props.recipeStore.favoriteIds.includes(this.props.recipe.id)
            ? 'wish active'
            : 'wish'
        }
        onClick={this.switch.bind(this)}
      />
    );
  }
}
