import { action, makeObservable, observable, computed } from 'mobx';
import { IStores } from '../types/IStores';
import { IProfile } from '../types/IProfile';
import AuthActions from '../connector/authActions';
import UserActions from '../connector/userActions';
import { CHATS_3, subscriptionsChats } from '../data';
import { AxiosResponse } from 'axios';
import MeasurementActions from 'connector/measurementActions';
import { BaseStore } from 'stores';
import jwt_decode from 'jwt-decode';

export class UserStore extends BaseStore {
  @observable
  roles = null;

  @observable
  mealsPlan: any = [];

  @observable
  caloriesPlan: any = [];

  @observable
  userMealPlan: any = {};

  @observable
  userCaloriesPlan: any = {};

  @observable
  profile: IProfile = null;

  @observable
  token: string = null;

  constructor(stores: IStores) {
    super(stores);
    makeObservable(this);
  }

  @computed
  get hasAvatar() {
    return (
      this.profile &&
      this.profile.avatar &&
      this.profile.avatar.indexOf('avatar') >= 0
    );
  }

  @computed
  get chats() {
    const subscriptions = this.profile?.subscriptions.map((s) => s.title);
    return (
      subscriptionsChats.find((v) => subscriptions?.includes(v.subscription))
        ?.chats || CHATS_3
    );
  }

  @action
  hasToken() {
    return !!(this.token || localStorage.getItem('auth'));
  }

  @action
  async signup(profile) {
    await UserActions.postSignUp(profile, null, async () => {
      await this.login(
        profile.credentials.phone,
        profile.credentials.password,
        () => (window.location.pathname = '/'),
        null
      );
    });
  }

  @action
  async login(
    username: string,
    password: string,
    success: { (): string; (): void },
    error: null
  ) {
    this.stores.uiStore.loadingOn();
    const preparedUserName = username.includes('@')
      ? username
      : username.replace(/\D/g, '');

    await AuthActions.login(
      { username: preparedUserName, password },
      error,
      async (response) => {
        const accessToken = response.data.access;
        const refreshToken = response.data.refresh;

        const decodedToken: any = await jwt_decode(response.data.access);

        if (decodedToken.is_old) {
          localStorage.setItem('auth_migrate', accessToken);

          window.location.href = 'migrate';
        } else {
          this.setToken(accessToken);
          localStorage.setItem('auth', accessToken);
          localStorage.setItem('auth_r', refreshToken);
          success();
        }
      }
    );
  }

  @action
  async migrate(
    email: string,
    success: { (response: AxiosResponse): string; (): void },
    error: null
  ) {
    await AuthActions.migrate(
      { email },
      error,
      async (response: AxiosResponse) => {
        localStorage.removeItem('auth_migrate');

        success(response);
      }
    );
  }

  @action
  async getEmail(success: { (response: AxiosResponse): string; (): void }) {
    await AuthActions.getEmail(async (response: AxiosResponse) => {
      success(response);
    });
  }

  @action
  async registration(
    email: string,
    password: string,
    success: { (response: AxiosResponse): string; (): void },
    error: null
  ) {
    this.stores.uiStore.loadingOn();
    await AuthActions.postRegistration(
      { email, password },
      error,
      async (response: AxiosResponse) => {
        success(response);
      }
    );
  }

  @action
  async verifyEmail(token: string, success: any, error) {
    await AuthActions.verifyEmail({ token }, error, async (response) => {
      const accessToken = response.data.access;
      const refreshToken = response.data.refresh;

      if (accessToken && refreshToken) {
        localStorage.setItem('auth', accessToken);
        localStorage.setItem('auth_r', refreshToken);
      }

      success();
    });
  }

  @action
  async getProfile() {
    this.setToken();

    const user = await UserActions.getProfile();
    const subscriptions = await UserActions.getSubscriptions();
    const measurements = await MeasurementActions.getMeasurements();
    const startMeasurements = await MeasurementActions.getStartMeasurements();

    const paymentsStep = '/payments';
    const profileStep = '/profile/info';
    const measurementStep = '/measurement/submit';

    const hasSubscriptions = subscriptions.data.results.length > 0;
    const hasProfile = user.data.firstName && user.data.lastName;
    const hasMeasurements = measurements.data.results.length > 0;

    let currentSteps = JSON.parse(localStorage.getItem('currentSteps')) || {};

    if (!hasSubscriptions) {
      currentSteps.payments = paymentsStep;
    } else {
      delete currentSteps.payments;
    }

    if (!hasProfile) {
      currentSteps.profile = profileStep;
    } else {
      delete currentSteps.profile;
    }

    if (!hasMeasurements) {
      currentSteps.measurement = measurementStep;
    } else {
      delete currentSteps.measurement;
    }

    const stepsArray = Object.values(currentSteps) as string[];

    if (stepsArray.length > 0 && window.location.pathname !== stepsArray[0]) {
      localStorage.setItem('currentSteps', JSON.stringify(currentSteps));
      window.location.href = stepsArray[0];
    } else {
      localStorage.removeItem('currentSteps');
    }

    this.profile = {
      ...user.data,
      startMeasurements: startMeasurements.data,
      measurements: measurements.data.results,
      subscriptions: subscriptions.data.results,
    };

    this.mealsPlan = (await UserActions.getMealPlans()).data.results;

    this.userMealPlan = this.mealsPlan.find(
      (item) => item.id === this.profile.mealPlan
    );

    this.caloriesPlan = (await UserActions.getCaloriesPlans()).data.results;

    this.userCaloriesPlan = this.caloriesPlan.find(
      (item) => item.id === this.profile.caloriesPlan
    );

    this.stores.planStore.userCalorage = this.userCaloriesPlan?.calories;

    this.stores.uiStore.loadingOff();
  }

  @action
  postAvatar(formData, cb) {
    return UserActions.postAvatar(
      formData,
      () => {
        this.stores.uiStore.showDialog(
          'Не удалось обновить фото',
          'Ошибка',
          () => {},
          undefined,
          'popup--style popup--red'
        );
        cb();
      },
      (response) => {
        this.profile = {
          ...this.profile,
          avatar: response.data.file,
        };
        cb();
      }
    );
  }

  setToken(token?) {
    this.token = token || localStorage.getItem('auth');
  }

  @action
  async unsubscribe(
    success: { (response: AxiosResponse): string; (): void },
    error: null
  ) {
    this.stores.uiStore.loadingOn();
    await UserActions.unsubscribe(error, async (response: AxiosResponse) => {
      success(response);
    });
  }

  @action
  logOut() {
    localStorage.removeItem('auth');
    this.token = null;
    this.roles = null;
    this.profile = null;
  }
}
