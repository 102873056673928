import { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { RecipeStore } from 'stores';

@inject('recipeStore')
@observer
export class MenuSearch extends Component<any, any> {
  render() {
    const recipeStore: RecipeStore = this.props.recipeStore;
    return (
      <div className="menusearch">
        <div className="title">Поиск рецептов</div>
        <form
          style={{ marginBottom: '10px' }}
          onSubmit={(event) => {
            event.preventDefault();
            const str: string = event.target[0].value;

            event.target[0].value = '';

            if (str.length > 0) {
              recipeStore.addToSearchString(str);
            }
          }}
        >
          <input
            id="searchInput"
            type="text"
            placeholder="Введите название рецепта или ингредиента"
            autoComplete="off"
            onChange={(event) => {
              event.preventDefault();
            }}
          />
          <button type="submit">
            <svg
              width="35"
              height="34"
              viewBox="0 0 35 34"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                x="0.00195312"
                width="34"
                height="34"
                rx="6"
                fill="url(#paint0_linear_1621_2913)"
              />
              <path
                d="M17.002 10V24M10.002 17H24.002"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
              />
              <defs>
                <linearGradient
                  id="paint0_linear_1621_2913"
                  x1="0.754166"
                  y1="8.925"
                  x2="34.8814"
                  y2="10.1853"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#F8764C" />
                  <stop offset="1" stopColor="#F55555" />
                </linearGradient>
              </defs>
            </svg>
          </button>
          <span className="search__ingredients">
            {recipeStore.searchItems &&
              recipeStore.searchItems.map((searchString, index) => (
                <span
                  key={index}
                  className="search__ingredient"
                  onClick={(event) => {
                    recipeStore.removeSearchString(searchString.value);
                    event.preventDefault();
                  }}
                >
                  {searchString.value}
                  <svg
                    width="8"
                    height="8"
                    viewBox="0 0 8 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect
                      pointerEvents="none"
                      y="0.727539"
                      width="1.0285"
                      height="10.285"
                      rx="0.51425"
                      transform="rotate(-45 0 0.727539)"
                      fill="#F55555"
                    />
                    <rect
                      pointerEvents="none"
                      x="7.27344"
                      width="1.0285"
                      height="10.285"
                      rx="0.51425"
                      transform="rotate(45 7.27344 0)"
                      fill="#F55555"
                    />
                  </svg>
                </span>
              ))}
          </span>
        </form>
      </div>
    );
  }
}
