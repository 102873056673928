import { images } from '../../../../components/Utils/componentUtils';
import { inject, observer } from 'mobx-react';
import { BasketContent } from './basketContent';
import ProtectedComponent from '../../../../components/Base/protected';
import { Toggle } from '../../../../components/Toggle/Toggle';

@inject('basketStore', 'uiStore', 'userStore', 'recipeStore')
@observer
class Basket extends ProtectedComponent {
  render() {
    const { basketRecipes } = this.props.basketStore;
    return (
      <div className="container">
        <Toggle />
        {basketRecipes?.length ? (
          <BasketContent />
        ) : (
          <div className="notfound notfound--inbasket">
            <div className="notfound__title">
              Нет рецептов в корзине покупок
            </div>
            <p>
              Нажмите на корзину понравившегося рецепта, чтобы добавить в список
              покупок:
            </p>
            <img
              src={images['tobasket.svg'].default}
              alt="Нет рецептов в корзине покупок"
            />
          </div>
        )}
      </div>
    );
  }
}

export default Basket;
