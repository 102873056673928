export namespace RegexpConstants {
  export const floatRegexp: RegExp = /^(?!0\d)(\d*\.?\d+){2,7}$/;
  export const intRegexp: RegExp = /^0$|^[1-9]\d{0,2}$/;
  export const intRegexpBig: RegExp = /^(0|[1-9]\d{0,6})$/;
  export const menstrualRegexp: RegExp = /^(0|[1-9]\d?)$/;
  export const nameRegexp: RegExp =
    /[^0-9\s.,_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:[\]]+/;
  export const dateRegexp: RegExp =
    /^(0[1-9]|[12][0-9]|3[01])\.(0[1-9]|1[0-2])\.\d{4}$/;
  export const emailRegexp: RegExp =
    /[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}/;
  export const phone: RegExp = /^[+][0-9]{3}[0-9]{3}[0-9]{4,6}$/;
}
