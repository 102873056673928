import { Component } from 'react';
import { Link } from 'react-router-dom';
import { images } from '../../../../components/Utils/componentUtils';
import { inject, observer } from 'mobx-react';
import { IBasketFood } from '../../../../types/IBasketFood';
import { IBasketRecipe } from '../../../../types/IBasketRecipe';
import { BasketStore } from 'stores';

@inject('basketStore')
@observer
export class BasketContent extends Component<any, any> {
  onBasketOpen(e) {
    e.target.parentElement.classList.toggle('opened');
    e.target.parentElement.previousSibling.classList.toggle('opened');
  }

  onRecipeIncrease(recipe: IBasketRecipe) {
    const basketStore = this.props.basketStore as BasketStore;
    basketStore.incrementRecipe(recipe);
  }

  onRecipeDecrease(recipe: IBasketRecipe) {
    if (recipe.count && recipe.count > 1) {
      const basketStore = this.props.basketStore as BasketStore;
      basketStore.decrementRecipe(recipe);
    }
  }

  onRecipeDelete(recipe: IBasketRecipe) {
    const basketStore = this.props.basketStore as BasketStore;
    basketStore.deleteRecipe(recipe);
  }

  onFoodAdd(food: IBasketFood) {
    const basketStore = this.props.basketStore as BasketStore;
    basketStore.addFood(food.id);
  }

  onFoodRemove(food: IBasketFood) {
    const basketStore = this.props.basketStore as BasketStore;
    basketStore.removeFood(food.id);
  }

  resetBasket() {
    const basketStore = this.props.basketStore as BasketStore;
    basketStore.resetBasket();
  }

  renderData(data) {
    const foodGroups = [];
    const foods = [];

    data.forEach((item) => {
      item.components.forEach((component) => {
        if (!foodGroups.some((groupItem) => groupItem.id === component.group)) {
          foodGroups.push({ id: component.group, title: component.group });
        }
        foods.push({
          ...component,
          weight: component.weight * (item.count || 1),
        });
      });
    });

    // Объединяем ингридиенты с одинаковым ID и суммируем вес
    foods.forEach((food, index) => {
      const foundIndex = foods.findIndex(
        (f, i) => f.id === food.id && i !== index
      );
      if (foundIndex !== -1) {
        foods[index].weight =
          (foods[index].weight || 0) + (foods[foundIndex].weight || 0);
        foods.splice(foundIndex, 1);
      }
    });

    return { foodGroups, foods };
  }

  render() {
    const basketStore = this.props.basketStore as BasketStore;
    const basketRecipes: IBasketRecipe[] = basketStore.basketRecipes;
    const selectedFoods: IBasketRecipe[] = basketStore.foods;
    const { foodGroups, foods } = this.renderData(basketRecipes);

    return (
      <div>
        <div className="notfound notfound--inbasket">
          <p>
            Нажмите на корзину понравившегося рецепта, чтобы добавить в список
            покупок:
          </p>
          <img
            src={images['tobasket.svg'].default}
            alt="Нет рецептов в корзине покупок"
          />
        </div>
        <div className="title">Выбранные рецепты</div>
        {basketRecipes.slice(0, 2).map((recipe) => (
          <div className="product product--basket" key={recipe.id}>
            <div
              className="product__img"
              style={{
                backgroundImage: `url(${recipe.image})`,
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: '50% 50%',
              }}
            ></div>
            <div className="product__name">
              <a href={`/recipe/${recipe.id}`}>{recipe.title}</a>
            </div>
            <div className="product__counttext">Кол-во порций:</div>
            <img
              className="product__delete"
              width="16"
              src={images['delete.png'].default}
              alt="delete"
              onClick={this.onRecipeDelete.bind(this, recipe)}
            />
            <div className="product__counter">
              <span
                className="product__down"
                onClick={this.onRecipeDecrease.bind(this, recipe)}
              ></span>
              <input
                type="text"
                className="product__quantity"
                name="product__quantity"
                value={recipe.count || 1}
                onChange={(e) => {
                  console.log('test');
                }}
              />
              <span
                className="product__up"
                onClick={this.onRecipeIncrease.bind(this, recipe)}
              ></span>
            </div>
          </div>
        ))}
        {basketRecipes.length > 2 && (
          <>
            <div className="hiddenblock">
              {basketRecipes.slice(2).map((recipe) => (
                <div className="product product--basket" key={recipe.id}>
                  <div
                    className="product__img"
                    style={{
                      backgroundImage: `url(${recipe.image})`,
                      backgroundSize: 'cover',
                      backgroundRepeat: 'no-repeat',
                      backgroundPosition: '50% 50%',
                    }}
                  ></div>
                  <div className="product__name">
                    {' '}
                    <Link to={'/recipe/' + recipe.id}>{recipe.title}</Link>
                  </div>
                  <div className="product__counttext">Кол-во порций:</div>
                  <img
                    className="product__delete"
                    width="16"
                    src={images['delete.png'].default}
                    alt="delete"
                    onClick={this.onRecipeDelete.bind(this, recipe)}
                  />
                  <div className="product__counter">
                    <span
                      className="product__down"
                      onClick={this.onRecipeDecrease.bind(this, recipe)}
                    ></span>
                    <input
                      type="text"
                      className="product__quantity"
                      name="product__quantity"
                      value={recipe.count}
                      onChange={(e) => {
                        console.log('test');
                      }}
                    />
                    <span
                      className="product__up"
                      onClick={this.onRecipeIncrease.bind(this, recipe)}
                    ></span>
                  </div>
                </div>
              ))}
            </div>
            <div className="openblock">
              <span onClick={this.onBasketOpen}>Все рецепты</span>
            </div>
          </>
        )}
        <br />
        <br />
        <div className="title">Список покупок</div>
        {foodGroups.map((group) => {
          return (
            <div className="sostav__wrap" key={group.id}>
              <div className="sostav sostav--basket" key={group.id}>
                {foods.map((food) =>
                  food.group === group.id ? (
                    !selectedFoods?.includes(food.id) ? (
                      <table key={food.id}>
                        <tbody>
                          <tr>
                            <td>
                              <div className="sostav__image">
                                <img src={food.image} alt="Ингридиенты" />
                              </div>
                            </td>
                            <td>{food.title}</td>
                            <td>
                              {food.quantity
                                ? food.quantity + ' шт.'
                                : food.weight
                                ? food.weight + ' г'
                                : 'по вкусу'}
                              <input
                                type="checkbox"
                                value={food.title}
                                onClick={this.onFoodAdd.bind(this, food)}
                                defaultChecked={selectedFoods?.includes(
                                  food.id
                                )}
                              />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    ) : (
                      <table className="sostav__removing" key={food.id}>
                        <tbody>
                          <tr>
                            <td>
                              <div className="sostav__image">
                                <img src={food.image} alt="Ингридиенты" />
                              </div>
                            </td>
                            <td>{food.title}</td>
                            <td>
                              {food.quantity
                                ? food.quantity + ' шт.'
                                : food.weight
                                ? food.weight + ' г'
                                : 'по вкусу'}
                              <input
                                type="checkbox"
                                defaultChecked
                                value={food.title}
                                onClick={this.onFoodRemove.bind(this, food)}
                              />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    )
                  ) : (
                    ''
                  )
                )}
              </div>
              <div className="day">
                <span>{group.title}</span>
              </div>
            </div>
          );
        })}
        <div className="text-center">
          <button onClick={this.resetBasket.bind(this)} className="btn">
            <span>очистить корзину</span>{' '}
            <img width="22" src={images['clear.png'].default} alt="clear" />
          </button>
        </div>
      </div>
    );
  }
}
