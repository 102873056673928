import { hasAccessRole, roleErrorPopup } from 'utils/utils';
import { Role } from '../../../data';
import { useStore } from 'utils/hooks';

type LinkChatProps = {
  title: string;
  info: string;
  imageSrc: string;
  imageWidth?: number;
  chatLink: string;
  imageAlt?: string;
};

export function LinkChat(props: LinkChatProps) {
  const { userStore, uiStore } = useStore();
  return (
    <div className="chatblock">
      <img
        width={props.imageWidth ?? 100}
        src={props.imageSrc}
        alt={props.imageAlt ?? 'image'}
      />
      <div className="chat__title">{props.title}</div>
      <p>{props.info}</p>
      {/* ALERT Заглушка инверсией, так как нет ролей */}
      {!hasAccessRole(userStore, Role.CHATS) ? (
        <a
          href={props.chatLink}
          target="_blank"
          rel="noreferrer"
          className="btn"
        >
          перейти в чат
        </a>
      ) : (
        <a onClick={() => roleErrorPopup(uiStore)} className="btn">
          перейти в чат
        </a>
      )}
    </div>
  );
}
