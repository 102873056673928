import { connector } from '../index';

export default class MeasurementActions {
  static async getStartMeasurements() {
    return connector.get('/measurements/initial_measurement/');
  }

  static async getMeasurements(range?: string) {
    return connector.get('/measurements/', { range });
  }

  static async getMeasurementsDay() {
    return connector.get('/measurements/', { range: 'day' });
  }

  static async getMeasurementsMonth() {
    return connector.get('/measurements/', { range: 'month' });
  }

  static async getMeasurementsYear() {
    return connector.get('/measurements/', { range: 'year' });
  }

  static async postMeasurement(measurement) {
    return connector.post('/measurements/', measurement);
  }

  // ALERT Не нужно???
  static async getMeasurement(id: number) {
    return connector.get('/measurement/' + id);
  }

  // ALERT Не нужно???
  static async deleteMeasurement(id) {
    return connector.delete('/measurement', id);
  }
}
