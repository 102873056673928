export class MeasurementUtils {

    public static parseIntNaNCheck(str: string): number {
        const result: number = parseInt(str);
        return isNaN(result) ? 0 : result;
    }

    public static getIsTodayMeasurementEditDay(): boolean {
        const date = new Date();
        const allowedDays: number[] = [0, 1, 5, 6]; // 0-вс, 1-пн, 2-вт, 3-ср, 4-чт, 5-пт, 6-сб
        const currentDay: number = date.getDay();
        return  allowedDays.indexOf(currentDay) >= 0;
    }

}