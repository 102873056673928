import { images } from '../../../components/Utils/componentUtils';
import { CSSProperties, ReactChild } from 'react';

export enum SectionID {
  Important = 'Important',
  Nutrition = 'Nutrition',
  Training = 'Training',
  Instructions = 'Instructions',
}

export type IconProps = {
  width?: number;
  height?: number;
  padding?: string;
  margin?: string;
  alt?: string;
};

export type Section = {
  id: SectionID;
  icon: string;
  text: string;
  width: CSSProperties['width'];
  nextIndex?: number;
  prevIndex?: number;
  iconProps?: IconProps;
  navigationIconProps?: IconProps;
};

export const sections: Section[] = [
  {
    id: SectionID.Important,
    icon: images['important.svg'].default,
    text: 'Важное',
    width: '100%',
    navigationIconProps: {
      width: 38,
      height: 38,
    },
  },
  {
    id: SectionID.Nutrition,
    icon: images['plateChow.svg'].default,
    text: 'Питание',
    width: '100%',
    navigationIconProps: {
      width: 40,
    },
  },
  {
    id: SectionID.Training,
    icon: images['shoes.png'].default,
    text: 'Тренировки',
    width: '100%',
    iconProps: {
      padding: '0 4px 0 4px',
      width: 40,
      height: 32,
    },
    navigationIconProps: {
      width: 24,
      height: 24,
    },
  },
  {
    id: SectionID.Instructions,
    icon: images['instruction.svg'].default,
    text: 'Инструкции',
    width: '100%',
    navigationIconProps: {
      width: 32,
      height: 32,
    },
  },
];

type SectionData = {
  [key in SectionID]?: {
    items: Array<ContentData>;
  };
};

export type ContentData = {
  contentTitle: string;
  description: Array<ReactChild>;
  videoHref?: string;
  isFirstSlide?: boolean;
  isLastSlide?: boolean;
  buttonProps?: {
    text: string;
    icon: string;
  };
  nextIndex?: number;
  prevIndex?: number;
  currentIndex?: number;
};

export const sectionData: SectionData = {
  [SectionID.Important]: {
    items: [
      {
        contentTitle: 'Раздел "Важное"',
        description: [
          'В данном разделе собрана основная информация, которая пригодится вам как перед самым началом, так и в процессе участия в нашем проекте похудения😎',
          'В первую очередь обязательно ознакомьтесь именно с этой информацией, уверены, что она будет для вас очень полезной😌',
          <>
            📲Нажмите кнопку ⏩ <strong>Далее</strong>, чтобы посмотреть
            приветственное <strong>ВИДЕО О ПРОЕКТЕ.</strong>
          </>,
          <>
            Также вы можете использовать <strong>быструю навигацию</strong> и
            сразу нажать на кнопку с интересующей информацией😉
          </>,
        ],
      },
      {
        contentTitle: 'Приветственное видео о проекте',
        buttonProps: {
          text: 'Видео о проекте',
          icon: images['youtube.svg'].default,
        },
        description: [
          'Это приветственное видео о проекте. Если вы у нас впервые, то оно поможет вам разобраться с тем, как все устроено, и понять основные моменты в организации проекта😎',
          <>
            Видео доступно по этой{' '}
            <a
              target="_blank"
              rel="noreferrer"
              href={'https://youtu.be/hZgyHLTQGTs'}
            >
              ССЫЛКЕ.
            </a>
            😌
          </>,
          'Посмотрите его, пожалуйста, это займет совсем немного времени, но будет очень полезным для вас❤️',
          <>
            📲Нажмите кнопку ⏩ <strong>Далее</strong>, чтобы посмотреть
            инструкцию "КАК ВОЙТИ В ЛИЧНЫЙ КАБИНЕТ?"
          </>,
        ],
        videoHref: 'hZgyHLTQGTs',
      },
      {
        contentTitle: 'Вход в личный кабинет',
        buttonProps: {
          text: 'Вход в личный кабинет',
          icon: images['personalAccount.svg'].default,
        },
        description: [
          <>
            ❗<strong>Инструкция, как войти в Личный Кабинет</strong>, находится
            по этой{' '}
            <a
              href={
                'https://drive.google.com/file/d/1UVrgnljg2fpWOBuMquUKoq57AKD_67kw/view?usp=sharing'
              }
            >
              ССЫЛКЕ.
            </a>
          </>,
          '👉При загрузке инструкции "Как войти в Личный Кабинет?", в зависимости от скорости вашего интернет-соединения,' +
            'может потребоваться немного времени. Пожалуйста, подождите🙏',
          <>
            ⚠️Если же у вас возникли <strong>проблемы со входом</strong>,
            нажмите кнопку ⏩ <strong>Далее</strong>, чтобы посмотреть
            инструкцию{' '}
            <strong>"КАК ВОССТАНОВИТЬ ПАРОЛЬ В ЛИЧНЫЙ КАБИНЕТ?"</strong>
          </>,
        ],
      },
      {
        contentTitle: 'Восстановление пароля',
        buttonProps: {
          text: 'Восстановление пароля',
          icon: images['lock.svg'].default,
        },
        description: [
          <>
            Для того, чтобы восстановить свой пароль в{' '}
            <strong>Личный Кабинет</strong>, вам нужно:
          </>,
          <div>
            <div>
              1️⃣ На экране входа нажать на кнопку{' '}
              <strong>"Забыли пароль"</strong>
            </div>
            <div>
              2️⃣ Ввести ваш номер телефона в международном формате и нажать
              кнопку <strong>"Подтвердить"</strong>
            </div>
            <div>3️⃣ Ввести 6-значный проверочный код из полученного СМС</div>
            <div>4️⃣ Придумать новый пароль и ввести его два раза</div>
            <div>
              5️⃣ Нажать кнопку <strong>"Сохранить"</strong>
            </div>
          </div>,
          '👉После этого вам нужно ввести ваш номер телефона и пароль, который вы только что придумали, и в случае, если вы ввели все данные верно, у вас откроется ваш личный кабинет😍',
          <>
            ❗️
            <strong>
              Инструкция, как восстановить пароль в Личный Кабинет
            </strong>
            , находится по этой{' '}
            <a
              target="_blank"
              rel="noreferrer"
              href={
                'https://drive.google.com/file/d/12jaLcR1wn6kA-D3ALO8AwevBA8VlahCb/view?usp=sharing'
              }
            >
              ССЫЛКЕ.
            </a>
          </>,
          '👉При загрузке инструкции "Как восстановить пароль", в зависимости от скорости вашего интернет-соединения, может потребоваться немного времени. Пожалуйста, подождите🙏',
          <>
            ⚠️Если же у вас{' '}
            <strong>не получилось восстановить пароль с помощью СМС</strong> или
            возникли другие <strong>проблемы со входом</strong>, то напишите
            пожалуйста в{' '}
            <a
              target="_blank"
              rel="noreferrer"
              href={'https://telegram.me/tanya_yourfit_bot'}
            >
              Поддержка от @tanya_yourfit
            </a>
          </>,
          <>
            📲Нажмите кнопку ⏩ <strong>Далее</strong>, чтобы посмотреть
            инструкцию <strong>"КАК ВЫВЕСТИ ЯРЛЫК НА ГЛАВНЫЙ ЭКРАН?"</strong>
          </>,
        ],
      },
      {
        contentTitle: 'Как добавить ярлык на главный экран',
        buttonProps: {
          text: 'Ярлык на рабочий экран',
          icon: images['shortcut.svg'].default,
        },
        description: [
          <>
            Личный Кабинет не нужно скачивать на телефон, и им можно
            пользоваться с любого телефона. Но для того, чтобы иметь{' '}
            <strong>быстрый доступ к Личному Кабинету</strong>, вы можете
            добавить ярлык на главный экран своего мобильного телефона.
          </>,
          <>
            ❗️<strong>Инструкции, как добавить ярлык на главный экран</strong>{' '}
            для телефонов на базе IOS и ANDROID, находятся по этой{' '}
            <a
              target="_blank"
              rel="noreferrer"
              href={
                'https://drive.google.com/file/d/1YwjImeOVEqQPl5WViuRZjJNQE5YVo9fC/view?usp=sharing'
              }
            >
              ССЫЛКЕ.
            </a>
          </>,
          '👉При загрузке инструкций "Как добавить ярлык на главный экран?", в зависимости от скорости вашего интернет-соединения, может потребоваться немного времени. Пожалуйста, подождите🙏',
          <>
            ❗️Если у вас Iphone, и в браузере{' '}
            <strong>нет кнопки "На экран Домой"</strong>, то обратите внимание,
            вы скорее всего находитесь во встроенном браузере Telegram, в таком
            случае вам нужно открыть ссылку через обычный браузер - Safari.
          </>,
          <>
            ⚠️Обратите внимание, если в какой-то момент вы заметите, что
            какие-либо{' '}
            <strong>
              функции Личного Кабинета перестали корректно работать
            </strong>
            , это может быть связано с обновлением нашего приложения.
          </>,
          <>
            👉В таком случае вам{' '}
            <strong>необходимо удалить существующий ярлык</strong> на главном
            экране вашего мобильного телефона, и{' '}
            <strong>создать новый ярлык заново</strong> по инструкции выше.
          </>,
          <>
            📲Нажмите кнопку ⏩ <strong>Далее</strong>, чтобы узнать{' '}
            <strong>"ГЛАВНЫЙ СЕКРЕТ ПОХУДЕНИЯ"</strong>😉
          </>,
        ],
      },
      {
        contentTitle: 'Главный секрет похудения',
        buttonProps: {
          text: 'Секрет похудения',
          icon: images['weight.svg'].default,
        },
        description: [
          'В данном коротком видео мы объединили опыт нашей команды и тысяч наших участниц💃',
          'У нас есть примеры похудения как на 5-10, так и на 20, 30, 40+ кг. При этом, конечно же, у нас есть примеры участниц, которые не смогли добиться цели😔',
          'Мы много анализировали, в чем же разница между теми, у кого получилось и теми, кто опустил руки😎',
          <>
            Посмотрите, пожалуйста, видео по этой{' '}
            <a
              target="_blank"
              rel="noreferrer"
              href={'https://youtu.be/UfG0ggLax74'}
            >
              ССЫЛКЕ.
            </a>
            😌
          </>,
          'Это займет совсем немного времени, но в таком случае вероятность того, что вы достигните своей цели, значительно увеличится❤️',
          <>
            📲Нажмите кнопку ⏩ <strong>Далее</strong>, чтобы открыть{' '}
            <strong>ССЫЛКИ НА ПОЛЕЗНЫЕ ДОКУМЕНТЫ В ОДНОМ МЕСТЕ</strong>😉
          </>,
        ],
        videoHref: 'UfG0ggLax74',
      },
      {
        contentTitle: 'Ссылки на полезные документы в одном месте',
        buttonProps: {
          text: 'Полезные ссылки',
          icon: images['chainLink.svg'].default,
        },
        description: [
          'В данном разделе вы найдете список ссылок на основные документы в формате PDF, чтобы они были у вас под рукой в одном месте.',
          <>
            ❗️Обратите внимание, что если вы новичок, то вам нужно{' '}
            <strong>ОБЯЗАТЕЛЬНО</strong> ознакомиться со всеми пунктами в
            разделах 🍎<strong>Питание</strong>, 🤸‍♀
            <strong>️Тренировки</strong> и 📚<strong>Инструкции.</strong>
          </>,
          <>
            🥗📗<strong>ЧАСТО ВСТРЕЧАЮЩИЕСЯ ВОПРОСЫ ПО ПИТАНИЮ</strong> доступны
            по этой{' '}
            <a
              target="_blank"
              rel="noreferrer"
              href={
                'https://drive.google.com/file/d/11E3LyKZk8nR91MmyT0bqFg4jkhNHjCVd/view?usp=sharing'
              }
            >
              ССЫЛКЕ.
            </a>
          </>,
          <>
            🤸‍♀📕<strong>ЧАСТО ВСТРЕЧАЮЩИЕСЯ ВОПРОСЫ ПО ТРЕНИРОВКАМ</strong>{' '}
            доступны по этой{' '}
            <a
              target="_blank"
              rel="noreferrer"
              href={
                'https://drive.google.com/file/d/15GsFFbxX7XbUANiHVeUZ2JIdGc1xDHvy/view?usp=sharing'
              }
            >
              ССЫЛКЕ.
            </a>
          </>,
          <>
            👩‍⚕️📘<strong>МЕДИЦИНСКИЕ РЕКОМЕНДАЦИИ</strong> по распространенным
            заболеваниям от врача-диетолога доступны по этой{' '}
            <a
              target="_blank"
              rel="noreferrer"
              href={
                'https://drive.google.com/file/d/17gyvU_MKhp8OEzQFNW1jNvW-oIhV944v/view?usp=sharing'
              }
            >
              ССЫЛКЕ.
            </a>
          </>,
          <>
            🧐
            <strong>
              Алгоритм принятия решения о смене калоража плана питания
            </strong>{' '}
            доступен по этой{' '}
            <a
              target="_blank"
              rel="noreferrer"
              href={
                'https://drive.google.com/file/d/1Zsx9pChvelMjhTYDYB0LLiMOm3haau7G/view?usp=sharing'
              }
            >
              ССЫЛКЕ.
            </a>{' '}
          </>,
          <>
            📷<strong>Инструкция, как сделать фото ДО/ПОСЛЕ</strong> доступна по
            этой{' '}
            <a
              target="_blank"
              rel="noreferrer"
              href={
                'https://drive.google.com/file/d/1PngB6QUmIxDMK3WXbSr3EtVQQVApbjkm/view?usp=sharing'
              }
            >
              ССЫЛКЕ.
            </a>
          </>,
          <>
            📲Нажмите кнопку 💌<strong>Важное</strong>, чтобы вернуться в{' '}
            <strong>Раздел "ВАЖНОЕ"</strong> или нажмите кнопку 🍎
            <strong>Питание</strong>, чтобы детальнее ознакомиться с информацией
            по питанию в нашем проекте😉
          </>,
        ],
      },
    ],
  },
  [SectionID.Nutrition]: {
    items: [
      {
        contentTitle: 'Раздел "Питание"',
        description: [
          <>
            🥗В данном разделе собрана полезная информация по{' '}
            <strong>питанию</strong>, которая вам пригодится в процессе участия
            в проекте:
          </>,
          <div>
            <div>▪️ Общая информация по питанию</div>
            <div>▪️ Какие планы питания есть в проекте?</div>
            <div>▪️ Что такое калораж и от чего он зависит?</div>
            <div>▪️ Как пользоваться калькулятором в плане-конструкторе?</div>
            <div>▪️ Какие продукты разрешены?</div>
            <div>▪️ Какие продукты запрещены?</div>
            <div>▪️ Как собрать себе прием пищи?</div>
            <div>▪️ Как пользоваться готовыми рецептами?</div>
            <div>▪️ Как комбинировать план-конструктор и готовое меню?</div>
            <div>▪️ Можно ли нарушать? Что такое Читмил?</div>
            <div>▪️ Как не сорваться?</div>
            <div>▪️ Ответы на часто встречающиеся вопросы по питанию</div>
          </div>,
          <>
            📲Нажмите кнопку ⏩ <strong>Далее</strong>, чтобы посмотреть общую
            информацию по <strong>ПИТАНИЮ</strong> в нашем проекте.
          </>,
          <>
            Также вы можете использовать <strong>быструю навигацию</strong> и
            сразу нажать на кнопку с интересующей информацией😉
          </>,
        ],
      },
      {
        contentTitle: 'О питании в проекте',
        buttonProps: {
          text: 'О питании',
          icon: images['tableWare.svg'].default,
        },
        description: [
          'Питание является неотъемлемой частью в процессе достижения желаемого веса и фигуры вашей мечты💃',
          <>
            🥑Наша <strong>система питания</strong> руководствуется прежде всего
            тем, чтобы не усложнять вам жизнь🙏
          </>,
          '🙅‍♀️Вам не придется считать калории и вносить каждый продукт в приложение. Это сделано прежде всего для того, чтобы сэкономить ваше время для более важных моментов и ваших близких❤️',
          <>
            ❗️Если вы новичок, то в первую неделю - во время "адаптационного
            периода", у вас могут возникнуть сложности с системой, и некоторые
            моменты могут казаться непонятными. Но как только вы поймете
            систему, и узнаете все тонкости, вы сможете по достоинству оценить
            ее простоту и красоту 😉
          </>,
          <>
            <strong>
              Короткое видео о том, как устроено питание в проекте
            </strong>
            , находится по этой{' '}
            <a
              target="_blank"
              rel="noreferrer"
              href={'https://youtu.be/Cm0BBb7NuJ0'}
            >
              ССЫЛКЕ.
            </a>
          </>,
          'Обязательно с ним ознакомьтесь, уверены, что оно будет для вас очень полезным😌',
          <>
            📲Нажмите кнопку ⏩ <strong>Далее</strong>, чтобы посмотреть
            информацию <strong>"КАКИЕ ПЛАНЫ ПИТАНИЯ ЕСТЬ В ПРОЕКТЕ?"</strong>
          </>,
          <>
            Также вы можете нажать кнопку 🍎<strong>ПИТАНИЕ</strong>, чтобы
            вернуться в <strong>Раздел "ПИТАНИЕ"</strong>😉
          </>,
        ],
        videoHref: 'Cm0BBb7NuJ0',
      },
      {
        contentTitle: 'Какие планы питания есть в проекте?',
        buttonProps: {
          text: 'Планы питания',
          icon: images['menu2.svg'].default,
        },
        description: [
          <>
            🥗На текущий момент в личном кабинете доступны три плана питания -{' '}
            <strong>"Похудение"</strong>, <strong>"Поддержание"</strong>, а
            также отдельные базы рецептов к каждому из них.
          </>,
          '👉Планы питания отличаются между собой соотношением БЖУ (белков/жиров/углеводов).',
          '💡Подробнее с информацией о "белках", "жирах" и "углеводах" можно ознакомиться в Личном Кабинете во вкладке "Общение, поддержка, обучение" (раздел с полезными видео внизу страницы).',
          <div>
            <div>
              <strong>Соотношение БЖУ плана питания "Похудение":</strong>
            </div>
            <div>🥩🥚Белки - 37%</div>
            <div>🧀🥑Жиры - 37%</div>
            <div>🥕🍐Углеводы - 26%</div>
          </div>,
          '➡️План питания "Похудение" использует большая часть участников, у которых основная цель - снизить вес. Повышенное содержание белка в вышеуказанном плане питания позволяет снижать вес преимущественно за счет жировой ткани, а не за счет мышц. Таким образом участник не просто худеет, но и улучшает качество тела.',
          <div>
            <div>
              <strong>Соотношение БЖУ плана питания "Поддержание":</strong>
            </div>
            <div>🥩🥚Белки - 24%</div>
            <div>🧀🥑Жиры - 36%</div>
            <div>🥕🍐Углеводы - 40%</div>
          </div>,
          <div>
            <div>
              ➡️План питания "Поддержание" используют участники, которые:
            </div>
            <div>
              ▪️ Достигли своего целевого веса, и теперь их основная цель -
              поддержание веса
            </div>
            <div>
              ▪️ "Устали" от плана питания "Похудение" и хотят попробовать
              что-то новое.
            </div>
            <div>
              ▪️{' '}
              {
                'Страдают определенными заболеваниями ("хронический гломерулонефрит", "диабетическая нефропатия" и другими), где есть строгие рекомендации по ограничении содержания белка в дневном рационе (см. Раздел "Инструкции -> "Медицинские рекомендации").'
              }
            </div>
          </div>,
          <>
            ❗️Планы питания и базы рецептов расчитаны на{' '}
            <strong>4 приема пищи</strong> - завтрак, обед, перекус и ужин.
            Перекус может быть как после завтрака, так и после обеда, также его
            можно делить пополам.
          </>,
          <>
            📲Нажмите кнопку ⏩ <strong>Далее</strong>, чтобы посмотреть
            информацию <strong>"ЧТО ТАКОЕ КАЛОРАЖ И ОТЧЕГО ОН ЗАВИСИТ?"</strong>
          </>,
        ],
      },
      {
        contentTitle: 'Что такое калораж и отчего он зависит?',
        buttonProps: {
          text: 'Калораж',
          icon: images['kcal.svg'].default,
        },
        description: [
          <>
            🤓Если говорить простым языком, то{' '}
            <strong>калораж вашего плана питания</strong> - это количество
            энергии, которую вы будете получать в течения дня, питаясь по вашим
            граммовкам в плане питания.
          </>,
          '⚡️Энергия расходуется как на физическую активность (тренировки), так и на обычную жизнь - ходьба, умственная работа, сон и тд.',
          '💡Для того, чтобы снижать вес вам нужно тратить этой энергии больше, чем вы потребляете (создать дефицит калорий).',
          'После заполнения вами стартовой анкеты система, оценив ваши параметры, присвоила калораж вашему плану питания с учетом необходимого дефицита. ',
          <>
            ❗️<strong>В первую неделю участия</strong> наша команда строго
            рекомендует вам использовать калораж плана питания изначально
            назначенный системой и ничего не менять. При этом большей части
            участников может показаться, что{' '}
            <strong>
              "еды слишком много, и никогда не похудеешь, когда столько ешь"
            </strong>
            😎
          </>,
          'Не переживайте, вы сможете самостоятельно корректировать установленный вам калораж (см. Раздел 📙"Инструкции).',
          <>
            📲Нажмите кнопку ⏩ <strong>Далее</strong>, чтобы посмотреть
            информацию{' '}
            <strong>
              "КАК ПОЛЬЗОВАТЬСЯ КАЛЬКУЛЯТОРОМ В ПЛАНЕ-КОНСТРУКТОРЕ?"
            </strong>
          </>,
        ],
      },
      {
        contentTitle: 'Как пользоваться калькулятором в плане-конструкторе?',
        buttonProps: {
          text: 'Калькулятор',
          icon: images['weight.svg'].default,
        },
        description: [
          'В вашем плане питания все продукты делятся на 3 категории, в зависимости от того, какой именно макронутриент преобладает в БЖУ:',
          <div>
            <div>🥩🥚Категория "Белки"</div>
            <div>🧀🥑Категория "Жиры"</div>
            <div>🥕🍐Категория "Углеводы"</div>
          </div>,
          'В каждой категории продуктов (белки, жиры, углеводы), есть подкатегории, например -\n' +
            '🍖“Мясо”, 🐟“Рыба”, 🧀“Сыр”, 🍆“Овощи” и тд. Вы также можете увидеть, что у каждой такой подкатегории есть свой цвет - зеленый и желтый.',
          '💡Каждая подкатегория содержит набор продуктов со схожим КБЖУ.',
          '❗️Может быть такое, что две, на первый взгляд, одинаковые подкатегории, как например 🍖“мясо”, имеют сразу два цвета - зеленый и желтый. Это связано с тем, что продукты, входящие в эти подкатегории, имеют различную усредненной калорийностью или гликемический индекс.',
          '📱Калькулятор в плане питания используется для того, чтобы упростить вам подсчет дробных частей продуктов в рамках одной категории.',
          <>
            Принцип работы калькулятора вы сможете увидеть в видео{' '}
            <strong>как устроено питание в проекте</strong> по этой{' '}
            <a
              target="_blank"
              rel="noreferrer"
              href={'https://youtu.be/Cm0BBb7NuJ0'}
            >
              ССЫЛКЕ.
            </a>
          </>,
          'Обязательно с ним ознакомьтесь, уверены, что оно будет для вас очень полезным😌',
          <>
            📲Нажмите кнопку ⏩ <strong>Далее</strong>, чтобы посмотреть
            информацию про <strong>"СПИСОК РАЗРЕШЕННЫХ ПРОДУКТОВ"</strong>
          </>,
        ],
        videoHref: 'Cm0BBb7NuJ0',
      },
      {
        contentTitle: 'Список разрешенных продуктов',
        buttonProps: {
          text: 'Разрешенные продукты',
          icon: images['eggs.svg'].default,
        },
        description: [
          <>
            🍏Вы можете ознакомиться с{' '}
            <strong>полным списком продуктов из вашего плана питания</strong> по
            этой{' '}
            <a
              target="_blank"
              rel="noreferrer"
              href={
                'https://drive.google.com/file/d/1pCel18swvsM67dl0cyRbfhOebkyW2Wtd/view?usp=sharing'
              }
            >
              ССЫЛКЕ.
            </a>
          </>,
          <>
            Данный список продуктов относится <strong>ТОЛЬКО</strong> к основным
            планам питания - <strong>"Похудение"</strong> и{' '}
            <strong>"Поддержание".</strong>
          </>,
          <>
            ❗️Если у вас есть сомнения по поводу какого-либо продукта, вы не
            знаете отнести его к "разрешенным" или "запрещенным" - спросите об
            этом в чате <strong>"Вопросы".</strong>
          </>,
          '⚠️При загрузке файла со списком продуктов, в зависимости от скорости вашего интернет-соединения, может потребоваться немного времени. Пожалуйста, подождите🙏',
          <>
            📲Нажмите кнопку ⏩ <strong>Далее</strong>, чтобы посмотреть
            информацию про <strong>"ЗАПРЕЩЕННЫЕ ПРОДУКТЫ"</strong>
          </>,
        ],
      },
      {
        contentTitle: 'Запрещенные продукты',
        buttonProps: {
          text: 'Запрещенные продукты',
          icon: images['donut.svg'].default,
        },
        description: [
          <>
            Как таковых "запрещенных продуктов НЕ существует. Но если ваша цель
            - снижение веса, то наша команда вам строго{' '}
            <strong>НЕ рекомендует</strong> использовать следующие продукты на
            плане питания <strong>"Похудение"</strong> до достижения вашей цели:
          </>,
          <div>
            <div>🔺Финики и изюм</div>
            <div>🔺Мясные и рыбные полуфабрикаты</div>
            <div>🔺Сахар и фруктоза в чистом виде</div>
            <div>🔺Диабетические сладости</div>
            <div>🔺Чипсы</div>
            <div>🔺Сушеная рыба</div>
            <div>🔺Сало</div>
            <div>🔺Алкоголь</div>
            <div>🔺Соки и напитки с сахаром</div>
            <div>🔺Мармелад, пастила, козинак</div>
            <div>🔺Ореховая мука</div>
            <div>🔺Йогурты с наполнителем</div>
            <div>🔺Творожные сырки</div>
            <div>🔺Протеиновые батончики</div>
            <div>🔺Выпечка</div>
          </div>,
          <>
            ❗️Если у вас есть сомнения по поводу какого-либо продукта -
            спросите у куратора проекта в чате <strong>"Вопросы".</strong>
          </>,
          <>
            📕Также вы можете ознакомиться с ответами на часто встречающиеся
            вопросы по питанию по этой{' '}
            <a
              target="_blank"
              rel="noreferrer"
              href={
                'https://drive.google.com/file/d/11E3LyKZk8nR91MmyT0bqFg4jkhNHjCVd/view?usp=sharing'
              }
            >
              ССЫЛКЕ.
            </a>
          </>,
          '⚠️При загрузке файла с ответами, в зависимости от скорости вашего интернет-соединения, может потребоваться немного времени. Пожалуйста, подождите🙏',
          <>
            📲Нажмите кнопку ⏩ <strong>Далее</strong>, чтобы посмотреть
            информацию <strong>"КАК СОБРАТЬ СЕБЕ ПРИЕМ ПИЩИ?"</strong>
          </>,
        ],
      },
      {
        contentTitle: 'Как собрать себе прием пищи по конструктору?',
        buttonProps: {
          text: 'Собираем прием пищи',
          icon: images['plateChow.svg'].default,
        },
        description: [
          <>
            🧐При использовании плана-конструктора,{' '}
            <strong>
              каждый прием пищи должен состоять из продуктов трёх категорий
            </strong>{' '}
            - "белков", "жиров", "углеводов" (исключение - ужин в плане питания
            "Похудение", там только "белки" и "углеводы"). Например:
          </>,
          '👉Категория "БЕЛКИ" + Категория "ЖИРЫ" + Категория "УГЛЕВОДЫ"',
          <div>
            <div>🔹Курица + Сыр + Гречневая крупа</div>
            <div>🔹Хек + Масло сливочное + Огурцы</div>
            <div>🔹Йогурт + Миндаль + Персик</div>
          </div>,
          '➡️Если вы используете только 1 продукт в рамках одной категории, то берете по "серой" граммовке.',
          '💡Если хотите взять несколько продуктов в рамках одной категории, то используйте калькулятор.',
          '➡️Уменьшаете граммовку одного из продуктов, она становится черного цвета, вы можете взять еще 1 продукт из этой категории по зеленой граммовке. Если вы хотите взять 3 продукта, то уменьшается граммовку сначала одного продукта, затем второго, они становятся черными, и добираете еще 1 продукт из этой категории по зеленой граммовке.',
          <>
            Принцип работы калькулятора вы сможете увидеть в видео{' '}
            <strong>как устроено питание в проекте</strong> по этой{' '}
            <a
              target="_blank"
              rel="noreferrer"
              href={'https://youtu.be/Cm0BBb7NuJ0'}
            >
              ССЫЛКЕ.
            </a>
          </>,
          <>
            📲Нажмите кнопку ⏩ <strong>Далее</strong>, чтобы посмотреть
            информацию <strong>"КАК ПОЛЬЗОВАТЬСЯ ГОТОВЫМ МЕНЮ?"</strong>
          </>,
        ],
        videoHref: 'Cm0BBb7NuJ0',
      },
      {
        contentTitle: 'Как пользоваться готовым меню?',
        buttonProps: {
          text: 'Готовое меню',
          icon: images['menu.svg'].default,
        },
        description: [
          '🥘Готовые рецепты являются отличной альтернативой плану-конструктору.',
          'Вы можете использовать предложенные рецепты на день. Если же вам не нравится те блюда, которые система вам предложила, вы можете выбрать любое другое блюдо из соответствующего приема пищи.',
          '💡Все рецепты в вашем меню в рамках одного приема пищи имеют схожий КБЖУ и могут меняться между собой местами. Также их граммовки адаптированы под ваш личный калораж. Если изменится ваш калораж, то поменяются все граммовки в рецептах.',
          '❗️Также завтраки и обеды имеют схожий КБЖУ и вы можете их менять местами. И готовить блюда указанные на завтрак в обед, и наоборот.',
          'Любые рецепты можно сохранять себя в любимые (нажимая ♥️), добавлять в корзину покупок (нажимая 🧺), а также их по названию или ингредиентам в составе.',
          '📊Нажав на любой из рецептов, вы увидите его энергетическую ценность и содержание белков, жиров и углеводов.',
          '❗️Ингредиенты в готовых рецептах заменять можно в рамках одной подкатегории конструктора (например, муку на лаваш, или яблоко на грушу).',
          '⏱Также вы можете готовить одно блюдо по двойной порции сразу на завтрак и обед, если у вас нет много времени на готовку.',
          <>
            📲Нажмите кнопку ⏩ <strong>Далее</strong>, чтобы посмотреть
            информацию{' '}
            <strong>
              "КАК КОМБИНИРОВАТЬ ПЛАН-КОНСТРУКТОР И ГОТОВОЕ МЕНЮ?"
            </strong>
          </>,
        ],
      },
      {
        contentTitle: 'Как комбинировать план-конструктор и готовое меню?',
        buttonProps: {
          text: 'Как комбинировать',
          icon: images['combination.svg'].default,
        },
        description: [
          '️❗️Не обязательно придерживаться только плана-конструктора или готового меню. Вы можете их комбинировать. И питаться как по конструктору, так и по готовому меню.',
          '💡Например, вы можете съесть завтрак и перекус по конструктору, а обед и ужин по готовому меню. Доступны любые варианты комбинирования конструктора и готовых рецептов, вы не ограничены в выборе. В этом и заключается гибкость и главная прелесть нашей системы питания.',
          <>
            📲Нажмите кнопку ⏩ <strong>Далее</strong>, чтобы посмотреть
            информацию <strong>"МОЖНО ЛИ НАРУШАТЬ? ЧТО ТАКОЕ ЧИТМИЛ?"</strong>
          </>,
        ],
      },
      {
        contentTitle: 'Можно ли нарушать? Что такое читмил?',
        buttonProps: {
          text: 'Читмил',
          icon: images['pizza.svg'].default,
        },
        description: [
          '😤Насколько бы не была сильна ваша сила воли, строгие ограничения практически гарантированно ведут к срывам☹️',
          <>
            Поэтому мы <strong>позволяем немного нарушать правила</strong> - это
            называется <strong>"ЧитМил"</strong>😈
          </>,
          <>
            Нарушать правила ("читмилить") можно{' '}
            <strong>1 раз в 2 недели:</strong>
          </>,
          <>
            🍕Вы сможете съесть в один из приемов пищи (завтрак, обед, перекус
            или ужин) <strong>запрещенный продукт или блюдо</strong> (например -
            сникерс или пиццу, выпить алкоголь).
          </>,
          <>
            🍟Желательно делать "ЧитМил"{' '}
            <strong>в пятницу или в выходные дни.</strong>
          </>,
          <>
            🥂Если вы чувствуете, что можете обойтись без ЧитМила, то{' '}
            <strong>делать его не обязательно.</strong>
          </>,
          '❗️Свой первый "ЧитМил" сделайте не раньше второй недели участия.',
          <>
            📲Нажмите кнопку ⏩ <strong>Далее</strong>, чтобы посмотреть
            информацию <strong>"КАК НЕ СОРВАТЬСЯ?"</strong>
          </>,
        ],
      },
      {
        contentTitle: 'Как не сорваться?',
        buttonProps: {
          text: 'Антисрыв',
          icon: images['antiExplosion.svg'].default,
        },
        description: [
          <>
            ❗️<strong>Видео о том, как не сорваться</strong>, находится по этой{' '}
            <a
              target="_blank"
              rel="noreferrer"
              href={'https://youtu.be/8Xczg1_k2jU'}
            >
              ССЫЛКЕ.
            </a>
          </>,
          'Обязательно с ним ознакомьтесь, уверены, что оно будет для вас очень полезным😌',
          <>
            📲Нажмите кнопку ⏩ <strong>Далее</strong>, чтобы посмотреть{' '}
            <strong>"ОТВЕТЫ НА ЧАСТО ВСТРЕЧАЮЩИЕСЯ ВОПРОСЫ ПО ПИТАНИЮ"</strong>
          </>,
        ],
        videoHref: '8Xczg1_k2jU',
      },
      {
        contentTitle: 'Ответы на часто встречающиеся вопросы по питанию',
        buttonProps: {
          text: 'Ответы на вопросы',
          icon: images['question.svg'].default,
        },
        description: [
          <>
            📕Вы можете ознакомиться с ответами на часто встречающиеся вопросы
            по питанию по этой{' '}
            <a
              target="_blank"
              rel="noreferrer"
              href={
                'https://drive.google.com/file/d/11E3LyKZk8nR91MmyT0bqFg4jkhNHjCVd/view?usp=sharing'
              }
            >
              ССЫЛКЕ.
            </a>
          </>,
          '⚠️При загрузке файла с ответами, в зависимости от скорости вашего интернет-соединения, может потребоваться немного времени. Пожалуйста, подождите🙏',
          <>
            📲Нажмите кнопку 🍎<strong>Питание</strong>, чтобы вернуться в{' '}
            <strong>Раздел "ПИТАНИЕ"</strong> или нажмите кнопку 🤸‍♀️
            <strong>Тренировки</strong>, чтобы детальнее ознакомиться с общими
            полезными инструкциями, которые вам пригодятся в процессе участия😉
          </>,
        ],
      },
    ],
  },
  [SectionID.Training]: {
    items: [
      {
        contentTitle: 'Раздел "Тренировки"',
        description: [
          '🤸‍♀️В данном разделе собрана полезная информация по тренировкам, которая вам пригодится в процессе участия:',
          <div>
            <div>▪️ Общая информация по тренировкам</div>
            <div>▪️ Где найти тренировки?</div>
            <div>▪️ Какие виды тренировок есть в проекте?</div>
            <div>▪️ Какой инвентарь необходим для тренировок?</div>
            <div>▪️ С каких тренировок начать?</div>
            <div>▪️ Какие тренировки выбрать при большом весе?</div>
            <div>▪️ Медицинские ограничения при тренировках</div>
            <div>▪️ В какое время питаться, когда тренируешься?</div>
            <div>▪️ Сколько нужно проходить шагов в день?</div>
            <div>▪️ Ответы на часто встречающиеся вопросы по тренировкам</div>
          </div>,
          <>
            📲Нажмите кнопку ⏩ <strong>Далее</strong>, чтобы посмотреть общую
            информацию по <strong>ТРЕНИРОВКАМ</strong> в нашем проекте.
          </>,
          <>
            Также вы можете использовать <strong>быструю навигацию</strong> и
            сразу нажать на кнопку с интересующей информацией😉
          </>,
        ],
      },
      {
        contentTitle: 'О тренировках в проекте',
        buttonProps: {
          text: 'О тренировках',
          icon: images['appleWatch.svg'].default,
        },
        description: [
          '🤸‍♀️В нашем проекте мы собрали самые лучшие и эффективные форматы тренировок, которые помогут вам не только снизить вес и улучшить свою фигуру, но и привести здоровье в порядок, снять напряжение и избавиться от стресса.',
          <>
            📲Нажмите кнопку ⏩ <strong>Далее</strong>, чтобы посмотреть
            информацию <strong>"ГДЕ НАЙТИ ТРЕНИРОВКИ?"</strong>
          </>,
          <>
            Также вы можете нажать кнопку 🤸‍♀️<strong>Тренировки</strong>, чтобы
            вернуться в <strong>Раздел "ТРЕНИРОВКИ"</strong>😉
          </>,
        ],
      },
      {
        contentTitle: 'Где найти тренировки?',
        buttonProps: {
          text: 'Где найти',
          icon: images['whereFind.svg'].default,
        },
        description: [
          <>
            1️⃣ В Личном Кабинете <strong>во вкладке "Тренировки"</strong>, в
            самом верху вы можете найти{' '}
            <strong>базовые программы тренировок</strong> - 39 интенсивных и 24
            легких восстановительных профессионально смонтированных тренировок в
            записи с звуковым таймером и предпоказами.
          </>,
          '💡Также здесь есть шаговые тренировки и зарядки, это открытые тренировки с Youtube, добавленные по просьбе наших участников.',
          <>
            📲Нажмите кнопку ⏩ <strong>Далее</strong>, чтобы посмотреть
            информацию <strong>"КАКИЕ ВИДЫ ТРЕНИРОВОК ЕСТЬ В ПРОЕКТЕ?"</strong>
          </>,
        ],
      },
      {
        contentTitle: 'Какие виды тренировок есть в проекте?',
        buttonProps: {
          text: 'Виды тренировок',
          icon: images['typesTraining.svg'].default,
        },
        description: [
          <>
            <em>Виды тренировок в проекте:</em>
          </>,
          <>
            🔥<strong>ИНТЕНСИВНЫЕ</strong>🔥
          </>,
          <>
            ♦️ <strong>Круговые тренировки на всё тело</strong>
          </>,
          <div>
            <div>
              ▪️ Позволяют одновременно прорабатывать все проблемные зоны тела.
              Укрепляют все основные группы мышц, сжигают лишние калории,
              способствуют снижению веса, повышают общую силу и выносливость,
              улучшают кардиовыносливость.
            </div>
            <div>▪️ Выполняются без дополнительного оборудования.</div>
            <div>▪️ Подходят для всех уровней подготовки.</div>
          </div>,
          <>
            ♦️ <strong>Интервальные тренировки Табата/HIIT</strong>
          </>,
          <div>
            <div>
              ▪️ Эффективны для жиросжигания, развития выносливости, укрепления
              мышц. Это настоящий стресс и испытание на прочность.
            </div>
            <div>▪️ Подходят ТОЛЬКО для подготовленных людей.</div>
          </div>,
          <>
            ♦️ <strong>Пилатес</strong>
          </>,
          <div>
            <div>
              ▪️ Включают упражнения на ковре, начинающего и среднего уровня
              подготовки по системе Джозефа Пилатеса. Занятия способствуют
              улучшению гибкости, силы. Укрепляют глубокий мышечный корсет,
              улучшают осанку и общее физическое состояние.
            </div>
            <div>
              ▪️ На тренировках подробно объясняется техника выполнения
              упражнений и дыхания.
            </div>
            <div>▪️ Подходят для всех уровней подготовки.</div>
          </div>,
          <>
            🧘‍♀️<strong>СПОКОЙНЫЕ</strong>🙏
          </>,
          <>
            🔹 <strong>Лёгкие круговые тренировки на всё тело</strong>
          </>,
          <div>
            <div>
              ▪️ Улучшают гибкость и подвижность суставов, снимают напряжение и
              усталость мышц, позволяют телу расслабиться.
            </div>
            <div>
              ▪️ Такие полезные и приятные тренировки добавят вам грациозности и
              пластичности.
            </div>
            <div>▪️ Подходят для всех уровней подготовки.</div>
          </div>,
          <>
            🔹 <strong>Стретчинг и Растяжка</strong>
          </>,
          <div>
            <div>
              ▪️ Данные тренировки улучшат вашу гибкость и подвижность суставов,
              снимут стресс, помогут расслабиться, восстановиться после
              нагрузок.
            </div>
            <div>
              ▪️ На тренировках подробно объясняется техника выполнения
              упражнений и дыхания.
            </div>
            <div>️▪️ Подходят для всех уровней подготовки.</div>
          </div>,
          <>
            📲Нажмите кнопку ⏩ <strong>Далее</strong>, чтобы посмотреть
            информацию{' '}
            <strong>"КАКОЙ ИНВЕНТАРЬ НЕОБХОДИМ ДЛЯ ТРЕНИРОВОК?"</strong>
          </>,
        ],
      },
      {
        contentTitle: 'Какой инвентарь необходим для тренировок?',
        buttonProps: {
          text: 'Инвентарь',
          icon: images['dumbbells.svg'].default,
        },
        description: [
          <>
            🔺Все интенсивные тренировки (кроме Пилатеса) важно делать{' '}
            <strong>в кроссовках</strong>.
          </>,
          <>
            🔺Лёгкие тренировки (и Пилатес) можно выполнять{' '}
            <strong>босиком или в носках</strong>.
          </>,
          <>
            🔺Для большинства тренировок в проекте вам пригодится{' '}
            <strong>фитнес-коврик</strong>.
          </>,
          <>
            🔺Для интенсивных тренировок вам может понадобится{' '}
            <strong>набор гантелей по 1-5 кг.</strong>
          </>,
          <>
            ❗️Это примерный вес гантелей, который вам понадобится для начала.
            Но в зависимости от уровня вашей подготовки вы можете подобрать вес
            гантелей так, чтобы последние несколько повторений в подходе вам
            было тяжело выполнять. Гантели легко можно заменить на бутылки с
            водой или песком или любой другой подручный материал.
          </>,
          <>
            💡Также для силовых тренировок можно использовать{' '}
            <strong>набор фитнес-резинок</strong> и{' '}
            <strong>утяжелители на ноги</strong> для варьирования нагрузки.
          </>,
          <>
            📲Нажмите кнопку ⏩ <strong>Далее</strong>, чтобы посмотреть
            информацию <strong>"С КАКИХ ТРЕНИРОВОК НАЧАТЬ?"</strong>
          </>,
        ],
      },
      {
        contentTitle: 'С каких тренировок начать?',
        buttonProps: {
          text: 'С чего начать',
          icon: images['plan.svg'].default,
        },
        description: [
          <>
            📕Начните{' '}
            <strong>с базовых программ интенсивных и легких тренировок.</strong>{' '}
            Делайте 3 интенсивных тренировки в неделю, через день. В дни отдыха
            выполняйте 2 легких тренировки в неделю.
          </>,
          <>
            ⚠️Если вам не подойдут тренировки без голосового сопровождения, то
            можно их заменить на тренировки из свободного доступа с моего
            ютуб-канала, которые вы найдете ниже😉
          </>,
          <>
            📲Нажмите кнопку ⏩ <strong>Далее</strong>, чтобы посмотреть
            информацию{' '}
            <strong>"КАКИЕ ТРЕНИРОВКИ ВЫБРАТЬ ПРИ БОЛЬШОМ ВЕСЕ?"</strong>
          </>,
        ],
      },
      {
        contentTitle: 'Какие тренировки выбрать при большом весе?',
        buttonProps: {
          text: 'При большом весе',
          icon: images['weight.svg'].default,
        },
        description: [
          '❗️Рекомендуется ограничить слишком интенсивные тренировки, бег, прыжковые упражнения, статическую нагрузку, упражнения с большой нагрузкой на суставы.',
          '👉Начать можно с простой ходьбы 1,5-2 часа в день, чуть позже можно добавить интенсивные тренировки, круговые тренировки на всё тело, пилатес и табаты.',
          <>
            📲Нажмите кнопку ⏩ <strong>Далее</strong>, чтобы посмотреть
            информацию{' '}
            <strong>"МЕДИЦИНСКИЕ ОГРАНИЧЕНИЯ ПРИ ТРЕНИРОВКАХ"</strong>
          </>,
        ],
      },
      {
        contentTitle: 'Медицинские ограничения при тренировках',
        buttonProps: {
          text: 'Мед. ограничения',
          icon: images['stethoscope.svg'].default,
        },
        description: [
          <>
            ❗️Если у вас есть ограничения по физической нагрузке, то{' '}
            <strong>
              обязательно следует проконсультироваться со своим лечащим врачом
            </strong>
            . Далее, исходя из его рекомендаций, подобрать подходящий для себя
            формат тренировок.
          </>,
          <>
            📲Нажмите кнопку ⏩ <strong>Далее</strong>, чтобы посмотреть
            информацию{' '}
            <strong>"В КАКОЕ ВРЕМЯ НУЖНО ПИТАТЬСЯ, КОГДА ТРЕНИРУЕШЬСЯ?"</strong>
          </>,
        ],
      },
      {
        contentTitle: 'В какое время нужно питаться, когда тренируешься?',
        buttonProps: {
          text: 'Еда до и после',
          icon: images['plateChow.svg'].default,
        },
        description: [
          <>
            🔴 <strong>До тренировки:</strong>
          </>,
          'Желательно делать прием пищи за 1,5- 2 часа, чтобы еда успела частично перевариться. Если вы не успеваете в это время, то можно перекусить что-то лёгкое, следуя вашему плану.',
          'Если ваша интенсивная тренировка утром, то желательно скушать порцию или пол-порции углеводов, чтобы у вас была энергия на занятие. Если вам комфортно, то тренироваться можно и натощак.',
          <>
            🔵 <strong>После тренировки:</strong>
          </>,
          'Можно делать прием пищи сразу, следуя вашему плану питания😌',
          <>
            📲Нажмите кнопку ⏩ <strong>Далее</strong>, чтобы посмотреть{' '}
            <strong>"СКОЛЬКО ШАГОВ НУЖНО ПРОХОДИТЬ В ДЕНЬ?"</strong>
          </>,
        ],
      },
      {
        contentTitle: 'Сколько шагов нужно проходить в день?',
        buttonProps: {
          text: 'Шаги',
          icon: images['steps.svg'].default,
        },
        description: [
          '🏃‍♀️Для повышения энерготрат за день, улучшения общего самочувствия, мы рекомендуем проходить в день в среднем 10000 шагов.',
          <>
            💡Если у вас нет возможности выйти на улицу, то вы можете просто
            сделать шаговую тренировку. Найти шаговые тренировки можно в личном
            кабинете <strong>во вкладке "Тренировки"</strong>.
          </>,
          <>
            📲Нажмите кнопку ⏩ <strong>Далее</strong>, чтобы посмотреть{' '}
            <strong>
              "ОТВЕТЫ НА ЧАСТО ВСТРЕЧАЮЩИЕСЯ ВОПРОСЫ ПО ТРЕНИРОВКАМ"
            </strong>
          </>,
        ],
      },
      {
        contentTitle: 'Ответы на часто встречающиеся вопросы по тренировка',
        buttonProps: {
          text: 'Ответы на вопросы',
          icon: images['question.svg'].default,
        },
        description: [
          <>
            📕Вы можете ознакомиться с ответами на часто встречающиеся вопросы
            по тренировкам по этой{' '}
            <a
              target="_blank"
              rel="noreferrer"
              href={
                'https://drive.google.com/file/d/15GsFFbxX7XbUANiHVeUZ2JIdGc1xDHvy/view?usp=sharing'
              }
            >
              ССЫЛКЕ.
            </a>
          </>,
          '⚠️При загрузке файла с ответами, в зависимости от скорости вашего интернет-соединения, может потребоваться немного времени. Пожалуйста, подождите🙏',
          <>
            📲Нажмите кнопку 🤸‍♀️<strong>Тренировки</strong>, чтобы вернуться в{' '}
            <strong>Раздел "ТРЕНИРОВКИ"</strong> или нажмите кнопку 📚
            <strong>Инструкции</strong>, чтобы детальнее ознакомиться с общими
            полезными инструкциями, которые вам пригодятся в процессе участия😉
          </>,
        ],
      },
    ],
  },
  [SectionID.Instructions]: {
    items: [
      {
        contentTitle: 'Раздел "Инструкции"',
        description: [
          '📚В данном разделе собраны общие полезные инструкции, которые вам пригодятся в процессе участия:',
          <div>
            <div>▪️ Когда и как проводить замеры?</div>
            <div>▪️ Как сменить план питания и калораж?</div>
            <div>▪️ Как добавить рецепт в любимые?</div>
            <div>▪️ Как пользоваться корзиной покупок?</div>
            <div>▪️ Как сделать и куда отправить фото ДО/ПОСЛЕ?</div>
            <div>
              ▪️ Медицинские рекомендации по часто встречающимся заболеваниям
            </div>
          </div>,
          <>
            📲Нажмите кнопку ⏩ <strong>Далее</strong>, чтобы посмотреть
            информацию по <strong>ЗАМЕРАМ</strong>.
          </>,
          <>
            Также вы можете использовать <strong>быструю навигацию</strong> и
            сразу нажать на кнопку с интересующей информацией😉
          </>,
        ],
      },
      {
        contentTitle: 'Замеры',
        buttonProps: {
          text: 'Замеры',
          icon: images['diet.svg'].default,
        },
        description: [
          '📐Для того, чтобы контролировать процесс похудения или удержания веса, необходимо проводить регулярные замеры.',
          'На основании опыта тысяч участников, наша команда рекомендует:',
          <div>
            <div>
              ▪️ Производить замеры только <strong>1 раз в неделю</strong>
            </div>
            <div>
              ▪️ Делать это строго утром натощак, после утреннего туалета
            </div>
            <div>
              ▪️ Желательно в один день - пятница, суббота или воскресенье
            </div>
          </div>,
          <>
            ❗️Личный кабинет позволяет производить замеры 1 раз в сутки. Но мы
            вам <strong>НЕ рекомендуем</strong> делать замеры каждый день, так
            как ежедневные скачки веса в обе стороны могут вызвать у вас
            дополнительный стресс, что негативно скажется на процессе похудения.
          </>,
          <>
            👉Чтобы <strong>сделать ЗАМЕРЫ</strong>, вам необходимо:
          </>,
          <div>
            <div>
              1️⃣ В Личном Кабинете перейти во вкладку <strong>ПРОФИЛЬ</strong>
            </div>
            <div>
              2️⃣ Нажать кнопку <strong>"Внести замеры"</strong>
            </div>
            <div>3️⃣ В открывшемся окне заполнить форму замеров</div>
            <div>
              4️⃣ Нажать на кнопку <strong>"Отправить"</strong>
            </div>
          </div>,
          <>
            ⚠️Если у вас <strong>возникли проблемы с отправкой замеров</strong>{' '}
            или есть <strong>дополнительные вопросы</strong>, то напишите нам в{' '}
            <a
              target="_blank"
              rel="noreferrer"
              href={'https://telegram.me/tanya_yourfit_bot'}
            >
              Поддержка от @tanya_yourfit
            </a>
            😉
          </>,
          <>
            📲Нажмите кнопку ⏩ <strong>Далее</strong>, чтобы посмотреть
            инструкцию <strong>"КАК СМЕНИТЬ ПЛАН ПИТАНИЯ?"</strong>
          </>,
          <>
            Также вы можете нажать кнопку 📚<strong>Инструкции</strong>, чтобы
            вернуться в <strong>Раздел "ИНСТРУКЦИИ"</strong>😉
          </>,
        ],
      },
      {
        contentTitle: 'Как сменить план питания?',
        buttonProps: {
          text: 'Смена плана питания',
          icon: images['menu2.svg'].default,
        },
        description: [
          <>
            На текущий момент в нашем проекте есть два плана питания -{' '}
            <strong>"Похудение"</strong>, <strong>"Поддержание"</strong>.
          </>,
          <>
            План питания <strong>"Похудение"</strong> на старте использует
            большая часть участников, у которых основная цель - снизить вес.
          </>,
          '⚠️ Соотношение БЖУ (Содержание белка около 37%) в вышеуказанном плане питания позволяет снижать вес преимущественно за счет жировой ткани, а не за счет мышц. Таким образом участник не просто худеет, но и улучшает качество тела.',
          <>
            👉Тем не менее, иногда возникает потребность в том, чтобы сменить
            план питания с <strong>"Похудение"</strong> на{' '}
            <strong>"Поддержание"</strong> (Содержание белка около 24%) в
            случае, если вы:
          </>,
          <div>
            <div>
              ▪️ Достигли своего целевого веса, и теперь ваша основная цель -
              поддержание веса.
            </div>
            <div>
              ▪️ Вы "устали" от плана питания "Похудение" и хотите попробовать
              что-то новое.
            </div>
            <div>
              ▪️ Страдаете такими заболеваниями, как хронический
              гломерулонефрит, диабетическая нефропатия и другими, где есть
              строгие рекомендации по ограничении содержания белка в дневном
              рационе.
            </div>
          </div>,
          '❗️Личный Кабинет позволяет сменить план питания только 1 раз в сутки. Также обратите внимание, что в каждом плане питания своя база рецептов, и если вы меняется план питания, то меняется и готовое меню, в том числе пропадают любимые рецепты.',
          <>
            🥗 Чтобы <strong>поменять ПЛАН ПИТАНИЯ</strong>, вам необходимо:
          </>,
          <div>
            <div>
              1️⃣ В Личном Кабинете перейти во вкладку <strong>ПРОФИЛЬ</strong>
            </div>
            <div>
              2️⃣ Нажать кнопку <strong>"ЛИЧНАЯ КАРТА ПОЛЬЗОВАТЕЛЯ"</strong>
            </div>
            <div>
              3️⃣ В открывшемся окне, в пункте 9 выбрать нужный план питания
            </div>
            <div>
              4️⃣ Нажать внизу на кнопку <strong>"Сохранить"</strong>
            </div>
          </div>,
          <>
            ⚠️Если у вас{' '}
            <strong>возникли проблемы со сменой плана питания</strong> или есть{' '}
            <strong>дополнительные вопросы</strong>, то напишите нам в{' '}
            <a
              target="_blank"
              rel="noreferrer"
              href={'https://telegram.me/tanya_yourfit_bot'}
            >
              Поддержка от @tanya_yourfit
            </a>
            😉
          </>,
          <>
            📲Нажмите кнопку ⏩ <strong>Далее</strong>, чтобы посмотреть
            инструкцию <strong>"КАК СМЕНИТЬ КАЛОРАЖ?"</strong>
          </>,
        ],
      },
      {
        contentTitle: 'Как поменять калораж плана питания?',
        buttonProps: {
          text: 'Смена калоража',
          icon: images['changeCalories.svg'].default,
        },
        description: [
          <>
            ❗️<strong>ВАЖНО:</strong> Ознакомьтесь с{' '}
            <strong>
              Алгоритмом принятия решения о смене калоража плана питания
            </strong>{' '}
            по этой{' '}
            <a
              target="_blank"
              rel="noreferrer"
              href={
                'https://drive.google.com/file/d/1Zsx9pChvelMjhTYDYB0LLiMOm3haau7G/view?usp=sharing'
              }
            >
              ССЫЛКЕ.
            </a>
          </>,
          <>
            🥗 Чтобы <strong>поменять КАЛОРАЖ ПЛАНА ПИТАНИЯ</strong>, вам
            необходимо:
          </>,
          <div>
            <div>
              1️⃣ В Личном Кабинете перейти во вкладку <strong>ПРОФИЛЬ</strong>
            </div>
            <div>
              2️⃣ Нажать кнопку <strong>"ЛИЧНАЯ КАРТА ПОЛЬЗОВАТЕЛЯ"</strong>
            </div>
            <div>3️⃣ В открывшемся окне, в пункте 8 выбрать нужный калораж</div>
            <div>
              4️⃣ Нажать внизу на кнопку <strong>"Сохранить"</strong>
            </div>
          </div>,
          '❗️Личный Кабинет позволяет сменить калораж плана питания только 1 раз в сутки. Также обратите внимание, что при смене калоража все граммовки в плане питания и готовом меню изменяются.',
          <>
            ⚠️Если у вас{' '}
            <strong>возникли проблемы со сменой калоража плана питания</strong>{' '}
            или есть <strong>дополнительные вопросы</strong>, то напишите нам в{' '}
            <a
              target="_blank"
              rel="noreferrer"
              href={'https://telegram.me/tanya_yourfit_bot'}
            >
              Поддержка от @tanya_yourfit
            </a>
            😉
          </>,
          <>
            📲Нажмите кнопку ⏩ <strong>Далее</strong>, чтобы посмотреть
            инструкцию <strong>"КАК ДОБАВИТЬ РЕЦЕПТ В ЛЮБИМЫЕ?"</strong>
          </>,
        ],
      },
      {
        contentTitle: 'Как добавить рецепт в любимые?',
        buttonProps: {
          text: 'Любимые рецепты',
          icon: images['recipe.svg'].default,
        },
        description: [
          'У вас есть возможность сохранять любимые рецепты в готовом меню.',
          <>
            ♥️ Чтобы <strong>ДОБАВИТЬ РЕЦЕПТ В ЛЮБИМЫЕ</strong>, вам необходимо:
          </>,
          <div>
            <div>
              1️⃣ В Личном Кабинете перейти во вкладку <strong>ПИТАНИЕ</strong>
            </div>
            <div>
              2️⃣ Нажать сверху переключатель на <strong>"ГОТОВОЕ МЕНЮ"</strong>
            </div>
            <div>
              3️⃣ Выбрать понравившийся вам рецепт и нажать на ❤️(сердечко)
            </div>
          </div>,
          <>
            ❗️Личный Кабинет позволяет добавлять только 100 любимых рецептов.
            Если вы превысите это значение, у вас будет появляться "ошибка
            добавления любимых рецептов".
          </>,
          <>
            📲Нажмите кнопку ⏩ <strong>Далее</strong>, чтобы посмотреть
            инструкцию <strong>"КАК ДОБАВИТЬ РЕЦЕПТ В КОРЗИНУ ПОКУПОК?"</strong>
          </>,
        ],
      },
      {
        contentTitle: 'Как добавить рецепт в корзину покупок?',
        buttonProps: {
          text: 'Корзина покупок',
          icon: images['wheelChair.svg'].default,
        },
        description: [
          <>
            У вас есть возможность добавлять рецепты в корзину покупок, чтобы
            потом было удобнее производить закупки в магазине.
          </>,
          <>
            🧺 Чтобы <strong>СФОРМИРОВАТЬ КОРЗИНУ ПОКУПОК</strong>, вам
            необходимо:
          </>,
          <div>
            <div>
              1️⃣ В Личном Кабинете перейти во вкладку <strong>ПИТАНИЕ</strong>
            </div>
            <div>
              2️⃣ Нажать сверху переключатель на <strong>"ГОТОВОЕ МЕНЮ"</strong>
            </div>
            <div>
              3️⃣ Выбрать понравившиеся вам рецепты и нажать на значок
              🧺(корзина)
            </div>
            <div>
              4️⃣ Нажать сверху переключатель на <strong>"КОРЗИНА"</strong>
            </div>
            <div>
              5️⃣ С помощью кнопок <strong>"-"</strong> и <strong>"+"</strong>{' '}
              выбрать количество рецептов
            </div>
            <div>
              6️⃣ В магазине, нажимая на "серый квадратик" возле ингредиента, вы
              можете его вычеркивать из списка покупок
            </div>
          </div>,
          <>
            ❗️После завершения закупок в магазине нажмите внизу кнопку{' '}
            <strong>"Очистить корзину"</strong>, для того, перед тем как
            добавлять туда рецепты для следующей закупки.
          </>,
          <>
            📲Нажмите кнопку ⏩ <strong>Далее</strong>, чтобы посмотреть
            инструкцию{' '}
            <strong>"КАК СДЕЛАТЬ И КУДА ОТПРАВЛЯТЬ ФОТО ДО/ПОСЛЕ?"</strong>
          </>,
        ],
      },
      {
        contentTitle: 'Как сделать и куда отправлять фото до/после?',
        buttonProps: {
          text: 'Фото до-после',
          icon: images['beforeAfterFoto.svg'].default,
        },
        description: [
          <>
            📷 Фото ДО/ПОСЛЕ необходимы, если ваше участие предполагает в конце
            проекта розыгрыш призов (Apple Watch и др.)
          </>,
          <>
            Если же вы не хотите принимать участия в розыгрыше или не хотите их
            высылать нам, то сделайте их просто для себя и сохраните. У нас было
            множество случаев, когда участники жалели, что не сделали фото ДО.
          </>,
          <>
            ❗️Инструкция, как сделать фото ДО/ПОСЛЕ, находится по этой{' '}
            <a
              target="_blank"
              rel="noreferrer"
              href={
                'https://drive.google.com/file/d/1PngB6QUmIxDMK3WXbSr3EtVQQVApbjkm/view?usp=sharing'
              }
            >
              ССЫЛКЕ.
            </a>
          </>,
          <>
            👉При загрузке инструкции "как сделать фото ДО/ПОСЛЕ", в зависимости
            от скорости вашего интернет-соединения, может потребоваться немного
            времени. Пожалуйста, подождите🙏
          </>,
          <>
            Прислать свои фото ДО/ПОСЛЕ необходимо в диалог{' '}
            <a
              target="_blank"
              rel="noreferrer"
              href={'https://telegram.me/tanya_yourfit_bot'}
            >
              "Поддержка от Tanya_yourfit"
            </a>
            😉
          </>,
          <>
            📲Нажмите кнопку ⏩ <strong>Далее</strong>, чтобы посмотреть{' '}
            <strong>"МЕДИЦИНСКИЕ РЕКОМЕНДАЦИИ"</strong> по часто встречающимся
            заболеваниям от врача-диетолога.
          </>,
        ],
      },
      {
        contentTitle: 'Медицинские рекомендации',
        buttonProps: {
          text: 'Мед. рекомендации',
          icon: images['stethoscope.svg'].default,
        },
        description: [
          <>
            ❗️Участие в проекте предполагает, что у вас есть какие-либо
            хронические заболевания, то вы знаете,{' '}
            <strong>
              какие у вас есть ограничения и рекомендации по питанию от вашего
              лечащего врача
            </strong>
            .
          </>,
          <>
            🥗Наши планы питания <strong>"ПОХУДЕНИЕ"</strong> и{' '}
            <strong>"ПОДДЕРЖАНИЕ"</strong> подходят для использовании при
            большинстве хронических заболеваний. А также могут быть легко
            адаптированы с учетом рекомендаций от вашего лечащего врача.
          </>,
          <>
            ⚠️В то же время план питания <strong>"ПОХУДЕНИЕ"</strong> может не
            подходить участникам с определенными заболеваниями почек,
            диабетической нефропатией, так как в нем процент содержания белков
            выше рекомендованного. Таким участникам необходимо использовать план
            питания <strong>"ПОДДЕРЖАНИЕ"</strong>.
          </>,
          <>
            👩🏻‍⚕️ Вы можете ознакомиться с нашими медицинскими рекомендациями по
            распространенным заболеваниям от врача-диетолога по этой{' '}
            <a
              target="_blank"
              rel="noreferrer"
              href={
                'https://drive.google.com/file/d/17gyvU_MKhp8OEzQFNW1jNvW-oIhV944v/view?usp=sharing'
              }
            >
              ССЫЛКЕ.
            </a>
          </>,
          <>
            👉При загрузке медицинских рекомендаций, в зависимости от скорости
            вашего интернет-соединения, может потребоваться немного времени.
            Пожалуйста, подождите🙏
          </>,
          <>
            📲Нажмите кнопку 💌<strong>Инструкции</strong>, чтобы вернуться в{' '}
            <strong>Раздел "Инструкции"</strong>
          </>,
        ],
      },
    ],
  },
};
