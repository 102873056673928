import Registration from '../pages/Registration';
import Menu from '../pages/Plan/components/Menu/menu';
import Plan from '../pages/Plan';
import ProfileInfo from '../pages/Profile/components/profileInfo';
import Profile from '../pages/Profile';
import { Support } from '../pages/Support/Support';
import Exercises from '../pages/Exercises';
import ExercisesWeek from '../pages/Exercises/components/ExercisesWeek';
import MeasurementTable from '../pages/Measurement/measurementTable';
import MeasurementEdit from '../pages/Measurement/measurementEdit';
import WeightLeaderboard from '../components/Leaderboard/weightLeaderboard';
import StepsLeaderboard from '../components/Leaderboard/stepsLeaderboard';
import Basket from '../pages/Plan/components/Basket/basket';
import SignUp from '../pages/SignUp/signUp';
import Login from '../pages/Login';
import { Role } from '../data';
import { MeasurementsSubmit } from '../pages/Measurement/measurementsSubmit';
import { NotFound } from '../components/NotFound';
import Photo from '../pages/Photo/photo';
import PhotoAdd from '../pages/Photo/photoAdd';
import VerifyEmail from 'pages/VerifyEmail';
import ResetPassword from 'pages/Password/resetPassword';
import Recipe from 'pages/Recipe/recipe';
import Payments from 'pages/Payments';
import Migrate from 'pages/Migrate';

export const routes = [
  { path: '/', component: Plan, roles: [Role.BASE], exact: true },
  { path: '/error', component: NotFound },
  { path: '/login', component: Login },
  { path: '/verify-email', component: VerifyEmail },
  { path: '/reset-password', component: ResetPassword },
  { path: '/migrate', component: Migrate },
  { path: '/signup', component: SignUp },
  { path: '/registration', component: Registration },
  { path: '/plan/menu', roles: [Role.BASE], component: Menu },
  { path: '/plan/basket', roles: [Role.BASE], component: Basket },
  { path: '/plan', roles: [Role.BASE], component: Plan },
  { path: '/recipe', roles: [Role.BASE], component: Recipe },
  { path: '/support', roles: [Role.BASE], component: Support },
  { path: '/exercises', roles: [Role.BASE], component: Exercises },
  { path: '/exercises_week', roles: [Role.BASE], component: ExercisesWeek },
  {
    path: '/leaderboard/weight',
    roles: [Role.BASE],
    component: WeightLeaderboard,
  },
  {
    path: '/leaderboard/steps',
    roles: [Role.BASE],
    component: StepsLeaderboard,
  },
  {
    path: '/measurement',
    roles: [Role.BASE],
    component: MeasurementTable,
    exact: true,
  },
  {
    path: '/measurement/submit',
    roles: [Role.MEASUREMENTS],
    component: MeasurementsSubmit,
    exact: true,
  },
  { path: '/measurement/:id', roles: [Role.BASE], component: MeasurementEdit },
  { path: '/profile', roles: [Role.BASE], component: Profile, exact: true },
  { path: '/profile/info', roles: [Role.BASE], component: ProfileInfo },
  { path: '/test', roles: [34], component: Plan },
  { path: '/photo', roles: [Role.BASE], component: Photo, exact: true },
  { path: '/photo/add', roles: [Role.BASE], component: PhotoAdd, exact: true },
  { path: '/payments', roles: [Role.BASE], component: Payments, exact: true },
];
