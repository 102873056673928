import { inject, observer } from 'mobx-react';
import ProtectedComponent from '../../../components/Base/protected';
import './Support.css';

@inject('uiStore', 'userStore', 'recipeStore')
@observer
class SupportLayout extends ProtectedComponent {
  constructor(props) {
    super(props);
  }

  render() {
    const user = this.props.userStore.profile;

    return user ? (
      <div className="container">{this.props.children}</div>
    ) : (
      <></>
    );
  }
}

export default SupportLayout;
