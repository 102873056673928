import { connector } from '../index';

export default class AuthActions {
  static async login({ username, password }, onError, onSuccess) {
    return connector.post(
      '/auth/token/',
      { username, password },
      onError,
      onSuccess
    );
  }

  static async migrate({ email }, onError, onSuccess) {
    return connector.post('/accounts/migrate/', { email }, onError, onSuccess);
  }

  static async getEmail(onSuccess) {
    return connector.get('/accounts/migrate/', null, null, onSuccess);
  }

  static async postRegistration({ email, password }, onError, onSuccess) {
    return connector.post(
      '/auth/register/',
      { email, password },
      onError,
      onSuccess
    );
  }

  static async verifyEmail({ token }, onError, onSuccess) {
    return connector.post(
      '/auth/email-verification/',
      { token },
      onError,
      onSuccess
    );
  }

  static async authenticate() {
    return connector.get(
      '/authenticate/' + Math.round(Math.random() * Number.MAX_SAFE_INTEGER)
    );
  }

  static async checkRegistration(username, phone) {
    return connector.post('/registration/check', { username, phone });
  }
}
