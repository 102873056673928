import { useState } from 'react';
import { IconButton } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { ComponentProgress } from 'components/Utils/componentUtils';

export const PasswordForm = (props) => {
  const [showPassword, setShowPassword] = useState(false);
  const { state, handleTextChange, confirm } = props;

  function handleClickShowPassword() {
    setShowPassword(!showPassword);
  }

  return (
    <div>
      <IconButton
        aria-label="toggle password visibility"
        onClick={handleClickShowPassword}
        edge="end"
      >
        {showPassword ? <Visibility /> : <VisibilityOff />}
      </IconButton>
      <input
        type={showPassword ? 'text' : 'password'}
        name="password"
        onChange={(e) => handleTextChange(e, 'password')}
        value={state.password}
        placeholder="Новый пароль"
      />
      <input
        type={showPassword ? 'text' : 'password'}
        name="confirmpassword"
        onChange={(e) => handleTextChange(e, 'confirmation')}
        value={state.confirmation}
        placeholder="Подтвердить пароль"
      />
      <span
        style={{
          color: 'red',
          marginBottom: '1em',
          opacity: +(
            state.password.length > 5 &&
            state.confirmation.length > 5 &&
            !validate(state.password, state.confirmation)
          ),
        }}
      >
        Введенные пароли не совпадают
      </span>
      {state.progress ? (
        <ComponentProgress />
      ) : (
        <input
          className="btn"
          type="submit"
          name="submit"
          disabled={
            state.password.length < 6 ||
            !validate(state.password, state.confirmation)
          }
          value="отправить"
          onClick={(e) => confirm(e)}
        />
      )}
    </div>
  );
};

const validate = (password, confirmation) => {
  return (
    password.length > 5 && confirmation.length > 5 && password === confirmation
  );
};
