import { ChangeEvent, useState } from 'react';
import PaymentsActions from 'connector/paymentsActions';

import styles from './Payments.module.scss';
import bg from '../../images/photo.jpeg';
import { inject, observer } from 'mobx-react';
import { UiStore } from 'stores';

const Payments = inject('uiStore')(
  observer((props) => {
    const uiStore: UiStore = props.uiStore;
    const token = localStorage.getItem('auth');

    const [agreements, setAgreements] = useState({
      agreementFirst: false,
      agreementSecond: false,
    });

    const setAgreementsHandler = (event: ChangeEvent<HTMLInputElement>) => {
      const { name, checked } = event.target;
      setAgreements((current) => ({ ...current, [name]: checked }));
    };

    const postPayment = async (paymentId: Number) => {
      try {
        PaymentsActions.postPayment(
          paymentId,
          () => {
            uiStore.showDialog(
              'Обновите страницу и попробуйте еще раз.',
              'Ошибка сервера!',
              () => {
                uiStore.clearDialog();
              },
              () => {
                uiStore.clearDialog();
              },
              'popup--style popup--red'
            );
          },
          (result) => {
            if (result.status === 200) {
              window.location.href = result.data.redirectUrl;
            } else {
            }
          }
        );
      } catch (error) {}
    };

    if (!token) {
      window.location.href = '/login';
    }

    return (
      <div className={styles.paymentsContainer}>
        <div
          className={styles.paymentsBody}
          style={{
            background: `-webkit-linear-gradient(top, rgba(0, 0, 0, 0.2), rgba(248, 86, 81, 0.2)), center / cover no-repeat url(${bg})`,
          }}
        >
          <div className={styles.content}>
            <h1 className={styles.title}>Выбери тариф участия в проекте</h1>
            <div className={styles.description}>
              <label htmlFor="agreementFirst">
                <input
                  type="checkbox"
                  name="agreementFirst"
                  id="agreementFirst"
                  onChange={(event) => setAgreementsHandler(event)}
                />
                <span>
                  Я подтверждаю свое согласие на периодическое списание средств
                  с карты в зависимости от выбранного тарифа
                </span>
              </label>
              <label htmlFor="agreementSecond">
                <input
                  type="checkbox"
                  name="agreementSecond"
                  id="agreementSecond"
                  onChange={(event) => setAgreementsHandler(event)}
                />
                <span>
                  Я согласен с{' '}
                  <a
                    href="https://4yourfit.by/upload/files/dogovor-podpiska.pdf"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    договором публичной оферты{' '}
                  </a>{' '}
                  и обработкой персональных данных.
                </span>
              </label>
            </div>
            <div className={styles.buttonsWrapper}>
              <button
                onClick={() => postPayment(1)}
                className={styles.button}
                disabled={
                  !agreements.agreementFirst || !agreements.agreementSecond
                }
              >
                29 BYN (30 дней)
              </button>
              <button
                onClick={() => postPayment(2)}
                className={styles.button}
                disabled={
                  !agreements.agreementFirst || !agreements.agreementSecond
                }
              >
                199 BYN (365 дней)
              </button>
            </div>
          </div>
        </div>
        <div className={styles.paymentsFooter}>
          <div className={styles.footerBody}>
            <ul className={styles.footerMenu}>
              <li>
                <a
                  href="https://4yourfit.by/upload/files/dogovor-podpiska.pdf"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  Договор оферты
                </a>
              </li>
              <li>
                <a
                  href="https://4yourfit.by/privacy/"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  Политика конфиденциальности
                </a>
              </li>
              <li>
                <a
                  href="https://4yourfit.by/dogovor/"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  Условия осуществления платежей
                </a>
              </li>
            </ul>
            <div className={styles.footerCopyright}>
              ИП Метельский Е.А., УНП 193205012, 220099, Республика Беларусь, г.
              Минск, ул. Кижеватова 16-5, Свидетельство о государственной
              регистрации №193205012 от 14.11.2022 выдано Минским горисполкомом,
              Режим работы 10:00 – 18:00, телефон: +375298927249, e-mail:
              support@4yourfit.by
            </div>
          </div>
        </div>
      </div>
    );
  })
);

export default Payments;
