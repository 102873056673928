import { action, makeObservable, observable } from 'mobx';
import { IRecipe } from '../types/IRecipe';
import { IStores } from '../types/IStores';
import { ISearchItem } from '../types/ISearchItem';
import { SessionStorageUtil } from '../components/Utils/sessionStorageUtil';
import RecipeActions from '../connector/recipeActions';
import { BaseStore } from 'stores';
import ReactGA from 'react-ga4';

export class RecipeStore extends BaseStore {
  readonly foodClassNames = ['', 'green', 'orange'];

  locked = false;

  @observable
  searchItems: ISearchItem[] = [];

  @observable
  recipes: IRecipe[] = null;

  @observable
  favoriteRecipes: any[] = null;

  @observable
  categoryRecipes: any = [];

  @observable
  dailyRecipes: IRecipe[] = null;

  @observable
  selectedId = null;

  @observable
  selectedRecipe = null;

  @observable
  count = 1;

  @observable
  selectedCategory =
    Number(sessionStorage.getItem('recipes.selectedCategory')) || 0;

  @observable
  favoriteIds: number[] = [];

  @observable
  similarRecipes: IRecipe[] = null;

  searchString = '';

  constructor(stores: IStores) {
    super(stores);
    makeObservable(this);
  }

  private getSearchString(): string {
    return (this.searchString = Array.from(
      new Set(this.searchItems.map((item) => item.value))
    )
      .join(',')
      .toLowerCase());
  }

  async addToSearchString(value: string) {
    this.searchItems.push({
      value,
      key: value.toLowerCase(),
    });

    this.searchString = this.getSearchString();

    this.recipes = (await RecipeActions.getAll(this.searchString)).data.results;
  }

  async removeSearchString(value: string) {
    const index: number = this.searchItems.findIndex((item) => {
      return item.value === value;
    });

    if (index >= 0) {
      this.searchItems.splice(index, 1);
      this.searchString = this.getSearchString();
    }

    this.recipes = (await RecipeActions.getAll(this.searchString)).data.results;
  }

  @action
  async getFavoriteRecipes() {
    this.favoriteRecipes = (await RecipeActions.getFavorite()).data.results;

    this.favoriteIds = this.favoriteRecipes.map((item) => item.id);
  }

  @action
  async loadAll() {
    if (this.locked) {
      return;
    }

    this.locked = true;

    this.recipes = (await RecipeActions.getAll()).data.results;

    this.getFavoriteRecipes();

    this.stores.basketStore.basketRecipes ??
      this.stores.basketStore.loadBasket();

    if (this.selectedId) {
      await this.loadSelectedRecipe();
    }

    this.dailyRecipes = (await RecipeActions.getDailyRecipes()).data || [];
    this.stores.uiStore.loadingOff();
  }

  @action
  async switchIsFavorite(recipe) {
    const isFavorite = this.favoriteIds.includes(recipe.id);
    if (isFavorite) {
      this.favoriteIds = this.favoriteIds.filter((id) => id !== recipe.id);
      recipe.isFavorite = false;
      await RecipeActions.removeFromFavorite(
        recipe.id,
        () => {
          this.stores.uiStore.showDialog(
            'Ошибка удаления любимых рецептов',
            'Ошибка'
          );
        },
        async () => {
          await this.getFavoriteRecipes();
        }
      );
    } else {
      this.favoriteIds = this.favoriteIds.concat(recipe.id);
      recipe.isFavorite = true;
      await RecipeActions.addToFavorite(
        recipe.id,
        () => {
          this.stores.uiStore.showDialog(
            'Ошибка добавления любимых рецептов',
            'Ошибка'
          );
        },
        async () => {
          await this.getFavoriteRecipes();
        }
      );
    }
  }

  @action
  async setCategory(category, categoryTitle?: string) {
    this.selectedCategory = category;

    SessionStorageUtil.setInt(
      SessionStorageUtil.RECIPES_SELECTED_CATEGORY,
      category
    );

    ReactGA.event({
      category: categoryTitle,
      action: 'Clicked buttons',
    });
  }

  @action
  async loadSimilarRecipes(id: string) {
    const response = await RecipeActions.getSimilarRecipes(id);
    this.similarRecipes = response.data.results;
  }

  async selectRecipe(id) {
    this.selectedId = id;
    await this.loadSelectedRecipe();
  }

  @action
  changeCount(count) {
    this.count = count;
  }

  @action
  resetSelected() {
    this.selectedId = null;
    this.selectedRecipe = null;
    this.count = 1;
  }

  @action
  async loadSelectedRecipe() {
    const response = await RecipeActions.getRecipe(this.selectedId);
    this.selectedRecipe = response.data;
  }
}
