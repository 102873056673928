import { ComponentType } from 'react';
import {
  ComponentDialog,
  ComponentProgress,
  images,
  PhoneNumber,
} from '../../components/Utils/componentUtils';
import { inject, observer } from 'mobx-react';
import { TextField } from '@material-ui/core';
import { diseasesList } from '../../data';
import { Autocomplete } from '@material-ui/lab';
import BaseComponent from '../../components/Base/base';
import UserActions from '../../connector/userActions';
import { Controller, useForm, UseFormReturn } from 'react-hook-form';
import { RegexpConstants } from '../../components/Constants/RegexpConstants';
import DateFnsUtils from '@date-io/date-fns';
import ruLocale from 'date-fns/locale/ru';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { PasswordForm } from 'pages/Password/passwordForm';
import './SingUp.css';

@inject('userStore', 'uiStore', 'validationFormStore')
@observer
class SignUp extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = this.getDefaultState();
  }

  getDefaultState() {
    return {
      phone: '',
      email: '',
      lastName: '',
      firstName: '',
      patronymic: '',
      birthDate: null,
      day: '',
      month: '',
      year: '',
      country: '',
      city: '',
      goal: 0,
      sex: 0,
      height: '',
      weight: '',
      targetWeight: '',
      belly: '',
      buttocks: '',
      diseases: [],
      maternity: false,
      info: '',
      isPasswordDialogOpen: false,
      password: '',
      confirmation: '',
      progress: false,
    };
  }

  async componentDidMount(): Promise<void> {
    await super.componentDidMount();
    this.props.validationFormStore.reset();
    this.props.validationFormStore.markAsInvalid('birthDate');
  }

  async checkUser(formData) {
    const { validationFormStore } = this.props;
    validationFormStore.enableValidation();
    if (validationFormStore.invalidFields.length > 0) {
      window.scroll({ top: 0 });
      return;
    }
    this.setState({
      progress: true,
      ...formData,
    });
    await UserActions.checkUser(this.state.phone, null, () =>
      this.setState({ isPasswordDialogOpen: true })
    );
    this.setState({ progress: false });
  }

  async signUp(e) {
    e.preventDefault();
    this.setState({ progress: true });
    const profile = this.getProfileEntity();
    await this.props.userStore.signup(profile);
    this.setState({ progress: true, isPasswordDialogOpen: false });
  }

  getProfileEntity() {
    const {
      lastName,
      firstName,
      patronymic,
      phone,
      email,
      birthDate,
      country,
      city,
      targetWeight,
      height,
      diseases,
      goal,
      sex,
      weight,
      belly,
      buttocks,
      password,
      info,
      maternity,
    } = this.state;
    return {
      user: {
        lastName,
        firstName,
        patronymic,
        sex,
      },
      credentials: {
        phone,
        email: email.trim(),
        password,
      },
      profile: {
        birthDate,
        country,
        city,
        targetWeight,
        height,
        goal,
        info,
        maternity,
        diseases: diseases.map((disease) => diseasesList.indexOf(disease)),
        startWeight: weight,
        startBelly: belly,
        startButtocks: buttocks,
      },
    };
  }

  handlePhoneChange(value) {
    if (value) {
      this.setState({ phone: value });
    }
  }

  handleTextChange(e, field) {
    this.setState({ [field]: e.target.value });
  }

  handleNumberChange(e, field, isDecimal) {
    const value = e.target.value.replace(',', '.');
    if (
      (!isDecimal && value.includes('.')) ||
      (isNaN(value) && value !== '.') ||
      (value === '.' && this.state[field].includes('.'))
    ) {
      return;
    }
    this.setState({ [field]: value });
  }

  changeDiseases(event, value, reason) {
    if (!value && !reason) {
      return;
    }
    this.setState({ diseases: value });
  }

  render() {
    const {
      register,
      handleSubmit,
      control,
      formState: { errors },
    }: UseFormReturn = this.props.form;
    return (
      <div className="container">
        <h1>
          <span>Анкета</span> участника{' '}
          <img width="34" src={images['exam.png'].default} alt="exam" />
        </h1>
        <div className="note">
          <p>
            Анкету лучше прислать ближе к старту программы. Замеры производятся
            натощак.
          </p>
          * Обязательно
          <img
            className="note__img"
            src={images['sticker1.png'].default}
            alt="sticker"
          />
        </div>
        <form
          className="zamerform"
          action="/"
          encType="multipart/form-data"
          method="post"
          onSubmit={handleSubmit(this.checkUser.bind(this))}
        >
          <div className="formquestion">
            <div className="blocktitle">
              1. Номер телефона <span>*</span>
            </div>
            <p>Укажите, пожалуйста, номер телефона</p>

            <Controller
              control={control}
              name={'phone'}
              rules={{
                required: 'Номер телефона обязателен',
              }}
              render={({ fieldState: { isTouched, invalid }, field }) => {
                const isInValid =
                  isTouched &&
                  (!field.value || invalid || field.value?.length < 7);
                return (
                  <>
                    <PhoneNumber
                      className={
                        isInValid ? 'signUpInputNotValid' : 'signUpInputDefault'
                      }
                      {...field}
                    />
                    {isInValid && (
                      <div className="signUpError">
                        {'Номер телефона обязателен'}
                      </div>
                    )}
                  </>
                );
              }}
            />
          </div>

          <div className="formquestion">
            <div className="blocktitle">
              2. Электронный адрес <span>*</span>
            </div>
            <input
              className={
                errors.email ? 'signUpInputNotValid' : 'signUpInputDefault'
              }
              type="text"
              name="email"
              placeholder="E-mail"
              {...register('email', {
                required: 'E-mail обязателен',
                pattern: {
                  value: RegexpConstants.emailRegexp,
                  message: 'Некорректный e-mail',
                },
              })}
            />
            {errors?.email && (
              <div className="signUpError">{errors?.email?.message}</div>
            )}
          </div>

          <div className="formquestion">
            <div className="blocktitle">
              3. ФИО <span>*</span>
            </div>
            <input
              className={
                errors.lastName ? 'signUpInputNotValid' : 'signUpInputDefault'
              }
              type="text"
              name="lastName"
              placeholder="Фамилия"
              {...register('lastName', {
                required: 'Фамилия обязательна',
                minLength: {
                  value: 2,
                  message: 'Минимум 2 символа',
                },
                maxLength: {
                  value: 128,
                  message: 'Максимум 128 символов',
                },
              })}
            />
            {errors?.lastName && (
              <div className="signUpError">{errors?.lastName?.message}</div>
            )}

            <input
              className={
                errors.firstName ? 'signUpInputNotValid' : 'signUpInputDefault'
              }
              type="text"
              name="firstName"
              placeholder="Имя"
              {...register('firstName', {
                required: 'Имя обязательно',
                minLength: {
                  value: 2,
                  message: 'Минимум 2 символа',
                },
                maxLength: {
                  value: 128,
                  message: 'Максимум 128 символов',
                },
              })}
            />
            {errors?.firstName && (
              <div className="signUpError">{errors?.firstName?.message}</div>
            )}

            <input
              className={
                errors.patronymic ? 'signUpInputNotValid' : 'signUpInputDefault'
              }
              type="text"
              name="patronymic"
              placeholder="Отчество"
              {...register('patronymic', {
                maxLength: {
                  value: 128,
                  message: 'Максимум 128 символов',
                },
              })}
            />
            {errors?.patronymic && (
              <div className="signUpError">{errors?.patronymic?.message}</div>
            )}
          </div>

          <div className="formquestion">
            <div className="blocktitle">
              4. Дата рождения <span>*</span>
            </div>
            {(() => {
              const isInvalid =
                this.props.validationFormStore.validationEnabled &&
                this.props.validationFormStore.isInvalid('birthDate');
              return (
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ruLocale}>
                  <DatePicker
                    disableFuture
                    openTo="year"
                    format="dd.MM.yyyy"
                    views={['year', 'month', 'date']}
                    value={this.state.birthDate}
                    defaultValue={null}
                    InputProps={{
                      className: `noBox ${isInvalid ? 'invalidInput' : ''}`,
                    }}
                    onChange={(birthDate) => {
                      this.setState({ birthDate });
                      this.props.validationFormStore.markAsValid('birthDate');
                    }}
                  />
                  {isInvalid && (
                    <div className="signUpError">
                      {'Дата рождения обязательна'}
                    </div>
                  )}
                </MuiPickersUtilsProvider>
              );
            })()}
          </div>

          <div className="formquestion">
            <div className="blocktitle">
              5. Страна и город проживания <span>*</span>
            </div>
            <input
              className={
                errors.country ? 'signUpInputNotValid' : 'signUpInputDefault'
              }
              type="text"
              name="country"
              placeholder="Страна"
              {...register('country', {
                required: 'Страна обязательна',
                minLength: {
                  value: 2,
                  message: 'Минимум 2 символа',
                },
                maxLength: {
                  value: 128,
                  message: 'Максимум 128 символов',
                },
              })}
            />
            {errors?.country && (
              <div className="signUpError">{errors?.country?.message}</div>
            )}

            <input
              className={
                errors.city ? 'signUpInputNotValid' : 'signUpInputDefault'
              }
              type="text"
              name="city"
              placeholder="Город"
              {...register('city', {
                required: 'Город обязателен',
                minLength: {
                  value: 2,
                  message: 'Минимум 2 символа',
                },
                maxLength: {
                  value: 128,
                  message: 'Максимум 128 символов',
                },
              })}
            />
            {errors?.city && (
              <div className="signUpError">{errors?.city?.message}</div>
            )}
          </div>

          <div className="formquestion formlabels">
            <div className="blocktitle">
              6. Какая цель вашего участия в нашем курсе? <span>*</span>
            </div>
            <div className="formlabel">
              <input
                type="radio"
                name="goal"
                id="goal1"
                value="Снизить вес и улучшить качество тела"
                checked={this.state.goal === 0}
                onChange={() => this.setState({ goal: 0 })}
              />
              <label htmlFor="goal1">
                Снизить вес и улучшить качество тела
              </label>
            </div>
            <div className="formlabel">
              <input
                type="radio"
                name="goal"
                id="goal2"
                value="Поддерживать вес и улучшить качество тела"
                checked={this.state.goal === 1}
                onChange={() => this.setState({ goal: 1 })}
              />
              <label htmlFor="goal2">
                Поддерживать вес и улучшить качество тела
              </label>
            </div>
          </div>

          <div className="formquestion formlabels">
            <div className="blocktitle">
              7. Пол <span>*</span>
            </div>
            <div className="formlabel">
              <input
                type="radio"
                name="gender"
                id="female"
                value="Женский"
                checked={this.state.sex === 0}
                onChange={() => this.setState({ sex: 0 })}
              />
              <label htmlFor="female">Женский</label>
            </div>
            <div className="formlabel">
              <input
                type="radio"
                name="gender"
                id="male"
                value="Мужской"
                checked={this.state.sex === 1}
                onChange={() => this.setState({ sex: 1 })}
              />
              <label htmlFor="male">Мужской</label>
            </div>
          </div>

          <div className="formquestion">
            <div className="blocktitle">
              8. Рост (см) <span>*</span>
            </div>
            <input
              className={
                errors.height ? 'signUpInputNotValid' : 'signUpInputDefault'
              }
              type="number"
              step="1"
              name="height"
              placeholder="Целое число (100-250)"
              {...register('height', {
                required: 'Рост обязателен',
                pattern: {
                  value: RegexpConstants.intRegexp,
                  message: 'Целое число. Пример "140"',
                },
                min: {
                  value: 100,
                  message: 'Минимум 100',
                },
                max: {
                  value: 250,
                  message: 'Максимум 250',
                },
              })}
            />
            {errors?.height && (
              <div className="signUpError">{errors?.height?.message}</div>
            )}
          </div>

          <div className="formquestion">
            <div className="blocktitle">
              9. Вес (кг) <span>*</span>
            </div>
            <p>Ваш вес на текущий момент?</p>
            <input
              className={
                errors.weight ? 'signUpInputNotValid' : 'signUpInputDefault'
              }
              type="number"
              name="weight"
              step="0.001"
              placeholder="Число (30-300)"
              {...register('weight', {
                required: 'Требуется указать вес в килограммах',
                pattern: {
                  value: RegexpConstants.floatRegexp,
                  message: 'Невалидное значение. Пример "57.400"',
                },
                min: {
                  value: 30,
                  message: 'Минимум 30',
                },
                max: {
                  value: 300,
                  message: 'Максимум 300',
                },
              })}
            />
            {errors?.weight && (
              <div className="signUpError">{errors?.weight?.message}</div>
            )}
          </div>

          <div className="formquestion">
            <div className="blocktitle">
              10. Желаемый вес (кг) <span>*</span>
            </div>
            <p>К какому весу вы хотите придти?</p>
            <input
              className={
                errors.targetWeight
                  ? 'signUpInputNotValid'
                  : 'signUpInputDefault'
              }
              type="number"
              name="targetWeight"
              step="0.001"
              placeholder="Число (30-300)"
              {...register('targetWeight', {
                required: 'Требуется указать вес в килограммах',
                pattern: {
                  value: RegexpConstants.floatRegexp,
                  message: 'Невалидное значение. Пример "57.400"',
                },
                min: {
                  value: 30,
                  message: 'Минимум 30',
                },
                max: {
                  value: 300,
                  message: 'Максимум 300',
                },
              })}
            />
            {errors?.targetWeight && (
              <div className="signUpError">{errors?.targetWeight?.message}</div>
            )}
          </div>

          <div className="formquestion">
            <div className="blocktitle">
              11. Объем живота (см) <span>*</span>
            </div>
            <p>На уровне пупка.</p>
            <input
              className={
                errors.belly ? 'signUpInputNotValid' : 'signUpInputDefault'
              }
              type="number"
              name="belly"
              step="0.001"
              placeholder="Число (30-300)"
              {...register('belly', {
                required: 'Объем живота обязателен',
                pattern: {
                  value: RegexpConstants.floatRegexp,
                  message: 'Невалидное значение. Пример "74.2"',
                },
                min: {
                  value: 30,
                  message: 'Минимум 30',
                },
                max: {
                  value: 300,
                  message: 'Максимум 300',
                },
              })}
            />
            {errors?.belly && (
              <div className="signUpError">{errors?.belly?.message}</div>
            )}
          </div>

          <div className="formquestion">
            <div className="blocktitle">
              12. Объем ягодиц (см) <span>*</span>
            </div>
            <p>В самой широк части бедер.</p>
            <input
              className={
                errors.buttocks ? 'signUpInputNotValid' : 'signUpInputDefault'
              }
              type="number"
              name="buttocks"
              step="0.001"
              placeholder="Число (30-300)"
              {...register('buttocks', {
                required: 'Объем ягодиц обязателен',
                pattern: {
                  value: RegexpConstants.floatRegexp,
                  message: 'Невалидное значение. Пример "112.8"',
                },
                min: {
                  value: 30,
                  message: 'Минимум 30',
                },
                max: {
                  value: 300,
                  message: 'Максимум 300',
                },
              })}
            />
            {errors?.buttocks && (
              <div className="signUpError">{errors?.buttocks?.message}</div>
            )}
          </div>

          <div className="formquestion">
            <div className="blocktitle">13. Заболевания</div>
            <Autocomplete
              className={'signUpInputDefault'}
              multiple
              id="diseases"
              options={diseasesList}
              onSelect={this.changeDiseases.bind(this)}
              onChange={(e, v, r) => this.changeDiseases(e, v, r)}
              filterSelectedOptions
              renderInput={(params) => <TextField {...params} type="search" />}
            />
          </div>

          {this.state.sex === 0 ? (
            <div className="formquestion">
              <div className="formlabel">
                <input
                  type="checkbox"
                  name="maternity"
                  id="maternity"
                  value={this.state.maternity}
                  onChange={() => {
                    const value = this.state.maternity;
                    this.setState({ maternity: !value });
                  }}
                />
                <label htmlFor="maternity">Грудное вскармливание</label>
              </div>
            </div>
          ) : null}

          <div className="formquestion">
            <div className="blocktitle">14. Прочая информация</div>
            <textarea
              name="info"
              placeholder="Введите ответ"
              value={this.state.info}
              onChange={(e) => this.handleTextChange(e, 'info')}
            />
          </div>
          {this.state.progress && !this.state.isPasswordDialogOpen ? (
            <ComponentProgress />
          ) : (
            <button
              type="submit"
              onClick={() => this.props.validationFormStore.enableValidation()}
            >
              сохранить{' '}
              <img width="22" src={images['exam.png'].default} alt="exam" />
            </button>
          )}
        </form>
        {this.state.isPasswordDialogOpen ? (
          <ComponentDialog
            open={true}
            title={'Придумайте новый пароль (минимум 6 символов)'}
            handleClose={() => {
              this.setState({
                isPasswordDialogOpen: false,
                progress: false,
                password: '',
                confirmation: '',
              });
            }}
            children={
              <PasswordForm
                state={this.state}
                handleTextChange={(e, field) => this.handleTextChange(e, field)}
                confirm={(e) => this.signUp(e)}
              />
            }
          />
        ) : null}
      </div>
    );
  }
}

function WithReactHookForm(Component: ComponentType) {
  return function Wrapped(props: any) {
    const form = useForm({
      mode: 'onBlur',
    });
    return <Component {...props} form={form} />;
  };
}

export default WithReactHookForm(SignUp);
