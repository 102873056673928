import { titles } from 'data';
import BaseComponent from './base';
import { RecipeStore, UiStore, UserStore } from 'stores';

class ProtectedComponent extends BaseComponent {
  private currentPath: string;

  constructor(props) {
    super(props);
    this.currentPath = window.location.pathname;
  }

  setTitleForCurrentPath() {
    const titleInfo = titles.find((item) => item.path === this.currentPath);

    if (titleInfo) {
      window.document.title = titleInfo.title;
    } else {
      const defaultTitleInfo = titles.find((item) => item.path === '/');
      if (defaultTitleInfo) {
        window.document.title = defaultTitleInfo.title;
      }
    }
  }

  async componentDidMount() {
    window.scroll({ top: 0 });

    try {
      this.setTitleForCurrentPath();

      const userStore: UserStore = this.props.userStore;
      const token = localStorage.getItem('auth');

      if (!token) {
        this.props.history?.push('/login');
        return;
      }

      await Promise.all([
        (this.props.recipeStore as RecipeStore).loadAll(),
        userStore.getProfile(),
      ]);

      const isSubscription = Boolean(userStore.profile.subscriptions);

      if (!isSubscription) {
        this.props.history?.push('/login');
        return;
      }

      (this.props.uiStore as UiStore).loadingOff();
    } catch (error) {
      console.error('Error in componentDidMount:', error);
      (this.props.uiStore as UiStore).loadingOff();
    }
  }
}

export default ProtectedComponent;
